/*----------------------------------------------------------------------------*/
/*#TABLE STYLE*/
/*----------------------------------------------------------------------------*/

.table {
  width: 100%;
  border-bottom: 1px solid #e7e9ed;

  &-wrapper {
    position: relative;
    max-width: 100%;
    overflow-x: scroll;

    &--height {
      max-height: 450px;
      overflow: auto;
    }
  }
  &-scroller {
    position: absolute;
    top: 0;

    bottom: 107px;
    width: 70px;
    border: none;
    z-index: 99;
    font-size: 50px;
    padding-top: 20px;
    @extend .txt--lightgray;
    transition: all .3s ease-in-out;
    &:hover { color: mat-color($gmb-app-primary, default); }

    &--left  {
      left: 415px;
      background: linear-gradient(270deg, transparent 0%, #F7F8FE 60%, #F7F8FE 100%);
    }
    &--right {
      right: 0;
      background: linear-gradient(90deg, transparent 0%, #F7F8FE 60%, #F7F8FE 100%);

      &.is-overflown { right: 15px; }
    }
  }

  &--overflow {
    width: 3000px;

    .mat-header-cell.mat-table-sticky,
    .mat-cell.mat-table-sticky { padding: 0 20px; }

    .cdk-column-location { width: 300px; }
    .cdk-column-ranking { width: 110px; }
  }

  &:not(.table--box) {

    .mat-row,
    .mat-header-row,
    .mat-footer-row { height: 65px; }

    .table--last-row-border .mat-row:last-of-type > *,
    .mat-row:not(:last-of-type) > *,
    .mat-header-row > *,
    .mat-footer-row > * {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left:   0;
        right:  0;
        bottom: 0;
        height: 1px;
        background: #eeeff3;
      }
    }

    .mat-row:not(.border--full) {

      td.mat-cell {
        &:first-child::before { left: 40px; }
        &:last-child::before  { right: 40px; }
      }
    }


    .table--sm {
      th.mat-header-cell,
      th.mat-footer-cell,
      .mat-row td.mat-cell {
        &:first-child::before { left: 40px; }
        &:last-child::before  { right: 40px; }
      }
    }

    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type {
      .cell__content {
        padding: 10px;
        width: calc(100% + 10px);
        border-top-right-radius:    5px;
        border-bottom-right-radius: 5px;
      }
    }

    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
      padding-left: 40px;
      .cell__content { transform: translateX(-10px); }
    }

    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type { padding-right: 40px; }

    tr:not(.no-hover):hover {
      .cell__content { background-color: #f7fbff; }
    }

  }

  &--bordered {
    th {
      background-color: $thead;
      .mat-sort-header-arrow,
      .mat-sort-header-button { color: #000; }
    }
    th, td {
      &:not(:first-child) { border-left: 1px solid $table-border; }
      &:not(:last-child)  { border-right: 1px solid $table-border; }
    }

    &.cells-no-border {
      th, td {
        border-left: none;
        border-right: none;
      }
    }
  }

  .mat-table-sticky {
    border-right: 1px solid $table-border;
    &.mat-column-location { left: 117px!important; }
  }

  tr.unread { background-color: #FAFAFB; }

  th {
    font-size: 14px;
    line-height: 1;
    color: #707380;

    &.txt--center {
      .mat-sort-header-container { justify-content: center; }
    }

    &.txt--right { text-align: right; }
  }

  td {
    position: relative;
    font-size: 16px;
    line-height: 1;
    color: #999daf;

    .cell__content {
      display: flex;
      align-items: center;
      min-height: 60px;
      padding: 10px 0;
      margin: 10px 0;
      background-color: transparent;
      transition: all .3s ease-in-out;
    }

    &.txt--right .cell__content  { justify-content: flex-end; }
    &.txt--center .cell__content { justify-content: center; }
  }


  tr.with-padding {
    th, td {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &--lg {
    td .cell__content { min-height: 120px; }
  }

  &--md {
    td .cell__content { min-height: 80px; }
  }

  th.mat-header-cell,
  td.mat-cell {
    vertical-align: middle;
  }
  td.mat-cell {
    &.cdk-column-img,
    &.mat-column-img { vertical-align: top; }
  }

  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom: none;

    &.txt--center { text-align: center; }
  }

  tr.no-hover {
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell { padding: 0 10px; }
  }

  &__img {
    border-radius: 4px;
    width: 100px;
    height: 100px;
    background: #e2e4ea;


    img {
      max-width: 100%;
      object-fit: cover;
    }
  }

  .img_post {
    border-radius: 4px;
    width: 100px;
    height: 100px;
  }



  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    width:  35px;
    height: 35px;
    background: #EFF0F3;
    color: #ced0da;
    border-radius: 50px;
    overflow: hidden;

    &--lg {
      min-width: 45px;
      width: 45px;
      height: 45px;
    }

    svg {
      width: 20px;
      height: 20px;

      path { fill: #CED0DA; }
    }
  }

  p { margin-bottom: 0; }

  //box view
  &--box {
    position: relative;
    display: block;
    width: 100%;
    padding: 15px 25px;

    &::after {
      content: "";
      width: calc(100% - 80px);
      position: absolute;
      left: 40px;
      bottom: 0;
      height: 1px;
      background-color: #eeeff3;
    }

    thead { display: none; }
    tbody {
      @extend .d-flex;
      @extend .flex-wrap;
    }

    td.mat-cell:first-of-type { padding-left: 0; }

    &-wrapper {
      display: flex;
      flex-flow: wrap column;
      width: calc(25% - 30px);
      height: inherit!important;
      border-top: 5px solid #000;
      padding: 30px 15px 15px;
      margin: 15px;
      box-shadow: 0 3px 7px 0 rgba(206,210,223,.52);
    }

    .button-actions {
      width: 100%;
      padding-bottom: 20px;
      border-bottom:  1px solid #eeeff3;
    }

    &__content { padding-top: 15px; }

    &__item {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;
      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      color: #8c91a2;
      margin-bottom: 10px;

      &.align-items-start { @extend .align-items-start; }

      &__icon {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        width: 35px;
        height: 35px;
        background-color: $lightgray;
        border-radius: 50px;
        margin-right: 20px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    &__row {
      @extend .d-flex;
      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      color: #8c91a2;

      &__item {
        height: 100%;
        padding-left: 0;
        border-right: 1px solid #eeeff3;

        span {
          padding: 10px 25px;
          background-color: #F7F8FE;
          margin-bottom: 10px;
        }
        p {
          padding: 0 16px;
        }

        &:first-child {
          border-left: 1px solid #eeeff3;
        }

      }
    }

    .mat-column-authenticate,
    .cdk-column-subscription,
    .cdk-column-date,
    .mat-column-actions,
    .cdk-column-actions { width: 100%; }
  }
}

.mat-row--padding { padding: 15px 40px; }
.mat-row--pb--5   { padding-bottom: 5px; }
.mat-row--padding-md { padding: 25px 40px; }
.mat-row--filter {
  padding: 15px 20px;
  margin: 0 -10px;
}

.mat-row--margin-neg {
  margin-left:  -15px;
  margin-right: -15px;
}
.mat-row--border        { border-bottom: 1px solid #eeeff3; }
.mat-row--border-top    { border-top: 1px solid #eeeff3; }
.mat-row--border-bottom { border-bottom: 1px solid #eeeff3; }

//column width
.cdk-column-subscription,
.cdk-column-time,
.cdk-column-date { width: 160px; }

.cdk-column-date--resp { width: 12%; }

.cdk-column-date-lg { width: 340px; }

.cdk-column-img { width: 120px; }

.mat-column-authenticate,
.cdk-column-id { width: 100px; }

.mat-column-range { width: 340px; }
.mat-column-actions,
.cdk-column-actions {
  width: 360px;

  &.column--sm {
    width: 180px;
    padding-right: 10px;
  }
}

.mat-column-status { width: 200px; }
.table--posted .mat-column-info { width: 800px; }
.table--pending .mat-column-info { width: 580px; }

.mat-column-message,
.cdk-column-message {
  width: 500px;
  @media (min-width: 1500px) { width: 600px; }
}

.cdk-column-reviewer { width: 30%; }

//paginator

.mat-paginator {
  flex-grow: 1;
  .mat-paginator-container {
    min-height: inherit;
    padding-left: 40px;
    justify-content: space-between;
  }

  .mat-paginator-range-actions {
    display: flex;
    align-items: center;
  }

  .mat-paginator-navigation-previous,
  .mat-paginator-navigation-next { display: none; }
  .mat-paginator-range-label,
  .mat-paginator-page-size-label {
    font-size: 14px;
    margin-right: 15px;
    color: #000;
    text-align: right;
  }

  .mat-form-field-infix {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    text-align: center;
    border: 1px solid $lightgray;
    border-radius: 5px;
  }

  .mat-paginator-page-size-select {
    margin: 0;
    .mat-form-field-label-wrapper {
      padding-top: 0;
      top: 0;
    }
    .mat-form-field-wrapper { padding-bottom: 0; }
    .mat-select-value { text-align: center; }
    .mat-form-field-underline { display: none; }
  }

}

//custom pagination elements
.pagination {
  overflow: hidden;
  margin-right: 40px;
  &__item {
    border: none;
    background-color: #fff;
    transition: all .3s ease-in-out;
    min-width: 40px;
    height: 40px;
    line-height: 1;
    color: #BDBDBD;
    border-top:    1px solid $lightgray;
    border-bottom: 1px solid $lightgray;
    border-left:   1px solid $lightgray;

    &:hover,
    &.active {
      background-color: mat-color($gmb-app-primary, default);
      color: #fff;
    }
    &:first-child {
      border-top-left-radius:    5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius:    5px;
      border-bottom-right-radius: 5px;
      border-right: 1px solid $lightgray;
    }
  }

  [disabled] {
    pointer-events: none;
    opacity: .5;
  }
}

.company {
  display: flex;
  align-items: center;

  &__info { padding: 0 10px; }
  &__title { color: #000; }
  &__address { color: #8c91a2; }
}

.col--translate {
  height: 100px;
  &.col--border-l { border-left: 1px solid #eeeff3; }
  &.col--border-lr {
    border-left: 1px solid #eeeff3;
    border-right: 1px solid #eeeff3;
  }
}
.col__header {
  display: flex;
  background: #F7F8FE;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #000;
}

.col__content { padding: 10px 20px; }

/* PRIMENG TABLE */
.p-datatable {
  .th-width-s {
    width: 138px;
  }

  .is-currency {
    width: 138px;
    text-align: right!important;
  }

  .p-datatable-thead {
    th {
      text-align: center!important;
      padding: 8px!important;
      font-size: 12px;
      line-height: 1;
      font-weight: 700!important;
      color: #707380!important;
    }
  }
  .p-datatable-tbody {
    .status-select{
      .mat-select-value-text {
        font-size: 12px!important;
        color: #999daf !important;
      }
    }

    tr:not(.p-highlight):hover {
      background: #f7fbff!important;
    }

    tr:nth-child(even) {
      background: #f6f6f6;
    }

    td:last-child {
      border: none!important;
    }

    td {
      text-align: center!important;
      font-size: 12px;
      padding: 6px!important;
      color: #999daf!important;
      font-weight: 500;
      border: none!important;
      border-right: 1px solid #e4e4e4!important;

      button {
        margin-right: 5px;
      }
    }

    .align-center-table {
      text-align: center;
    }

    .align-left-table {
      text-align: left!important;
    }

    .align-right-table {
      text-align: right!important;
    }

    .isEmpty {
      display: block;
      text-align: center;
    }

    .td-width-xs {
      width: 75px;
      word-break: break-all;
    }

    .td-width-s {
      width: 150px;
      word-break: break-all;
    }

    .td-width-sm {
      width: 182px;
      word-break: break-all;
    }

    .td-width-xxm {
      width: 200px;
      word-break: break-all;
    }

    .td-width-xm {
      width: 250px;
      word-break: break-all;
    }

    .td-width-m {
      width: 300px;
      word-break: break-all;
    }

    .td-width-l {
      width: 400px;
      word-break: break-all;
    }

    .td-align-middle {
      vertical-align: middle;
    }
  }

  .p-column-filter-menu-button {
    width: auto!important;
  }

  .p-paginator-bottom {
    border: 1px solid #e4e4e4;
    border-width: 1px 0 1px 0!important;
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin: 0!important;
}

.p-dropdown-panel span,
.p-column-filter-constraint .p-component .p-component {
  text-transform: uppercase;
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
  top: 2px;
}

.p-paginator {
  .p-paginator-current,
  .p-paginator-pages .p-paginator-page.p-highlight,
  .p-dropdown-label {
    font-size: 14px!important;
    color: #000!important;
  }
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
}

