/*----------------------------------------------------------------------------*/
/*#LIST STYLE*/
/*----------------------------------------------------------------------------*/

.list {

  &__item {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.1;

    &--sm { font-size: 14px; }

    &::before {
      font-family: "Font Awesome 5 Pro", sans-serif;
      position: absolute;
      left: 0;
      top:  0;
      font-size: 16px;
      font-weight: 700;
    }

    &--check::before,
    &--success::before {
      content: "\f00c";
      color: mat-color($gmb-app-accent, default);
    }
    &--fail {
      color: #7d7d7d;
      &::before {
        content: "\f00d";
        color: mat-color($gmb-app-warn, default);
      }
    }
  }
}
