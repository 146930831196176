
/*----------------------------------------------------------------------------*/
/*#CAROUSEL STYLE*/
/*----------------------------------------------------------------------------*/

.swiper-navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.carousel {
  display: block;

  &--up { margin-top: -40px; }

  .swiper-container { padding-top: 50px; }

  .swiper-wrapper { height: 350px; }

  &__item {
    display: flex;
    height: 100%;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @extend .btn;
    position: absolute!important;
    left: inherit;
    right: inherit;
    top: 23px;
    width:  30px;
    height: 30px;
    border-radius: 3px;
    background-color: $lightgray;
    cursor: pointer;
    background-image: none;

    &:hover {
      background-color: mat-color($gmb-app-accent, 500);
      color: #fff;
    }

    &::before {
      font-family: "Font Awesome 5 Pro", sans-serif;
      color: #737373;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      transition: all .3s ease-in-out;
    }

    &:hover::before { color: #fff; }
  }
  .swiper-button-next {
    right: 0;
    &::before { content: '\f105'; }
  }
  .swiper-button-prev {
    right: 40px;
    &::before { content: '\f104'; }
  }
}


.dialog__content--gallery {
  margin-bottom: -30px!important;
  padding: 0 30px!important;
  height: 530px!important;
}

.carousel--media {
  background-color: #dcdcdc;

  &.carousel { margin: 0 -30px; }

  .swiper-wrapper { height: 380px; }

  .swiper-container { padding-top: 0; }

  .swiper-button-prev,
  .swiper-button-next {
    height: 30px;
    width: 30px;
    box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.4);
    border-radius: 50px;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;

    &::before {
      font-size: 20px;
      line-height: 30px;
    }
    &:hover {
      background-color: #fff;
      &::before { color: mat-color($gmb-app-primary, 500); }
    }

    &[disabled=true] {
      opacity: .4;
      cursor: not-allowed;
      &:hover {
        &::before { color: #737373; }
      }
    }
  }

  .swiper-button-prev {
    right: inherit;
    left: 15px;
  }
  .swiper-button-next { right: 15px; }

  .full-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width:  500px;
    height: 500px;

    img { max-width: 100%; }
  }
  .btn-delete--gallery {
    padding: .5em;
    background-color: #fff;
    border-radius: 50px;
    color: #737373;
    box-shadow: 0 3px 5px -2px rgba(0, 0, 0, 0.4);
    top: 15px;
    right: 15px;
  }
}

.carousel--thumbs {
  margin: 0 -30px;

  .swiper-wrapper { height: inherit; }

  .swiper-container {
    padding: 20px 30px;
  }

  .thumb-wrapper {
    cursor: pointer;
    height: 110px!important;
    border-radius: 5px;
    overflow: hidden;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      &::before { color: mat-color($gmb-app-primary, 500); }
    }

    &[disabled=true] {
      opacity: .4;
      cursor: not-allowed;
      &:hover {
        &::before { color: #737373; }
      }
    }
  }
  .swiper-button-next { right: 0; }
  .swiper-button-prev {
    right: inherit;
    left: 0;
  }
}
