/*------------------------------------------*/
/*#HELPER CLASSES*/
/*------------------------------------------*/

/*----------font weight-------------------*/

.fw--light     { font-weight: 300; }
.fw--regular   { font-weight: 400; }
.fw--medium    { font-weight: 500; }
.fw--semibold  { font-weight: 600; }
.fw--extrabold { font-weight: 800; }
.fw--black     { font-weight: 900; }

/*----------font style------------------*/

.txt--uppercase   { text-transform: uppercase; }
.txt--lowercase   { text-transform: lowercase; }
.txt--capitalize  { text-transform: capitalize; }

.txt--overline    { text-decoration: overline; }
.txt--underline   { text-decoration: underline; }
.txt--linethrough { text-decoration: line-through; }

/*-----------font align -------------------*/

.txt--center  { text-align: center; }
.txt--left    { text-align: left; }
.txt--right   { text-align: right; }
.txt--justify { text-align: justify; }

/*---------elements align------------------*/

.pull--left   { float: left; }
.pull--right  { float: right; }
.pull--none   { float: none; }
.clearfix     { clear: both; }

.clearfix::before,
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/*--------elements visibility--------------------*/

.visible   { visibility: visible; }
.invisible { visibility: hidden; }

/*--------overflow---------------------------------*/

.overflow--hidden  { overflow: hidden; }
.overflow--visible { overflow: visible; }
.overflow--auto    { overflow: auto; }

/*----------------------------------------------------------------------------*/
/*#FONT COLOR*/
/*----------------------------------------------------------------------------*/

.txt--white     { color: #fff; }
.txt--black     { color: #000; }
.txt--dark      { color: #1f304e; }
.txt--gray      { color: #575962; }
.txt--lightgray { color: #8c91a2; }
.txt--lightblue { color: #249af3; }
.txt--green     { color: mat-color($gmb-app-accent, default); }
.txt--red       { color: mat-color($gmb-app-warn, default); }
.txt--orange    { color: mat-color($gmb-orange, 500); }
.txt--blue      { color: mat-color($gmb-blue, 700); }

.txt--lightergray { color: lighten(#8c91a2, 20%); }

/*----------------------------------------------------------------------------*/
/*#BORDER STYLE*/
/*----------------------------------------------------------------------------*/

.border-bottom  { border-bottom: 1px solid $lightgray; }
.border-left    { border-left: 1px solid $lightgray; }
.border-right   { border-right: 1px solid $lightgray; }
.border-top     { border-top: 1px solid $lightgray; }

.border--dashed { border: 1px dashed; }
.border--blue { border-color: mat-color(mat-palette($gmb-blue, 100)); }

.border--solid { border: 1px solid; }
.border--gray { border-color: #eeeff2; }

/*----------------------------------------------------------------------------*/
/*#CONTAINER STYLE*/
/*----------------------------------------------------------------------------*/

[class^='container'] {
  width: 100%;
  padding-left:  15px;
  padding-right: 15px;
  margin-left:  auto;
  margin-right: auto;
}
.container--full { max-width: 100%; }
.container       { max-width: 1440px; }
.container--lg   {/* css */ }
.container--md   { max-width: 760px; }
.container--sm   { max-width: 90%; }
.container--xs   { max-width: 360px; }

.w--100 { width: 100%; }
.w--90  { width: 90%; }

.w--xxs { width: 30px; }
.w--sm  { width: 230px; }
.w--md  { width: 350px; }
.w--lg  { width: 600px; }

.h--220{ height: 220px; }
.h--280{ height: 280px; }

/*----------------------------------------------------------------------------*/
/*#PADDING STYLE*/
/*----------------------------------------------------------------------------*/

.container--padding-lg { padding: 40px 30px 80px; }
.container--padding-rl-lg {
  padding-left: 30px;
  padding-right: 30px;
}

.col--padding {
  padding-top:    15px;
  padding-bottom: 15px;
}

.col--padding-lg {
  padding-top:    40px;
  padding-bottom: 20px;

  @media (max-width: $screen-xl) {
    padding-top: 10px;
    padding-bottom: 0;
  } //register screen
}
.col--padding-xl {
  padding-top:   115px;
  padding-bottom: 20px;
}

.p--0  { padding: 0!important; }
.pb--0 { padding-bottom: 0!important; }
.p--30 { padding: 30px; }

.pb--0 { padding-bottom: 0!important; }
.pb--5 { padding-bottom: 5px!important; }
.pb--30 { padding-bottom: 30px!important; }
.pt--0 { padding-top: 0!important; }
.pt--20 { padding-top: 20px!important; }
.pt--30 { padding-top: 30px!important; }
.pt--40 { padding-top: 40px!important; }
.pr--15 { padding-right: 15px; }
.pr--20 { padding-right: 20px; }
.pr--30 { padding-right: 30px!important; }
.pl--15 { padding-left: 15px; }
.pl--20 { padding-left: 20px; }
.pl--30 { padding-left: 30px!important; }

.pt--10 { padding-top: 10px!important; }

.p-tb--0 {
  padding-top:    0!important;
  padding-bottom: 0!important;
}
.p-tb--10 {
  padding-top:    10px!important;
  padding-bottom: 10px!important;
}
.p-tb--20 {
  padding-top:    20px!important;
  padding-bottom: 20px!important;
}

.p--lr-0 {
  padding-left:  0!important;
  padding-right: 0!important;
}
.p--lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p--lr-30 {
  padding-left: 30px!important;
  padding-right: 30px!important;
}

.locations-no-padding .p--30 { padding: 0; }


/*----------------------------------------------------------------------------*/
/*#MARGIN STYLE*/
/*----------------------------------------------------------------------------*/

.m--0   { margin: 0!important; }
.m--4   { margin: 4px!important; }
.m--10  { margin: 10px!important; }

.mb--0  { margin-bottom: 0!important; }
.mb--5  { margin-bottom: 5px!important; }
.mb--10 { margin-bottom: 10px!important; }
.mb--25 { margin-bottom: 25px!important; }

.mb--20 { margin-bottom: 20px!important; }
.mb--15 { margin-bottom: 15px!important; }
.mb--30 { margin-bottom: 30px!important; }
.mb--40 { margin-bottom: 40px!important; }
.mb--50 { margin-bottom: 50px!important; }
.mb--60 { margin-bottom: 60px!important; }
.mb--90 { margin-bottom: 60px!important; }

.ml--5  { margin-left: 5px!important; }
.ml--10 { margin-left: 10px!important; }
.ml--15 { margin-left: 15px!important; }
.ml--20 { margin-left: 20px!important; }
.ml--30 { margin-left: 30px!important; }
.ml--35 { margin-left: 35px!important; }

.mr--0  { margin-right: 0!important; }
.mr--5  { margin-right: 5px!important; }
.mr--10 { margin-right: 10px!important; }
.mr--15 { margin-right: 15px!important; }
.mr--20 { margin-right: 20px!important; }
.mr--25 { margin-right: 25px!important; }

.mt--5  { margin-top: 5px!important; }
.mt--10 { margin-top: 10px!important; }
.mt--15 { margin-top: 15px!important; }
.mt--20 { margin-top: 20px!important; }
.mt--25 { margin-top: 25px!important; }

.mt--auto { margin-top: auto!important; }

.m-tb--0 {
  margin-top:    0;
  margin-bottom: 0;
}

.m--auto {
  margin-left:  auto!important;
  margin-right: auto!important;
}

.last-no-margin p:last-child { margin-bottom: 0!important; }


.register--margin {
  margin-bottom: 30px;
  @media (max-width: $screen-xl) {

    margin-bottom: 0;

    &.box--padding-md { padding: 15px 40px;}
    &.box--padding-xxs { padding: 15px 20px;}
  }
}

.row.gutters-xs {
  margin-left:  -3px;
  margin-right: -3px;

  [class*="col-"] {
    padding-left:  3px;
    padding-right: 3px;
  }
}
.row.gutters-xsm {
  margin-left:  -5px;
  margin-right: -5px;

  [class*="col-"] {
    padding-left:  5px;
    padding-right: 5px;
  }
}
.row.gutters-sm {
  margin-left:  -10px;
  margin-right: -10px;

  [class*="col-"] {
    padding-left:  10px;
    padding-right: 10px;
  }
}

/*----------------------------------------------------------------------------*/
/*#IMAGE STYLE*/
/*----------------------------------------------------------------------------*/

.img--fluid { max-width: 100%; }

.img--round {
  border-radius: 50px;
  overflow: hidden;
}

.img--center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img--xxs { max-width: 23px; }
.img--xs { max-width: 50px; }
.img--sm { max-width: 80px; }
.img--md { max-width: 120px; }
.img--lg { max-width: 150px; }

.img--height-md { max-height: 140px; }

.reverse-icon { transform: scaleX(-1); }

/*----------------------------------------------------------------------------*/
/*#UNIQUE HELPERS*/
/*----------------------------------------------------------------------------*/

.screen-height { min-height: 100vh; }

.cursor--pointer { cursor: pointer; }

.border-box { box-sizing: border-box; }

.no-shadow { box-shadow: none!important; }

.flex-grow-1 { flex-grow: 1; }

.flex-shrink-0 { flex-shrink: 0; }

/*----------------------------------------------------------------------------*/
/*MIN HEIGHT*/
/*----------------------------------------------------------------------------*/

.height-sm { min-height: 108px;}
.height-xs { min-height: 78px;}
