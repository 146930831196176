/*----------------------------------------------------------------------------*/
/*#BOX STYLE*/
/*----------------------------------------------------------------------------*/

.box--height .box:not(.box--flex) { height: 100%; }

.box {
  width: 100%;

  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &--xs { max-width: 270px; }
  &--md { max-width: 490px; }
  &--auto { max-width: inherit; }

  &--padding-tiny { padding: 10px; }
  &--padding-xxxs { padding: 15px; }
  &--padding-xxs  { padding: 20px; }
  &--padding-xs   { padding: 25px; }
  &--padding-sm   { padding: 30px; }
  &--padding-md   { padding: 30px 45px; }
  &--padding      { padding: 45px; }
  &--padding-lg   { padding: 45px 60px; }

  &--padding-t   { padding-top: 45px; }
  &--padding-xxs-tb {
    padding-top:    20px;
    padding-bottom: 20px;
  }
  &--padding-sm-tb {
    padding-top:    30px;
    padding-bottom: 30px;
  }
  &--padding-lr {
    padding-left:  45px;
    padding-right: 45px;
  }

  &--margin {
    margin-top: 35px;
    margin-bottom: 30px;
  }

  &--flex {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .flex-column;

    width: auto;
    height: inherit;

    svg { height: 10px; }
  }

  &--info {
    svg { display: none; }
  }

  &--fail    {
    h6 { color: mat-color($gmb-app-warn, default); }
    .mat-chip.mat-standard-chip {
      background-color: mat-color($gmb-app-warn, default);
      color: #fff;
    }
    svg {
      transform: scale(1, -1);
      path { fill: mat-color($gmb-app-warn, default); }
    }
  }
  &--success {
    h6 { color: mat-color($gmb-app-accent, default); }
    .mat-chip.mat-standard-chip {
      background-color: mat-color($gmb-app-accent, default);
      color: #fff;
    }
    svg {
      path { fill: mat-color($gmb-app-accent, default); }
    }
  }
  &--selected {

    &.box--success { background-color: mat-color($gmb-app-accent, default); }
    &.box--fail { background-color: mat-color($gmb-app-warn, default); }

    &.box--success,
    &.box--fail {
      h6 { color: #fff; }
      .mat-chip.mat-standard-chip {
        background-color: #fff;
        color: #000;
      }

      svg {
        path { fill: #fff; }
      }

      .chip-title { border-color: #fff; }
    }
  }

  &--border {
    border: 2px solid #d8d8d8;
    border-radius: 10px;
    &-sm { border: 1px solid #d8d8d8; }
    &-light { border-color:  #eeeff2; }
  }

  &--rounded { border-radius: 5px; }

  &--shadow { box-shadow: 0 3px 7px 0 rgba(206,210,223,.52); }

  &--upgrade { min-height: 390px; }

  &--scrollable {
    width: 100%;
    overflow-x: auto;
  }

  &--preview {
    display: inline-block;
    min-width: 280px;
    margin-left:  auto;
    margin-right: auto;
    padding: 70px 0 0;

    &__row {
      @extend .d-flex;
      @extend .align-items-start;
      @extend .txt--md;
      margin-bottom: 10px;
      text-align: left;
    }
  }

  &__icon {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    width: 75px;
    height: 75px;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.16);

    &--up {
      margin-top: -40px;
      transform: translateY(-10px);
    }
  }

  &__header {
    padding: 20px;
    border-bottom: 1px solid $lightgray;

    &--flex {
      .mat-button-wrapper {
        @extend .d-flex;
        @extend .justify-content-between;
        @extend .align-items-center;
        text-align: left;
      }
    }

    &--sm { padding: 20px 30px; }
    &--xs { padding: 10px 20px; }

    > * { margin-bottom: 0; }

    &.no-border { border-bottom: none; }
  }
  &__content {
    position: relative;
    &--padding-xs { padding: 5px 20px!important; }
  }
  &__badge .mat-badge-content {
    right: -42px!important;
    top: -3px!important;
  }

  &__footer {
    padding-top: 25px;
    [class*='btn'] { padding: 11px 18px; }
  }

  &__section {
    &--right { padding-right: 20px; }
    &--left  { padding-left: 20px; }
  }

  &__item {
    padding-top:    20px;
    padding-bottom: 20px;

    &--sm {
      padding-top:    10px;
      padding-bottom: 10px;
    }
    &--md {
      padding-top:    15px;
      padding-bottom: 15px;
    }

    &--editable {
      @extend .d-flex;
      @extend .justify-content-between;
      @extend .align-items-center;

      &.align-items-start {
        @extend .align-items-start;
      }
    }

    p:last-child { margin-bottom: 0; }
  }

  &--flex-between {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-between;
  }

  &--circles {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 100px;
    }
    &::before {
      width:  200px;
      height: 200px;
      top:  -140px;
      left: -100px;
    }

    &::after {
      left: 75%;
      top: -90px;
      width:  150px;
      height: 150px;
    }

    &-blue {
      &::before,
      &::after {  background-color: mat-color(mat-palette($gmb-blue, 50)); }
    }
    &-green {
      &::before,
      &::after {  background-color: mat-color(mat-palette($gmb-green, 50)); }
    }
  }

  &--circle {

    &-blue,
    &-green {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        top: -25px;
        right: 15%;
        border-radius: 50%;
        z-index: 9;
      }
    }

    &-green {
      &::after {
        background-color: mat-color(mat-palette($gmb-green, 100));
      }
    }
    &-blue {
      &::after {
        background-color: mat-color(mat-palette($gmb-blue, 100));
      }
    }
  }

  &--overflow { overflow: hidden; }
}

.chip-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}


