/*----------------------------------------------------------------------------*/
/*#FOOTER STYLE*/
/*----------------------------------------------------------------------------*/

.footer {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-between;
  padding-top:    20px;
  padding-bottom: 20px;
  border-top: 1px solid #dcdfe3;
  position: absolute;
  bottom: 0;
  left:  35px;
  right: 35px;

  &--noborder {
    border-top: 0;
    position: relative;
    left: inherit;
    right: inherit;
  }

  &__nav {

    li {
      position: relative;
      display: inline-block;
      padding-left:  20px;
      padding-right: 20px;

      &:first-child { padding-left: 0; }

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        left: -1px;
        top:   50%;
        transform: translateY(-50%);
        width:  1px;
        height: 12px;
        background-color: #8c91a2;
      }
    }
  }

  a {
    transition: all .3s ease-in-out;
    cursor: pointer;
    &:hover { color: mat-color($gmb-app-accent, default); }
  }

  li,
  a,
  span {
    font-size: 14px;
    line-height: 1;
    color: #8c91a2;
    margin-bottom: 0;

    &.txt--white {
      @extend .txt--white;
      &:hover { color: mat-color($gmb-app-primary, default); }
    }
  }
}
