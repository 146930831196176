/*----------------------------------------------------------------------------*/
/*#DIALOG STYLE*/
/*----------------------------------------------------------------------------*/

.dialog {

  &__header {
    position: relative;
    background-color: mat-color($gmb-app-primary, default);
    padding: 30px;
    margin: -30px -30px 0;

    &--sm { padding: 20px 30px; }

    &--info { background-color: mat-color($gmb-app-primary, default); }
    &--warn { background-color: mat-color($gmb-app-warn, default); }
    &--error { background-color: mat-color($gmb-app-warn , default); }

    > * { color: #fff; }

    &--white {
      background-color: #fff;
      > * { color: #1c1c29; }
    }

    &--border {
      border-bottom: 1px solid #e3e3e3;
    }

    &--upgrade {
      padding-top:    0;
      padding-bottom: 0;
    }

    .mat-progress-bar {
      width: inherit;
      margin: 0 -30px;
    }
  }

  &__search {
    padding: 5px 30px;
    margin-left: -30px;
    margin-right: -30px;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px
  }

  &__back {
    position: absolute;
    left: 20px;
    top: 20px
  }

  &-row {
    padding: 15px 0;

    &--override {
      height: 60px;
      padding: 0;
    }

    .mat-checkbox-inner-container { margin-top: 5px; }
    .m--0 .mat-checkbox-inner-container { margin-top: 0; }

    &.border-bottom { border-bottom: 1px solid #eeeff3; }

   /*override input style*/
    .mat-form-field .input-group__field,
    .input-group__field,
    .form-control {
      background-color: #fff;
      &.input-bg--gray { background-color: #e6e6e6; }
    }

    .mat-form-field.no-margin {
      margin: 0;

      .mat-form-field-infix {
        border-top: 0;
        padding: 0;
        margin: 0;
      }
    }

    .input-group__label { font-weight: 400; }
  }

  &--padding { padding: 0 35px; }

  //featured dialogs
  &--featured.dialog__content.mat-dialog-content {
    position: relative;
    background: url("/assets/images/featured.png") no-repeat top center/contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 170px;
    margin: -30px;
    height: calc(100% + 60px);
  }
  //ultimate dialogs
  &--ultimate {
    &-wrapper {
      overflow: visible;
      max-height: calc(100vh - 10%) !important;
      margin-top: 20px;

      .mat-dialog-container.mat-dialog-container {
        padding: 0;
        overflow: visible;
      }
    }

    .dialog__header {
      background: url("/assets/images/ultimate.png") no-repeat bottom center;
      background-size: 100% 190px;
      margin: 0;
      padding-top: 50px;
      padding-bottom: 20px;
      border-radius: 6px;
    }

    .dialog__content.mat-dialog-content {
      position: relative;
      padding: 20px 30px;
      margin: 0;
      max-height: calc(100vh - 240px);
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);

    &--sm {
      width:  73px;
      height: 73px;
    }
    &--top {
      top: 0;
      transform: translate(-50%, -50%);
    }
  }
}

.overflow--visible {
  .mat-dialog-container,
  .mat-dialog-content {
    overflow: visible;
  }
}

.mat-dialog {
  &-container.mat-dialog-container {
    border-radius: 7px;
    padding: 30px;
  }
  &-content.dialog__content {
    padding: 15px 30px;
    margin: 0 -30px;

    &--height { max-height: 45vh; }

    &.dialog__content--posts {
      margin-top: -30px;
      padding: 0;
    }
  }

  &-actions.dialog__footer {
    padding: 0 0 20px;
    justify-content: space-between;
  }
  &-actions.dialog__footer.justify_end {
    padding: 0 0 20px;
    justify-content: end;
  }
  &-actions.dialog__footer--override {
    padding: 0;
    margin: 0 0 0 -10px;
  }
}

.dialog__content--posts {

  .mat-tab-label {
    height: 75px;
    padding: 25px;
    width: 33%;
    opacity: 1;

    .mat-tab-label-content {
      font-size: 16px;
      font-weight: 400;
    }

    &-active .mat-tab-label-content { color: mat-color($gmb-app-primary, default); }
  }

  .mat-ink-bar { height: 4px; }
}

.dialog__tab-content { padding: 30px 30px 10px; }

.dialog__footer--posts {
  &.mat-dialog-actions.dialog__footer {
    margin: 0 -30px -30px;
    padding: 20px;
    height: 85px;
    border-top: 1px solid #eeeff3;
  }
}

.info-type {
  display: inline-block;
  width: 150px;
  text-align: left;
}

.info-details {
  display: inline-block;
  max-width: calc(100% - 150px);
  word-break: break-word;
}


.overflow--visible .mat-dialog-container {
  @extend .overflow--visible;
}


.dropdown--fixed {
  .dropdown-content { position: fixed!important; }
}

.powerBy-Icon{
  fill: rgba(255, 255, 255, 0.7);
  vertical-align: text-bottom;
}

.content-footer-bottoms {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%
}

.content-terms-labels {
  margin: 30px -30px -30px -30px;
}

.content-footer-labels a{
  color: #299ef6;
}

.text-center {
  text-align: center;
}

.text-payment {
  width: 50%;
}

.link-support {
  color: #7d7d7d;
  font-size: 14px;
  padding-top: 13px;
  margin-left: 8px;
}

.hidden-content {
  display: none !important;
}

.div-center {
  display: flex;
  justify-content: center !important;
}

