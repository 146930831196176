/*----------------------------------------------------------------------------*/
/*#REPORTS ELEMENTS STYLE*/
/*----------------------------------------------------------------------------*/

.stars {
  position: relative;
  width: 110px;
  height: 22px;
  margin-left: 15px;

  @media (max-width: 1550px) { margin-left: 5px; }

  &--bg {
    display: block;
    position: absolute;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    background-repeat: repeat-x;
    background-position: left center;
    background-size: 22px;
  }

  &--full {
    background-image: url('../../../assets/images/star-full.png');
    z-index: 1;
  }
  &--empty {
    background-image: url('../../../assets/images/star-empty.png');
    z-index: 0;
  }
}

.single-star {
  display: inline-block;
  height: 20px;
  width:  40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../assets/images/star-empty.png');
  background-size: contain;

  &--full { background-image: url('../../../assets/images/star-full.png'); }
}

.stars--sm {

  .stars {
    width: 130px;
    margin-left: 0;
  }
  .single-star { width: 25px; }
}

.stars--xs {
  .stars { margin-left: 0; }
  .single-star { width: 22px; }
}

.stars--xxs {
  .stars { margin-left: 0; }
  .single-star {
    width:  17px;
    height: 17px;
  }
}

.stars-checkbox--margin .mat-checkbox .mat-checkbox-inner-container {
  margin-bottom: 10px;
  margin-right: 7px;
}

.statistics {
  i {
    font-size: 18px;
    transform: translateY(-1px);
  }
  p {
    //font-size: 16px;
    line-height: 1;
    margin-bottom: 0;
  }

  &--number {
    display: inline-block;
    font-weight: 900;
    margin-right: 5px;
  }

  &--up {
    i, .statistics--number { color: mat-color($gmb-app-accent, default); }
  }
  &--down {
    i, .statistics--number { color: mat-color($gmb-app-warn, default); }
  }
}

.rating-distribution {

  .rating--percentage {
    width: 80px;
    text-align: right;
    margin-right: -10px;

    sub {
      display: inline-block;
      width: 35px;
      margin-left: 5px;
      text-align: left;
      bottom: 0;
    }
  }

  .mat-progress-bar {
    width: inherit;
    flex-grow: 1;
  }
}

.review-response {
  @extend .d-flex;
  @extend .align-items-center;
  padding: 13px 20px;
  border-radius: 5px;

  @media (max-width: 1550px) {
    &:not(.review-response--xs) {
      height: 52px;
      padding: 13px;
    }
  }

  i {
    font-size: 25px;
    margin-right: 15px;
    @media (max-width: 1550px) {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  p {
    flex-grow: 1;
    @media (max-width: 1550px) {
      &.txt--md { font-size: 14px; }
    }
  }

  &--num {
    font-size: 20px;
    font-weight: 900;
    @media (max-width: 1550px) {
      font-weight: 600;
      font-size: 16px;
    }
  }

  &--success {
    background-color: mat-color($gmb-app-accent, 50);
    i, .review-response--num { color: mat-color($gmb-app-accent, default); }
  }
  &--info {
    background-color: mat-color($gmb-app-primary, 50);
    i, .review-response--num { color: mat-color($gmb-app-primary, default); }
  }
  &--error {
    background-color: mat-color($gmb-app-warn, 50);
    i, .review-response--num { color: mat-color($gmb-app-warn, default); }
  }

  &--xs {
    padding: 8px 10px;
    i {
      font-size: 12px;
      margin-right: 10px;
    }
    p { font-size: 9px; }
  }
}

.comments {
  @extend .d-flex;
  @extend .align-items-center;

  @media (max-width: 1550px) {
    &:not(.comments--xs) {
      height: 52px;
    }
  }

  p {
    flex-grow: 1;
    @media (max-width: 1550px) {
      &.txt--md { font-size: 12px; }
    }
  }

  &--info {
    background-color: mat-color($gmb-app-primary, 50);
    i, .review-response--num { color: mat-color($gmb-app-primary, default); }
  }

  &--xs {
    padding: 8px 10px;
    i {
      font-size: 12px;
      margin-right: 10px;
    }
    p { font-size: 9px; }
  }
}


.reviewer {
  padding: 15px 0;

  &__img {
    width: 65px;
    height: 65px;
    border-radius: 50px;
    overflow: hidden;
    background-color: #f3f3f3;
    margin-right: 15px;
    flex-shrink: 0;

    &--sm {
      width: 35px;
      height: 35px;
    }
  }

  &__reply {
    margin-top: 20px;
    margin-left: 32px;
    padding-left: 20px;
    border-left: 2px solid #f3f3f3;
  }

  &__comment {
    padding: 10px 0;
    @extend .txt--gray;
  }
}
