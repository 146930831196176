
/*----------------------------------------------------------------------------*/
/*#TOOLTIP STYLE*/
/*----------------------------------------------------------------------------*/
.tooltip-wrapper {
  position: absolute;
  top:    25px;
  right: -30px;
}

.mat-tooltip.tooltip {
  font-size: 12px;
  cursor: pointer;

  &--blue {
    background-color: mat-color($gmb-app-primary, 50);
    color: mat-color($gmb-app-primary, default);
  }
  &--orange {
    background-color: #fdefda;
    color: #f3a637;
  }
  &--green {
    background-color: mat-color($gmb-app-accent, 50);
    color: mat-color($gmb-app-accent, default);
  }
  &--red {
    background-color: mat-color($gmb-app-warn, 50);
    color: mat-color($gmb-app-warn, default);
  }
  &--white {
    background-color: white;
    color: black;
  }
  &--black {
    background-color: black;
    color: white;
  }
  &--info {
    display: inline-block;
    background-color: #F3F3F8;
    @extend .txt--gray;
    width:  20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    padding: 0;
    margin: 0 0 0 15px;
    border-radius: 50px;
  }
}

.mat-tooltip.mat-tooltip--override {
  position: relative;
  overflow: visible;
  font-size: 14px;
  line-height: 1.5;
  max-width: 450px;
  padding: 15px!important;
  background: #fff;
  color: #000;
  box-shadow: 0 4px 10px -2px rgba(0,0,0,.2);

  &::before {
    content: '';
    display: inline-block;
    width:  0;
    height: 0;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
}

.mat-chip-corner {
  position: absolute !important;
  float: right;
  right: 10px;
  top: 12px;
}
