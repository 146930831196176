
/*----------------------------------------------------------------------------*/
/*#MAIN/GLOBAL STYLE*/
/*----------------------------------------------------------------------------*/

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EFF0F3;
}

h1,
h2,
h3,
h4,
h6,
h5 {
  @extend .txt--gray;
  &.txt--black { @extend .txt--black; }
}

h1 {
  font-size: 18px;
  line-height: 1;
}

h2,
.heading-h2{
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;

  &.heading--medium { font-size: 17px; }
  &.heading--big { font-size: 18px; }
  &.heading--bigger { font-size: 24px; }
  &.heading--xl { font-size: 28px; }
}

h3 {
  font-size: 17px;
  line-height: 1.4;

  &.heading--bigger { font-size: 22px; }
}

h4 {
  font-size: 16px;
  line-height: 1.4;
  &.heading--bigger { font-size: 20px; }
}

h5 {
  font-size: 15px;
  line-height: 1.4;
}

h6 {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
}

p,
ul li,
ol li,
.txt--normal {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.content {
  position: relative;
  padding: 70px 35px 80px;
  min-height: 100vh;

  &--full {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 90px;

    &.content-header {
      padding-top: 128px;
      min-height: 100vh;
    }
  }

  &--padding {
    padding: 100px 30px;
    .content__header--up { margin-top: -25px; }

    .footer {
      left: 30px;
      right: 30px;
    }
  }
}

//update main padding if there is trial message
.trial-message-wrapper {

  .header,
  .header__aside.header--fixed { top: 37px; }

  .mat-sidenav-fixed { top: 100px !important; }
  .mat-sidenav .mat-drawer-inner-container {
    height: calc(100vh - 63px);
    background-color: mat-color(mat-palette($gmb-darkblue, 800));
  }
  .dashboard-content { padding-top: 158px; }

  .filter { top: 101px; }

  .content {
    padding-top: 105px;

    &--full {
      &.content-header { padding-top: 158px; }
    }
    &.content--padding { padding-top: 135px; }
  }
}

.container--up { margin-top: -64px; }

.dashboard-content {
  position: relative;
  padding-top:    128px;
  padding-bottom:  70px;
  width: 100%;
  min-height: 100vh;

  &--sm {
    padding-top:  125px;
    .container--padding-lg { padding-top: 30px; }
  }
}

.txt--xxxl {
  font-size: 65px;
  line-height: 1;

  @media (max-width: 1400px) { font-size: 50px; }

  &-sm { font-size: 55px; }
}

.txt--price {
  font-size: 48px;
  line-height: 1;
}
.txt--price-sup {
  font-size: 26px;
  line-height: 1;
}

.txt--xxl {
  font-size: 35px;
  line-height: 1;
}

.txt--xl {
  font-size: 30px;
  line-height: 1.2;
}
.txt--lg {
  font-size: 20px;
  line-height: 1.2;
}

.txt--md {
  font-size: 16px;
  line-height: 1.2;
}

.txt--ex-md {
  font-size: 13px;
  line-height: 1.2;
}

.txt--sm {
  font-size: 12px;
  line-height: 1;
}

.txt--xs {
  font-size: 10px;
  line-height: 1;
}

.txt--absolute {
  position: absolute;
  top: 10px;
  right: 0;
}

.txt--lg-md {
  @media (min-width: 1400px ) {
    font-size: 16px;
    line-height: 1;
  }
}

.content__header { padding: 10px 0 15px; }

.content-header {
  padding: 20px;
  min-height: 100vh;
}

.mat-chip-list-stacked  .mat-chip-list-wrapper .mat-chip--half.mat-standard-chip { width: 50%; }

.mat-chip-list-wrapper .mat-chip.mat-standard-chip,
.mat-chip-list-wrapper .mat-chip.mat-standard-chip.mat-chip-disabled { cursor: pointer; }

.tag__content .mat-chip-list-wrapper .mat-chip.mat-standard-chip,
.notification__header .mat-chip-list-wrapper .mat-chip.mat-standard-chip.mat-chip-disabled { cursor: default; }

.chips-row--lg {
  @media (max-width: 1450px) {
    &.row {
      margin-left:  -8px;
      margin-right: -8px;

      [class*='col-'] {
        padding-left:  8px;
        padding-right: 8px;
        margin-bottom: 16px!important;
      }

      .chip-title { font-size: 13px; }
    }
  }
}

.spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  z-index: 9999;

  &--fixed {
    position: fixed;
    top:    0;
    left:   0;
    bottom: 0;
    right:  0;
    z-index: 99999;
  }

  &--bg { background-color: rgba(26, 35, 51, .8); }

  &--sticky { position: sticky; }
}

.thumbnails-wrapper {
  @extend .d-flex;
  @extend .justify-content-start;
  @extend .flex-column;
  max-width: 100px;

  .thumbnail {
    position: relative;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      backface-visibility: hidden;
      transition: transform .3s ease-in-out;
    }

    &:hover {
      img { transform: scale(1.1); }
    }
  }
  p {
    text-align: center;
    margin: 10px 0;
    word-break: break-word;
  }
}

.logo img { max-width: 170px; }

.reply {
  border-left: 3px solid #d2d2d2;
  padding: 10px;
}

blockquote {
  background-color: #f3f3f3;
  padding: 10px;
}

.mat-chip-list-wrapper.mat-chip-list-wrapper { flex-wrap: nowrap; }
.mat-chip-list-wrapper .mat-chip.mat-standard-chip.mat-chip-disabled { opacity: 1; }

.mat-expansion-panel-body .row { box-sizing: border-box; }

.chart-wrapper { height: 300px; }

//override skeleton last span element
span.loader:not(.sidebar__title span.loader) { background-color: #e7e7e7!important; }
span.loader:last-child { margin-bottom: 0!important; }

.mat-menu--lg {
  &.mat-menu-panel {
    max-width: inherit;
    width: 520px;
    max-height: 190px;

    button {
      width: 100%;
      height: 35px;
      line-height: 35px;
    }
  }
  &.mat-menu--noheight { min-height: inherit; }
}

//force show scrollbar (for MacOS)
.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
  border-left: 1px solid #E7E7E7;
  box-shadow: 1px 0 1px 0 #F6F6F6 inset, -1px 0 1px 0 #F6F6F6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
  border-top: 1px solid #E7E7E7;
  box-shadow: 0 1px 1px 0 #F6F6F6 inset, 0 -1px 1px 0 #F6F6F6 inset;
}

.force-show-scrollbars ::-webkit-scrollbar {
  -webkit-appearance: none;
  background-color: #FAFAFA;
  width: 16px;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #C1C1C1;
  border-color: transparent;
  border-radius: 9px 8px 8px 9px;
  border-style: solid;
  border-width: 3px 3px 3px 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.mat-autocomplete-panel.mat-autocomplete-panel--dimensions {
  max-height: 285px;
  min-width: 640px;
  max-width: 100%;
}

.search-result.mat-option {
  height: inherit;
  line-height: 1;
  padding-left: 15px;
  padding-right: 15px;

  .box__item { position: relative; }
}

.credit-card {
  max-width: 60px;
  margin-right: 15px;

  img {
    display: block;
    max-width: 100%;
  }
}


//table elements
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width:  30px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  margin-right: 20px;

  &--lg {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &--green {
    background-color: #d3f9d6;
    color: #65c36c;
  }
}


//billing icon
.billing-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width:  40px;
  height: 40px;
  border-radius: 100px;
  background-color: #fff;
  margin-right: 15px;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}
