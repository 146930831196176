/*----------------------------------------------------------------------------*/
/*#FILTER STYLE*/
/*----------------------------------------------------------------------------*/

.filter {
  position: fixed;
  top: 64px;
  left:  0;
  right: 0;
  z-index: 9;
  padding: 10px 20px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.04);

  &__item {
    position: relative;
    padding: 0 5px;

    &__btn,
    &__btn.mat-button,
    .input-field {
      font-size: 14px;
      line-height: 1;
      border: 1px solid $lightgray;
      border-radius: 5px;
      height: 40px;
    }

    &__btn,
    &__btn.mat-button {
      position: relative;
      min-width: 115px;
      padding: 12px 40px 12px 20px;
      font-weight: 400!important;

      [class*="fa-angle"]    {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__btn--md.mat-button {
      min-width: 145px;
      text-align: left;
    }
    .input-field { padding: 11px 40px; }

    i {font-size: 16px; }

    [class*="fa-calendar"] { margin-right: 20px; }

    &__icon {
      @extend .menu__item__icon;
    }

    &__content.mat-menu-panel {
      max-width: 500px;
      width: 500px;
      padding: 10px;
    }

    &__footer {
      padding-top: 20px;

    }
  }

  &__header {
    padding: 0 0 20px;
  }

  &__content {
    padding: 20px 0 10px;
    border-top:    1px solid $lightgray;
    border-bottom: 1px solid $lightgray;
  }
}

.filter__item,
.input-group {
  [class*="fa-angle"],
  [class*="fa-calendar"] { color: rgba(0,0,0,.54); }

  [class*="fa-angle"] {
    font-size: 20px;
  }

}


//calendar overrides
.md-drppicker.md-drppicker {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-width: 380px;
  width: 100%;

  thead tr:first-child { margin-bottom: 10px; }

  td, th  { vertical-align: middle; }

  li { margin-bottom: 0; }

  &.shown { margin-top: 1px; }

  .ranges {
    width: 120px;
    padding-right: 10px;
    border-right: 1px solid $lightgray;

    ul li {
      &:hover { background-color: transparent; }
      button {
        background-color: transparent;
        transition: .3s ease-in-out;
        padding: 10px;
        margin-bottom: 5px;

        &:hover,
        &.active {
          background-color: #ECF3FE;
          color: #4479BA;
        }
      }
    }
  }

  .calendar.left {
    padding-bottom: 90px;
    &::before {
      content: 'Start';
      font-size: 15px;
      text-align: center;
      margin-left: 100px;
    }
  }

  .calendar.right {
    &::before {
      content: 'End';
      font-size: 15px;
      text-align: center;
      margin-left: 100px;
    }

    .calendar-table {
      margin-top: 1px;
    }
  }

  .buttons {
    position: absolute;
    bottom: 20px;
    right:  20px;
    left: 140px;
    margin: 0;

    .buttons_input {
      display: flex;
      justify-content: space-between;
    }
  }

  td.off,
  td.off.end-date,
  td.off.in-range,
  td.off.start-date {
    &.active { color: #fff; }
  }

  td.off.in-range,
  td.in-range { background-color: mat-color($gmb-app-accent, 100); }

  td.active,
  td.active:hover,
  td.start-date,
  td.start-date:hover,
  td.end-date,
  td.end-date:hover,
  td.end-date.in-range,
  td.start-date.in-range {
    background-color: mat-color($gmb-app-accent, default);
  }

  .btn {
    padding: 10px 25px;
    margin: 5px;
    box-shadow: none;
    background-color: mat-color($gmb-app-accent, default);
    color: #fff;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1;

    &:focus,
    &:hover { background-color: mat-color($gmb-app-accent, default); }

    &.btn-default {
      background-color: transparent;
      padding: 0;
      position: relative;
      color: #7d7d7d!important;
      &:focus,
      &:hover { background-color: transparent; }

      &::before {
        content: '';
        position: absolute;
        bottom: 9px;
        top: inherit;
        left:  0;
        right: 0;
        height: 1px;
        background-color: #7d7d7d;
        width: 100%;
        border-radius: 0;
        transform: none;
      }
    }
  }

  .week-days th {
    font-weight: 700;
    color: #000;
  }

}

.dialog__content .md-drppicker.md-drppicker {
  position: fixed;
  left: inherit!important;
}

