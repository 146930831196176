
/*----------------------------------------------------------------------------*/
/*#EXPANSION PANEL STYLE*/
/*----------------------------------------------------------------------------*/

.mat-expansion-panel-header {
  position: relative;
  height: 111px!important;

  &.header-height--sm {
    height: 70px!important;
    .mat-expansion-indicator::after { padding: 4px!important; }
  }

  &:hover .mat-expansion-panel-icon {
    &::before,
    &::after {
      background-color: mat-color($gmb-app-accent, default);
    }
  }
}

.mat-expanded .mat-expansion-panel-icon {
  &::before,
  &::after {
    background-color: mat-color($gmb-app-accent, default);
  }

  &::after {
    transform: translateX(-50%) rotate(90deg);
  }
}

.mat-expansion-panel-body { padding: 20px!important; }

.mat-expansion-panel-icon {
  display: block;
  position: relative;
  width: 23px;
  height: 23px;

  &::before,
  &::after {
    content: "";
    background-color: #8a8e9f;
    position: absolute;
    transform-origin: center;
    transition: all .3s ease-in-out;
  }
  &::before {
    height: 3px;
    width: 23px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    height: 23px;
    width: 3px;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
  }
}

.mat-expansion-indicator {
  position: absolute;
  right: 20px;
  top:   50%;
  width: 20px;
  margin-top: -9px;
  &::after {
    transform-origin: center;
    padding: 7px!important;
  }
}

.no-padding {
  .mat-expansion-panel-header,
  .mat-expansion-panel-body { padding: 0!important; }
}

.no-hover .mat-expansion-panel-header { background: transparent!important; }
.no-shadow .mat-expansion-panel { box-shadow: none!important; }

.disabled-pointer {
  pointer-events: none;
}
