/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'variables';

@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-duotone-900.eot');
  src: url('#{$fa-font-path}/fa-duotone-900.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-duotone-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-duotone-900.woff') format('woff'),
  url('#{$fa-font-path}/fa-duotone-900.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-duotone-900.svg#fontawesome') format('svg');
}

.fad {
  position: relative;
  font-family: 'Font Awesome 5 Duotone', sans-serif;
  font-weight: 900;
}

.fad:before {
  position: absolute;
  color: var(--#{$fa-css-prefix}-primary-color, inherit);
  opacity: $fa-primary-opacity;
  opacity: var(--#{$fa-css-prefix}-primary-opacity, #{$fa-primary-opacity});
}

.fad:after {
  color: var(--#{$fa-css-prefix}-secondary-color, inherit);
  opacity: $fa-secondary-opacity;
  opacity: var(--#{$fa-css-prefix}-secondary-opacity, #{$fa-secondary-opacity});
}

.fad.#{$fa-css-prefix}-swap-opacity:before {
  opacity: $fa-secondary-opacity;
  opacity: var(--#{$fa-css-prefix}-secondary-opacity, #{$fa-secondary-opacity});
}

.fad.#{$fa-css-prefix}-swap-opacity:after {
  opacity: $fa-primary-opacity;
  opacity: var(--#{$fa-css-prefix}-primary-opacity, #{$fa-primary-opacity});
}

.fad.#{$fa-css-prefix}-inverse {
  color: $fa-inverse;
}

.fad.#{$fa-css-prefix}-stack-1x, .fad.#{$fa-css-prefix}-stack-2x {
  position: absolute;
}

.fad.#{$fa-css-prefix}-stack-1x:before,
.fad.#{$fa-css-prefix}-stack-2x:before,
.fad.#{$fa-css-prefix}-fw:before {
  left: 50%;
  transform: translateX(-50%);
}

@function fa-content-secondary($fa-var) {
  @return unquote("\"#{ str-insert($fa-var, "10", 2) }\"");
}

.fad.#{$fa-css-prefix}-abacus:after { content: fa-content-secondary($fa-var-abacus); }
.fad.#{$fa-css-prefix}-acorn:after { content: fa-content-secondary($fa-var-acorn); }
.fad.#{$fa-css-prefix}-ad:after { content: fa-content-secondary($fa-var-ad); }
.fad.#{$fa-css-prefix}-address-book:after { content: fa-content-secondary($fa-var-address-book); }
.fad.#{$fa-css-prefix}-address-card:after { content: fa-content-secondary($fa-var-address-card); }
.fad.#{$fa-css-prefix}-adjust:after { content: fa-content-secondary($fa-var-adjust); }
.fad.#{$fa-css-prefix}-air-freshener:after { content: fa-content-secondary($fa-var-air-freshener); }
.fad.#{$fa-css-prefix}-alarm-clock:after { content: fa-content-secondary($fa-var-alarm-clock); }
.fad.#{$fa-css-prefix}-alarm-exclamation:after { content: fa-content-secondary($fa-var-alarm-exclamation); }
.fad.#{$fa-css-prefix}-alarm-plus:after { content: fa-content-secondary($fa-var-alarm-plus); }
.fad.#{$fa-css-prefix}-alarm-snooze:after { content: fa-content-secondary($fa-var-alarm-snooze); }
.fad.#{$fa-css-prefix}-alicorn:after { content: fa-content-secondary($fa-var-alicorn); }
.fad.#{$fa-css-prefix}-align-center:after { content: fa-content-secondary($fa-var-align-center); }
.fad.#{$fa-css-prefix}-align-justify:after { content: fa-content-secondary($fa-var-align-justify); }
.fad.#{$fa-css-prefix}-align-left:after { content: fa-content-secondary($fa-var-align-left); }
.fad.#{$fa-css-prefix}-align-right:after { content: fa-content-secondary($fa-var-align-right); }
.fad.#{$fa-css-prefix}-align-slash:after { content: fa-content-secondary($fa-var-align-slash); }
.fad.#{$fa-css-prefix}-allergies:after { content: fa-content-secondary($fa-var-allergies); }
.fad.#{$fa-css-prefix}-ambulance:after { content: fa-content-secondary($fa-var-ambulance); }
.fad.#{$fa-css-prefix}-american-sign-language-interpreting:after { content: fa-content-secondary($fa-var-american-sign-language-interpreting); }
.fad.#{$fa-css-prefix}-analytics:after { content: fa-content-secondary($fa-var-analytics); }
.fad.#{$fa-css-prefix}-anchor:after { content: fa-content-secondary($fa-var-anchor); }
.fad.#{$fa-css-prefix}-angel:after { content: fa-content-secondary($fa-var-angel); }
.fad.#{$fa-css-prefix}-angle-double-down:after { content: fa-content-secondary($fa-var-angle-double-down); }
.fad.#{$fa-css-prefix}-angle-double-left:after { content: fa-content-secondary($fa-var-angle-double-left); }
.fad.#{$fa-css-prefix}-angle-double-right:after { content: fa-content-secondary($fa-var-angle-double-right); }
.fad.#{$fa-css-prefix}-angle-double-up:after { content: fa-content-secondary($fa-var-angle-double-up); }
.fad.#{$fa-css-prefix}-angle-down:after { content: fa-content($fa-var-angle-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-angle-left:after { content: fa-content($fa-var-angle-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-angle-right:after { content: fa-content($fa-var-angle-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-angle-up:after { content: fa-content($fa-var-angle-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-angry:after { content: fa-content-secondary($fa-var-angry); }
.fad.#{$fa-css-prefix}-ankh:after { content: fa-content($fa-var-ankh); visibility: hidden; }
.fad.#{$fa-css-prefix}-apple-alt:after { content: fa-content-secondary($fa-var-apple-alt); }
.fad.#{$fa-css-prefix}-apple-crate:after { content: fa-content-secondary($fa-var-apple-crate); }
.fad.#{$fa-css-prefix}-archive:after { content: fa-content-secondary($fa-var-archive); }
.fad.#{$fa-css-prefix}-archway:after { content: fa-content-secondary($fa-var-archway); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-down:after { content: fa-content-secondary($fa-var-arrow-alt-circle-down); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-left:after { content: fa-content-secondary($fa-var-arrow-alt-circle-left); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-right:after { content: fa-content-secondary($fa-var-arrow-alt-circle-right); }
.fad.#{$fa-css-prefix}-arrow-alt-circle-up:after { content: fa-content-secondary($fa-var-arrow-alt-circle-up); }
.fad.#{$fa-css-prefix}-arrow-alt-down:after { content: fa-content($fa-var-arrow-alt-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-alt-from-bottom:after { content: fa-content-secondary($fa-var-arrow-alt-from-bottom); }
.fad.#{$fa-css-prefix}-arrow-alt-from-left:after { content: fa-content-secondary($fa-var-arrow-alt-from-left); }
.fad.#{$fa-css-prefix}-arrow-alt-from-right:after { content: fa-content-secondary($fa-var-arrow-alt-from-right); }
.fad.#{$fa-css-prefix}-arrow-alt-from-top:after { content: fa-content-secondary($fa-var-arrow-alt-from-top); }
.fad.#{$fa-css-prefix}-arrow-alt-left:after { content: fa-content($fa-var-arrow-alt-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-alt-right:after { content: fa-content($fa-var-arrow-alt-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-alt-square-down:after { content: fa-content-secondary($fa-var-arrow-alt-square-down); }
.fad.#{$fa-css-prefix}-arrow-alt-square-left:after { content: fa-content-secondary($fa-var-arrow-alt-square-left); }
.fad.#{$fa-css-prefix}-arrow-alt-square-right:after { content: fa-content-secondary($fa-var-arrow-alt-square-right); }
.fad.#{$fa-css-prefix}-arrow-alt-square-up:after { content: fa-content-secondary($fa-var-arrow-alt-square-up); }
.fad.#{$fa-css-prefix}-arrow-alt-to-bottom:after { content: fa-content-secondary($fa-var-arrow-alt-to-bottom); }
.fad.#{$fa-css-prefix}-arrow-alt-to-left:after { content: fa-content-secondary($fa-var-arrow-alt-to-left); }
.fad.#{$fa-css-prefix}-arrow-alt-to-right:after { content: fa-content-secondary($fa-var-arrow-alt-to-right); }
.fad.#{$fa-css-prefix}-arrow-alt-to-top:after { content: fa-content-secondary($fa-var-arrow-alt-to-top); }
.fad.#{$fa-css-prefix}-arrow-alt-up:after { content: fa-content($fa-var-arrow-alt-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-circle-down:after { content: fa-content-secondary($fa-var-arrow-circle-down); }
.fad.#{$fa-css-prefix}-arrow-circle-left:after { content: fa-content-secondary($fa-var-arrow-circle-left); }
.fad.#{$fa-css-prefix}-arrow-circle-right:after { content: fa-content-secondary($fa-var-arrow-circle-right); }
.fad.#{$fa-css-prefix}-arrow-circle-up:after { content: fa-content-secondary($fa-var-arrow-circle-up); }
.fad.#{$fa-css-prefix}-arrow-down:after { content: fa-content($fa-var-arrow-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-from-bottom:after { content: fa-content-secondary($fa-var-arrow-from-bottom); }
.fad.#{$fa-css-prefix}-arrow-from-left:after { content: fa-content-secondary($fa-var-arrow-from-left); }
.fad.#{$fa-css-prefix}-arrow-from-right:after { content: fa-content-secondary($fa-var-arrow-from-right); }
.fad.#{$fa-css-prefix}-arrow-from-top:after { content: fa-content-secondary($fa-var-arrow-from-top); }
.fad.#{$fa-css-prefix}-arrow-left:after { content: fa-content($fa-var-arrow-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-right:after { content: fa-content($fa-var-arrow-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrow-square-down:after { content: fa-content-secondary($fa-var-arrow-square-down); }
.fad.#{$fa-css-prefix}-arrow-square-left:after { content: fa-content-secondary($fa-var-arrow-square-left); }
.fad.#{$fa-css-prefix}-arrow-square-right:after { content: fa-content-secondary($fa-var-arrow-square-right); }
.fad.#{$fa-css-prefix}-arrow-square-up:after { content: fa-content-secondary($fa-var-arrow-square-up); }
.fad.#{$fa-css-prefix}-arrow-to-bottom:after { content: fa-content-secondary($fa-var-arrow-to-bottom); }
.fad.#{$fa-css-prefix}-arrow-to-left:after { content: fa-content-secondary($fa-var-arrow-to-left); }
.fad.#{$fa-css-prefix}-arrow-to-right:after { content: fa-content-secondary($fa-var-arrow-to-right); }
.fad.#{$fa-css-prefix}-arrow-to-top:after { content: fa-content-secondary($fa-var-arrow-to-top); }
.fad.#{$fa-css-prefix}-arrow-up:after { content: fa-content($fa-var-arrow-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrows:after { content: fa-content($fa-var-arrows); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrows-alt:after { content: fa-content($fa-var-arrows-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrows-alt-h:after { content: fa-content($fa-var-arrows-alt-h); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrows-alt-v:after { content: fa-content($fa-var-arrows-alt-v); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrows-h:after { content: fa-content($fa-var-arrows-h); visibility: hidden; }
.fad.#{$fa-css-prefix}-arrows-v:after { content: fa-content($fa-var-arrows-v); visibility: hidden; }
.fad.#{$fa-css-prefix}-assistive-listening-systems:after { content: fa-content-secondary($fa-var-assistive-listening-systems); }
.fad.#{$fa-css-prefix}-asterisk:after { content: fa-content($fa-var-asterisk); visibility: hidden; }
.fad.#{$fa-css-prefix}-at:after { content: fa-content($fa-var-at); visibility: hidden; }
.fad.#{$fa-css-prefix}-atlas:after { content: fa-content-secondary($fa-var-atlas); }
.fad.#{$fa-css-prefix}-atom:after { content: fa-content-secondary($fa-var-atom); }
.fad.#{$fa-css-prefix}-atom-alt:after { content: fa-content-secondary($fa-var-atom-alt); }
.fad.#{$fa-css-prefix}-audio-description:after { content: fa-content-secondary($fa-var-audio-description); }
.fad.#{$fa-css-prefix}-award:after { content: fa-content-secondary($fa-var-award); }
.fad.#{$fa-css-prefix}-axe:after { content: fa-content-secondary($fa-var-axe); }
.fad.#{$fa-css-prefix}-axe-battle:after { content: fa-content-secondary($fa-var-axe-battle); }
.fad.#{$fa-css-prefix}-baby:after { content: fa-content-secondary($fa-var-baby); }
.fad.#{$fa-css-prefix}-baby-carriage:after { content: fa-content-secondary($fa-var-baby-carriage); }
.fad.#{$fa-css-prefix}-backpack:after { content: fa-content-secondary($fa-var-backpack); }
.fad.#{$fa-css-prefix}-backspace:after { content: fa-content-secondary($fa-var-backspace); }
.fad.#{$fa-css-prefix}-backward:after { content: fa-content-secondary($fa-var-backward); }
.fad.#{$fa-css-prefix}-bacon:after { content: fa-content-secondary($fa-var-bacon); }
.fad.#{$fa-css-prefix}-badge:after { content: fa-content($fa-var-badge); visibility: hidden; }
.fad.#{$fa-css-prefix}-badge-check:after { content: fa-content-secondary($fa-var-badge-check); }
.fad.#{$fa-css-prefix}-badge-dollar:after { content: fa-content-secondary($fa-var-badge-dollar); }
.fad.#{$fa-css-prefix}-badge-percent:after { content: fa-content-secondary($fa-var-badge-percent); }
.fad.#{$fa-css-prefix}-badger-honey:after { content: fa-content-secondary($fa-var-badger-honey); }
.fad.#{$fa-css-prefix}-bags-shopping:after { content: fa-content-secondary($fa-var-bags-shopping); }
.fad.#{$fa-css-prefix}-balance-scale:after { content: fa-content-secondary($fa-var-balance-scale); }
.fad.#{$fa-css-prefix}-balance-scale-left:after { content: fa-content-secondary($fa-var-balance-scale-left); }
.fad.#{$fa-css-prefix}-balance-scale-right:after { content: fa-content-secondary($fa-var-balance-scale-right); }
.fad.#{$fa-css-prefix}-ball-pile:after { content: fa-content-secondary($fa-var-ball-pile); }
.fad.#{$fa-css-prefix}-ballot:after { content: fa-content-secondary($fa-var-ballot); }
.fad.#{$fa-css-prefix}-ballot-check:after { content: fa-content-secondary($fa-var-ballot-check); }
.fad.#{$fa-css-prefix}-ban:after { content: fa-content($fa-var-ban); visibility: hidden; }
.fad.#{$fa-css-prefix}-band-aid:after { content: fa-content-secondary($fa-var-band-aid); }
.fad.#{$fa-css-prefix}-barcode:after { content: fa-content($fa-var-barcode); visibility: hidden; }
.fad.#{$fa-css-prefix}-barcode-alt:after { content: fa-content-secondary($fa-var-barcode-alt); }
.fad.#{$fa-css-prefix}-barcode-read:after { content: fa-content-secondary($fa-var-barcode-read); }
.fad.#{$fa-css-prefix}-barcode-scan:after { content: fa-content-secondary($fa-var-barcode-scan); }
.fad.#{$fa-css-prefix}-bars:after { content: fa-content($fa-var-bars); visibility: hidden; }
.fad.#{$fa-css-prefix}-baseball:after { content: fa-content-secondary($fa-var-baseball); }
.fad.#{$fa-css-prefix}-baseball-ball:after { content: fa-content-secondary($fa-var-baseball-ball); }
.fad.#{$fa-css-prefix}-basketball-ball:after { content: fa-content-secondary($fa-var-basketball-ball); }
.fad.#{$fa-css-prefix}-basketball-hoop:after { content: fa-content-secondary($fa-var-basketball-hoop); }
.fad.#{$fa-css-prefix}-bat:after { content: fa-content($fa-var-bat); visibility: hidden; }
.fad.#{$fa-css-prefix}-bath:after { content: fa-content-secondary($fa-var-bath); }
.fad.#{$fa-css-prefix}-battery-bolt:after { content: fa-content-secondary($fa-var-battery-bolt); }
.fad.#{$fa-css-prefix}-battery-empty:after { content: fa-content($fa-var-battery-empty); visibility: hidden; }
.fad.#{$fa-css-prefix}-battery-full:after { content: fa-content-secondary($fa-var-battery-full); }
.fad.#{$fa-css-prefix}-battery-half:after { content: fa-content-secondary($fa-var-battery-half); }
.fad.#{$fa-css-prefix}-battery-quarter:after { content: fa-content-secondary($fa-var-battery-quarter); }
.fad.#{$fa-css-prefix}-battery-slash:after { content: fa-content-secondary($fa-var-battery-slash); }
.fad.#{$fa-css-prefix}-battery-three-quarters:after { content: fa-content-secondary($fa-var-battery-three-quarters); }
.fad.#{$fa-css-prefix}-bed:after { content: fa-content-secondary($fa-var-bed); }
.fad.#{$fa-css-prefix}-beer:after { content: fa-content-secondary($fa-var-beer); }
.fad.#{$fa-css-prefix}-bell:after { content: fa-content-secondary($fa-var-bell); }
.fad.#{$fa-css-prefix}-bell-exclamation:after { content: fa-content-secondary($fa-var-bell-exclamation); }
.fad.#{$fa-css-prefix}-bell-plus:after { content: fa-content-secondary($fa-var-bell-plus); }
.fad.#{$fa-css-prefix}-bell-school:after { content: fa-content-secondary($fa-var-bell-school); }
.fad.#{$fa-css-prefix}-bell-school-slash:after { content: fa-content-secondary($fa-var-bell-school-slash); }
.fad.#{$fa-css-prefix}-bell-slash:after { content: fa-content-secondary($fa-var-bell-slash); }
.fad.#{$fa-css-prefix}-bells:after { content: fa-content-secondary($fa-var-bells); }
.fad.#{$fa-css-prefix}-bezier-curve:after { content: fa-content-secondary($fa-var-bezier-curve); }
.fad.#{$fa-css-prefix}-bible:after { content: fa-content-secondary($fa-var-bible); }
.fad.#{$fa-css-prefix}-bicycle:after { content: fa-content-secondary($fa-var-bicycle); }
.fad.#{$fa-css-prefix}-biking:after { content: fa-content-secondary($fa-var-biking); }
.fad.#{$fa-css-prefix}-biking-mountain:after { content: fa-content-secondary($fa-var-biking-mountain); }
.fad.#{$fa-css-prefix}-binoculars:after { content: fa-content-secondary($fa-var-binoculars); }
.fad.#{$fa-css-prefix}-biohazard:after { content: fa-content-secondary($fa-var-biohazard); }
.fad.#{$fa-css-prefix}-birthday-cake:after { content: fa-content-secondary($fa-var-birthday-cake); }
.fad.#{$fa-css-prefix}-blanket:after { content: fa-content($fa-var-blanket); visibility: hidden; }
.fad.#{$fa-css-prefix}-blender:after { content: fa-content-secondary($fa-var-blender); }
.fad.#{$fa-css-prefix}-blender-phone:after { content: fa-content-secondary($fa-var-blender-phone); }
.fad.#{$fa-css-prefix}-blind:after { content: fa-content-secondary($fa-var-blind); }
.fad.#{$fa-css-prefix}-blog:after { content: fa-content-secondary($fa-var-blog); }
.fad.#{$fa-css-prefix}-bold:after { content: fa-content($fa-var-bold); visibility: hidden; }
.fad.#{$fa-css-prefix}-bolt:after { content: fa-content($fa-var-bolt); visibility: hidden; }
.fad.#{$fa-css-prefix}-bomb:after { content: fa-content-secondary($fa-var-bomb); }
.fad.#{$fa-css-prefix}-bone:after { content: fa-content($fa-var-bone); visibility: hidden; }
.fad.#{$fa-css-prefix}-bone-break:after { content: fa-content-secondary($fa-var-bone-break); }
.fad.#{$fa-css-prefix}-bong:after { content: fa-content-secondary($fa-var-bong); }
.fad.#{$fa-css-prefix}-book:after { content: fa-content-secondary($fa-var-book); }
.fad.#{$fa-css-prefix}-book-alt:after { content: fa-content-secondary($fa-var-book-alt); }
.fad.#{$fa-css-prefix}-book-dead:after { content: fa-content-secondary($fa-var-book-dead); }
.fad.#{$fa-css-prefix}-book-heart:after { content: fa-content-secondary($fa-var-book-heart); }
.fad.#{$fa-css-prefix}-book-medical:after { content: fa-content-secondary($fa-var-book-medical); }
.fad.#{$fa-css-prefix}-book-open:after { content: fa-content-secondary($fa-var-book-open); }
.fad.#{$fa-css-prefix}-book-reader:after { content: fa-content-secondary($fa-var-book-reader); }
.fad.#{$fa-css-prefix}-book-spells:after { content: fa-content-secondary($fa-var-book-spells); }
.fad.#{$fa-css-prefix}-book-user:after { content: fa-content-secondary($fa-var-book-user); }
.fad.#{$fa-css-prefix}-bookmark:after { content: fa-content-secondary($fa-var-bookmark); }
.fad.#{$fa-css-prefix}-books:after { content: fa-content-secondary($fa-var-books); }
.fad.#{$fa-css-prefix}-books-medical:after { content: fa-content-secondary($fa-var-books-medical); }
.fad.#{$fa-css-prefix}-boot:after { content: fa-content-secondary($fa-var-boot); }
.fad.#{$fa-css-prefix}-booth-curtain:after { content: fa-content-secondary($fa-var-booth-curtain); }
.fad.#{$fa-css-prefix}-border-all:after { content: fa-content($fa-var-border-all); visibility: hidden; }
.fad.#{$fa-css-prefix}-border-bottom:after { content: fa-content-secondary($fa-var-border-bottom); }
.fad.#{$fa-css-prefix}-border-inner:after { content: fa-content-secondary($fa-var-border-inner); }
.fad.#{$fa-css-prefix}-border-left:after { content: fa-content-secondary($fa-var-border-left); }
.fad.#{$fa-css-prefix}-border-none:after { content: fa-content($fa-var-border-none); visibility: hidden; }
.fad.#{$fa-css-prefix}-border-outer:after { content: fa-content-secondary($fa-var-border-outer); }
.fad.#{$fa-css-prefix}-border-right:after { content: fa-content-secondary($fa-var-border-right); }
.fad.#{$fa-css-prefix}-border-style:after { content: fa-content-secondary($fa-var-border-style); }
.fad.#{$fa-css-prefix}-border-style-alt:after { content: fa-content-secondary($fa-var-border-style-alt); }
.fad.#{$fa-css-prefix}-border-top:after { content: fa-content-secondary($fa-var-border-top); }
.fad.#{$fa-css-prefix}-bow-arrow:after { content: fa-content-secondary($fa-var-bow-arrow); }
.fad.#{$fa-css-prefix}-bowling-ball:after { content: fa-content-secondary($fa-var-bowling-ball); }
.fad.#{$fa-css-prefix}-bowling-pins:after { content: fa-content-secondary($fa-var-bowling-pins); }
.fad.#{$fa-css-prefix}-box:after { content: fa-content-secondary($fa-var-box); }
.fad.#{$fa-css-prefix}-box-alt:after { content: fa-content-secondary($fa-var-box-alt); }
.fad.#{$fa-css-prefix}-box-ballot:after { content: fa-content-secondary($fa-var-box-ballot); }
.fad.#{$fa-css-prefix}-box-check:after { content: fa-content-secondary($fa-var-box-check); }
.fad.#{$fa-css-prefix}-box-fragile:after { content: fa-content-secondary($fa-var-box-fragile); }
.fad.#{$fa-css-prefix}-box-full:after { content: fa-content-secondary($fa-var-box-full); }
.fad.#{$fa-css-prefix}-box-heart:after { content: fa-content-secondary($fa-var-box-heart); }
.fad.#{$fa-css-prefix}-box-open:after { content: fa-content-secondary($fa-var-box-open); }
.fad.#{$fa-css-prefix}-box-up:after { content: fa-content-secondary($fa-var-box-up); }
.fad.#{$fa-css-prefix}-box-usd:after { content: fa-content-secondary($fa-var-box-usd); }
.fad.#{$fa-css-prefix}-boxes:after { content: fa-content-secondary($fa-var-boxes); }
.fad.#{$fa-css-prefix}-boxes-alt:after { content: fa-content-secondary($fa-var-boxes-alt); }
.fad.#{$fa-css-prefix}-boxing-glove:after { content: fa-content-secondary($fa-var-boxing-glove); }
.fad.#{$fa-css-prefix}-brackets:after { content: fa-content-secondary($fa-var-brackets); }
.fad.#{$fa-css-prefix}-brackets-curly:after { content: fa-content-secondary($fa-var-brackets-curly); }
.fad.#{$fa-css-prefix}-braille:after { content: fa-content-secondary($fa-var-braille); }
.fad.#{$fa-css-prefix}-brain:after { content: fa-content-secondary($fa-var-brain); }
.fad.#{$fa-css-prefix}-bread-loaf:after { content: fa-content-secondary($fa-var-bread-loaf); }
.fad.#{$fa-css-prefix}-bread-slice:after { content: fa-content($fa-var-bread-slice); visibility: hidden; }
.fad.#{$fa-css-prefix}-briefcase:after { content: fa-content-secondary($fa-var-briefcase); }
.fad.#{$fa-css-prefix}-briefcase-medical:after { content: fa-content-secondary($fa-var-briefcase-medical); }
.fad.#{$fa-css-prefix}-bring-forward:after { content: fa-content-secondary($fa-var-bring-forward); }
.fad.#{$fa-css-prefix}-bring-front:after { content: fa-content-secondary($fa-var-bring-front); }
.fad.#{$fa-css-prefix}-broadcast-tower:after { content: fa-content-secondary($fa-var-broadcast-tower); }
.fad.#{$fa-css-prefix}-broom:after { content: fa-content-secondary($fa-var-broom); }
.fad.#{$fa-css-prefix}-browser:after { content: fa-content-secondary($fa-var-browser); }
.fad.#{$fa-css-prefix}-brush:after { content: fa-content-secondary($fa-var-brush); }
.fad.#{$fa-css-prefix}-bug:after { content: fa-content-secondary($fa-var-bug); }
.fad.#{$fa-css-prefix}-building:after { content: fa-content-secondary($fa-var-building); }
.fad.#{$fa-css-prefix}-bullhorn:after { content: fa-content-secondary($fa-var-bullhorn); }
.fad.#{$fa-css-prefix}-bullseye:after { content: fa-content-secondary($fa-var-bullseye); }
.fad.#{$fa-css-prefix}-bullseye-arrow:after { content: fa-content-secondary($fa-var-bullseye-arrow); }
.fad.#{$fa-css-prefix}-bullseye-pointer:after { content: fa-content-secondary($fa-var-bullseye-pointer); }
.fad.#{$fa-css-prefix}-burger-soda:after { content: fa-content-secondary($fa-var-burger-soda); }
.fad.#{$fa-css-prefix}-burn:after { content: fa-content-secondary($fa-var-burn); }
.fad.#{$fa-css-prefix}-burrito:after { content: fa-content-secondary($fa-var-burrito); }
.fad.#{$fa-css-prefix}-bus:after { content: fa-content-secondary($fa-var-bus); }
.fad.#{$fa-css-prefix}-bus-alt:after { content: fa-content-secondary($fa-var-bus-alt); }
.fad.#{$fa-css-prefix}-bus-school:after { content: fa-content-secondary($fa-var-bus-school); }
.fad.#{$fa-css-prefix}-business-time:after { content: fa-content-secondary($fa-var-business-time); }
.fad.#{$fa-css-prefix}-cabinet-filing:after { content: fa-content-secondary($fa-var-cabinet-filing); }
.fad.#{$fa-css-prefix}-calculator:after { content: fa-content-secondary($fa-var-calculator); }
.fad.#{$fa-css-prefix}-calculator-alt:after { content: fa-content-secondary($fa-var-calculator-alt); }
.fad.#{$fa-css-prefix}-calendar:after { content: fa-content-secondary($fa-var-calendar); }
.fad.#{$fa-css-prefix}-calendar-alt:after { content: fa-content-secondary($fa-var-calendar-alt); }
.fad.#{$fa-css-prefix}-calendar-check:after { content: fa-content-secondary($fa-var-calendar-check); }
.fad.#{$fa-css-prefix}-calendar-day:after { content: fa-content-secondary($fa-var-calendar-day); }
.fad.#{$fa-css-prefix}-calendar-edit:after { content: fa-content-secondary($fa-var-calendar-edit); }
.fad.#{$fa-css-prefix}-calendar-exclamation:after { content: fa-content-secondary($fa-var-calendar-exclamation); }
.fad.#{$fa-css-prefix}-calendar-minus:after { content: fa-content-secondary($fa-var-calendar-minus); }
.fad.#{$fa-css-prefix}-calendar-plus:after { content: fa-content-secondary($fa-var-calendar-plus); }
.fad.#{$fa-css-prefix}-calendar-star:after { content: fa-content-secondary($fa-var-calendar-star); }
.fad.#{$fa-css-prefix}-calendar-times:after { content: fa-content-secondary($fa-var-calendar-times); }
.fad.#{$fa-css-prefix}-calendar-week:after { content: fa-content-secondary($fa-var-calendar-week); }
.fad.#{$fa-css-prefix}-camera:after { content: fa-content-secondary($fa-var-camera); }
.fad.#{$fa-css-prefix}-camera-alt:after { content: fa-content-secondary($fa-var-camera-alt); }
.fad.#{$fa-css-prefix}-camera-retro:after { content: fa-content-secondary($fa-var-camera-retro); }
.fad.#{$fa-css-prefix}-campfire:after { content: fa-content-secondary($fa-var-campfire); }
.fad.#{$fa-css-prefix}-campground:after { content: fa-content-secondary($fa-var-campground); }
.fad.#{$fa-css-prefix}-candle-holder:after { content: fa-content-secondary($fa-var-candle-holder); }
.fad.#{$fa-css-prefix}-candy-cane:after { content: fa-content-secondary($fa-var-candy-cane); }
.fad.#{$fa-css-prefix}-candy-corn:after { content: fa-content-secondary($fa-var-candy-corn); }
.fad.#{$fa-css-prefix}-cannabis:after { content: fa-content-secondary($fa-var-cannabis); }
.fad.#{$fa-css-prefix}-capsules:after { content: fa-content-secondary($fa-var-capsules); }
.fad.#{$fa-css-prefix}-car:after { content: fa-content-secondary($fa-var-car); }
.fad.#{$fa-css-prefix}-car-alt:after { content: fa-content-secondary($fa-var-car-alt); }
.fad.#{$fa-css-prefix}-car-battery:after { content: fa-content-secondary($fa-var-car-battery); }
.fad.#{$fa-css-prefix}-car-building:after { content: fa-content-secondary($fa-var-car-building); }
.fad.#{$fa-css-prefix}-car-bump:after { content: fa-content-secondary($fa-var-car-bump); }
.fad.#{$fa-css-prefix}-car-bus:after { content: fa-content-secondary($fa-var-car-bus); }
.fad.#{$fa-css-prefix}-car-crash:after { content: fa-content-secondary($fa-var-car-crash); }
.fad.#{$fa-css-prefix}-car-garage:after { content: fa-content-secondary($fa-var-car-garage); }
.fad.#{$fa-css-prefix}-car-mechanic:after { content: fa-content-secondary($fa-var-car-mechanic); }
.fad.#{$fa-css-prefix}-car-side:after { content: fa-content-secondary($fa-var-car-side); }
.fad.#{$fa-css-prefix}-car-tilt:after { content: fa-content-secondary($fa-var-car-tilt); }
.fad.#{$fa-css-prefix}-car-wash:after { content: fa-content-secondary($fa-var-car-wash); }
.fad.#{$fa-css-prefix}-caret-circle-down:after { content: fa-content-secondary($fa-var-caret-circle-down); }
.fad.#{$fa-css-prefix}-caret-circle-left:after { content: fa-content-secondary($fa-var-caret-circle-left); }
.fad.#{$fa-css-prefix}-caret-circle-right:after { content: fa-content-secondary($fa-var-caret-circle-right); }
.fad.#{$fa-css-prefix}-caret-circle-up:after { content: fa-content-secondary($fa-var-caret-circle-up); }
.fad.#{$fa-css-prefix}-caret-down:after { content: fa-content($fa-var-caret-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-caret-left:after { content: fa-content($fa-var-caret-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-caret-right:after { content: fa-content($fa-var-caret-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-caret-square-down:after { content: fa-content-secondary($fa-var-caret-square-down); }
.fad.#{$fa-css-prefix}-caret-square-left:after { content: fa-content-secondary($fa-var-caret-square-left); }
.fad.#{$fa-css-prefix}-caret-square-right:after { content: fa-content-secondary($fa-var-caret-square-right); }
.fad.#{$fa-css-prefix}-caret-square-up:after { content: fa-content-secondary($fa-var-caret-square-up); }
.fad.#{$fa-css-prefix}-caret-up:after { content: fa-content($fa-var-caret-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-carrot:after { content: fa-content-secondary($fa-var-carrot); }
.fad.#{$fa-css-prefix}-cars:after { content: fa-content-secondary($fa-var-cars); }
.fad.#{$fa-css-prefix}-cart-arrow-down:after { content: fa-content-secondary($fa-var-cart-arrow-down); }
.fad.#{$fa-css-prefix}-cart-plus:after { content: fa-content-secondary($fa-var-cart-plus); }
.fad.#{$fa-css-prefix}-cash-register:after { content: fa-content-secondary($fa-var-cash-register); }
.fad.#{$fa-css-prefix}-cat:after { content: fa-content-secondary($fa-var-cat); }
.fad.#{$fa-css-prefix}-cauldron:after { content: fa-content-secondary($fa-var-cauldron); }
.fad.#{$fa-css-prefix}-certificate:after { content: fa-content($fa-var-certificate); visibility: hidden; }
.fad.#{$fa-css-prefix}-chair:after { content: fa-content-secondary($fa-var-chair); }
.fad.#{$fa-css-prefix}-chair-office:after { content: fa-content-secondary($fa-var-chair-office); }
.fad.#{$fa-css-prefix}-chalkboard:after { content: fa-content-secondary($fa-var-chalkboard); }
.fad.#{$fa-css-prefix}-chalkboard-teacher:after { content: fa-content-secondary($fa-var-chalkboard-teacher); }
.fad.#{$fa-css-prefix}-charging-station:after { content: fa-content-secondary($fa-var-charging-station); }
.fad.#{$fa-css-prefix}-chart-area:after { content: fa-content-secondary($fa-var-chart-area); }
.fad.#{$fa-css-prefix}-chart-bar:after { content: fa-content-secondary($fa-var-chart-bar); }
.fad.#{$fa-css-prefix}-chart-line:after { content: fa-content-secondary($fa-var-chart-line); }
.fad.#{$fa-css-prefix}-chart-line-down:after { content: fa-content-secondary($fa-var-chart-line-down); }
.fad.#{$fa-css-prefix}-chart-network:after { content: fa-content-secondary($fa-var-chart-network); }
.fad.#{$fa-css-prefix}-chart-pie:after { content: fa-content-secondary($fa-var-chart-pie); }
.fad.#{$fa-css-prefix}-chart-pie-alt:after { content: fa-content-secondary($fa-var-chart-pie-alt); }
.fad.#{$fa-css-prefix}-chart-scatter:after { content: fa-content-secondary($fa-var-chart-scatter); }
.fad.#{$fa-css-prefix}-check:after { content: fa-content($fa-var-check); visibility: hidden; }
.fad.#{$fa-css-prefix}-check-circle:after { content: fa-content-secondary($fa-var-check-circle); }
.fad.#{$fa-css-prefix}-check-double:after { content: fa-content-secondary($fa-var-check-double); }
.fad.#{$fa-css-prefix}-check-square:after { content: fa-content-secondary($fa-var-check-square); }
.fad.#{$fa-css-prefix}-cheese:after { content: fa-content-secondary($fa-var-cheese); }
.fad.#{$fa-css-prefix}-cheese-swiss:after { content: fa-content-secondary($fa-var-cheese-swiss); }
.fad.#{$fa-css-prefix}-cheeseburger:after { content: fa-content-secondary($fa-var-cheeseburger); }
.fad.#{$fa-css-prefix}-chess:after { content: fa-content-secondary($fa-var-chess); }
.fad.#{$fa-css-prefix}-chess-bishop:after { content: fa-content-secondary($fa-var-chess-bishop); }
.fad.#{$fa-css-prefix}-chess-bishop-alt:after { content: fa-content-secondary($fa-var-chess-bishop-alt); }
.fad.#{$fa-css-prefix}-chess-board:after { content: fa-content-secondary($fa-var-chess-board); }
.fad.#{$fa-css-prefix}-chess-clock:after { content: fa-content-secondary($fa-var-chess-clock); }
.fad.#{$fa-css-prefix}-chess-clock-alt:after { content: fa-content-secondary($fa-var-chess-clock-alt); }
.fad.#{$fa-css-prefix}-chess-king:after { content: fa-content-secondary($fa-var-chess-king); }
.fad.#{$fa-css-prefix}-chess-king-alt:after { content: fa-content-secondary($fa-var-chess-king-alt); }
.fad.#{$fa-css-prefix}-chess-knight:after { content: fa-content-secondary($fa-var-chess-knight); }
.fad.#{$fa-css-prefix}-chess-knight-alt:after { content: fa-content-secondary($fa-var-chess-knight-alt); }
.fad.#{$fa-css-prefix}-chess-pawn:after { content: fa-content-secondary($fa-var-chess-pawn); }
.fad.#{$fa-css-prefix}-chess-pawn-alt:after { content: fa-content-secondary($fa-var-chess-pawn-alt); }
.fad.#{$fa-css-prefix}-chess-queen:after { content: fa-content-secondary($fa-var-chess-queen); }
.fad.#{$fa-css-prefix}-chess-queen-alt:after { content: fa-content-secondary($fa-var-chess-queen-alt); }
.fad.#{$fa-css-prefix}-chess-rook:after { content: fa-content-secondary($fa-var-chess-rook); }
.fad.#{$fa-css-prefix}-chess-rook-alt:after { content: fa-content-secondary($fa-var-chess-rook-alt); }
.fad.#{$fa-css-prefix}-chevron-circle-down:after { content: fa-content-secondary($fa-var-chevron-circle-down); }
.fad.#{$fa-css-prefix}-chevron-circle-left:after { content: fa-content-secondary($fa-var-chevron-circle-left); }
.fad.#{$fa-css-prefix}-chevron-circle-right:after { content: fa-content-secondary($fa-var-chevron-circle-right); }
.fad.#{$fa-css-prefix}-chevron-circle-up:after { content: fa-content-secondary($fa-var-chevron-circle-up); }
.fad.#{$fa-css-prefix}-chevron-double-down:after { content: fa-content-secondary($fa-var-chevron-double-down); }
.fad.#{$fa-css-prefix}-chevron-double-left:after { content: fa-content-secondary($fa-var-chevron-double-left); }
.fad.#{$fa-css-prefix}-chevron-double-right:after { content: fa-content-secondary($fa-var-chevron-double-right); }
.fad.#{$fa-css-prefix}-chevron-double-up:after { content: fa-content-secondary($fa-var-chevron-double-up); }
.fad.#{$fa-css-prefix}-chevron-down:after { content: fa-content($fa-var-chevron-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-chevron-left:after { content: fa-content($fa-var-chevron-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-chevron-right:after { content: fa-content($fa-var-chevron-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-chevron-square-down:after { content: fa-content-secondary($fa-var-chevron-square-down); }
.fad.#{$fa-css-prefix}-chevron-square-left:after { content: fa-content-secondary($fa-var-chevron-square-left); }
.fad.#{$fa-css-prefix}-chevron-square-right:after { content: fa-content-secondary($fa-var-chevron-square-right); }
.fad.#{$fa-css-prefix}-chevron-square-up:after { content: fa-content-secondary($fa-var-chevron-square-up); }
.fad.#{$fa-css-prefix}-chevron-up:after { content: fa-content($fa-var-chevron-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-child:after { content: fa-content-secondary($fa-var-child); }
.fad.#{$fa-css-prefix}-chimney:after { content: fa-content-secondary($fa-var-chimney); }
.fad.#{$fa-css-prefix}-church:after { content: fa-content-secondary($fa-var-church); }
.fad.#{$fa-css-prefix}-circle:after { content: fa-content($fa-var-circle); visibility: hidden; }
.fad.#{$fa-css-prefix}-circle-notch:after { content: fa-content($fa-var-circle-notch); visibility: hidden; }
.fad.#{$fa-css-prefix}-city:after { content: fa-content-secondary($fa-var-city); }
.fad.#{$fa-css-prefix}-claw-marks:after { content: fa-content-secondary($fa-var-claw-marks); }
.fad.#{$fa-css-prefix}-clinic-medical:after { content: fa-content-secondary($fa-var-clinic-medical); }
.fad.#{$fa-css-prefix}-clipboard:after { content: fa-content-secondary($fa-var-clipboard); }
.fad.#{$fa-css-prefix}-clipboard-check:after { content: fa-content-secondary($fa-var-clipboard-check); }
.fad.#{$fa-css-prefix}-clipboard-list:after { content: fa-content-secondary($fa-var-clipboard-list); }
.fad.#{$fa-css-prefix}-clipboard-list-check:after { content: fa-content-secondary($fa-var-clipboard-list-check); }
.fad.#{$fa-css-prefix}-clipboard-prescription:after { content: fa-content-secondary($fa-var-clipboard-prescription); }
.fad.#{$fa-css-prefix}-clipboard-user:after { content: fa-content-secondary($fa-var-clipboard-user); }
.fad.#{$fa-css-prefix}-clock:after { content: fa-content-secondary($fa-var-clock); }
.fad.#{$fa-css-prefix}-clone:after { content: fa-content-secondary($fa-var-clone); }
.fad.#{$fa-css-prefix}-closed-captioning:after { content: fa-content-secondary($fa-var-closed-captioning); }
.fad.#{$fa-css-prefix}-cloud:after { content: fa-content($fa-var-cloud); visibility: hidden; }
.fad.#{$fa-css-prefix}-cloud-download:after { content: fa-content-secondary($fa-var-cloud-download); }
.fad.#{$fa-css-prefix}-cloud-download-alt:after { content: fa-content-secondary($fa-var-cloud-download-alt); }
.fad.#{$fa-css-prefix}-cloud-drizzle:after { content: fa-content-secondary($fa-var-cloud-drizzle); }
.fad.#{$fa-css-prefix}-cloud-hail:after { content: fa-content-secondary($fa-var-cloud-hail); }
.fad.#{$fa-css-prefix}-cloud-hail-mixed:after { content: fa-content-secondary($fa-var-cloud-hail-mixed); }
.fad.#{$fa-css-prefix}-cloud-meatball:after { content: fa-content-secondary($fa-var-cloud-meatball); }
.fad.#{$fa-css-prefix}-cloud-moon:after { content: fa-content-secondary($fa-var-cloud-moon); }
.fad.#{$fa-css-prefix}-cloud-moon-rain:after { content: fa-content-secondary($fa-var-cloud-moon-rain); }
.fad.#{$fa-css-prefix}-cloud-rain:after { content: fa-content-secondary($fa-var-cloud-rain); }
.fad.#{$fa-css-prefix}-cloud-rainbow:after { content: fa-content-secondary($fa-var-cloud-rainbow); }
.fad.#{$fa-css-prefix}-cloud-showers:after { content: fa-content-secondary($fa-var-cloud-showers); }
.fad.#{$fa-css-prefix}-cloud-showers-heavy:after { content: fa-content-secondary($fa-var-cloud-showers-heavy); }
.fad.#{$fa-css-prefix}-cloud-sleet:after { content: fa-content-secondary($fa-var-cloud-sleet); }
.fad.#{$fa-css-prefix}-cloud-snow:after { content: fa-content-secondary($fa-var-cloud-snow); }
.fad.#{$fa-css-prefix}-cloud-sun:after { content: fa-content-secondary($fa-var-cloud-sun); }
.fad.#{$fa-css-prefix}-cloud-sun-rain:after { content: fa-content-secondary($fa-var-cloud-sun-rain); }
.fad.#{$fa-css-prefix}-cloud-upload:after { content: fa-content-secondary($fa-var-cloud-upload); }
.fad.#{$fa-css-prefix}-cloud-upload-alt:after { content: fa-content-secondary($fa-var-cloud-upload-alt); }
.fad.#{$fa-css-prefix}-clouds:after { content: fa-content-secondary($fa-var-clouds); }
.fad.#{$fa-css-prefix}-clouds-moon:after { content: fa-content-secondary($fa-var-clouds-moon); }
.fad.#{$fa-css-prefix}-clouds-sun:after { content: fa-content-secondary($fa-var-clouds-sun); }
.fad.#{$fa-css-prefix}-club:after { content: fa-content($fa-var-club); visibility: hidden; }
.fad.#{$fa-css-prefix}-cocktail:after { content: fa-content-secondary($fa-var-cocktail); }
.fad.#{$fa-css-prefix}-code:after { content: fa-content-secondary($fa-var-code); }
.fad.#{$fa-css-prefix}-code-branch:after { content: fa-content-secondary($fa-var-code-branch); }
.fad.#{$fa-css-prefix}-code-commit:after { content: fa-content-secondary($fa-var-code-commit); }
.fad.#{$fa-css-prefix}-code-merge:after { content: fa-content-secondary($fa-var-code-merge); }
.fad.#{$fa-css-prefix}-coffee:after { content: fa-content-secondary($fa-var-coffee); }
.fad.#{$fa-css-prefix}-coffee-togo:after { content: fa-content-secondary($fa-var-coffee-togo); }
.fad.#{$fa-css-prefix}-coffin:after { content: fa-content-secondary($fa-var-coffin); }
.fad.#{$fa-css-prefix}-cog:after { content: fa-content($fa-var-cog); visibility: hidden; }
.fad.#{$fa-css-prefix}-cogs:after { content: fa-content-secondary($fa-var-cogs); }
.fad.#{$fa-css-prefix}-coin:after { content: fa-content-secondary($fa-var-coin); }
.fad.#{$fa-css-prefix}-coins:after { content: fa-content-secondary($fa-var-coins); }
.fad.#{$fa-css-prefix}-columns:after { content: fa-content-secondary($fa-var-columns); }
.fad.#{$fa-css-prefix}-comment:after { content: fa-content($fa-var-comment); visibility: hidden; }
.fad.#{$fa-css-prefix}-comment-alt:after { content: fa-content($fa-var-comment-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-comment-alt-check:after { content: fa-content-secondary($fa-var-comment-alt-check); }
.fad.#{$fa-css-prefix}-comment-alt-dollar:after { content: fa-content-secondary($fa-var-comment-alt-dollar); }
.fad.#{$fa-css-prefix}-comment-alt-dots:after { content: fa-content-secondary($fa-var-comment-alt-dots); }
.fad.#{$fa-css-prefix}-comment-alt-edit:after { content: fa-content-secondary($fa-var-comment-alt-edit); }
.fad.#{$fa-css-prefix}-comment-alt-exclamation:after { content: fa-content-secondary($fa-var-comment-alt-exclamation); }
.fad.#{$fa-css-prefix}-comment-alt-lines:after { content: fa-content-secondary($fa-var-comment-alt-lines); }
.fad.#{$fa-css-prefix}-comment-alt-medical:after { content: fa-content-secondary($fa-var-comment-alt-medical); }
.fad.#{$fa-css-prefix}-comment-alt-minus:after { content: fa-content-secondary($fa-var-comment-alt-minus); }
.fad.#{$fa-css-prefix}-comment-alt-plus:after { content: fa-content-secondary($fa-var-comment-alt-plus); }
.fad.#{$fa-css-prefix}-comment-alt-slash:after { content: fa-content-secondary($fa-var-comment-alt-slash); }
.fad.#{$fa-css-prefix}-comment-alt-smile:after { content: fa-content-secondary($fa-var-comment-alt-smile); }
.fad.#{$fa-css-prefix}-comment-alt-times:after { content: fa-content-secondary($fa-var-comment-alt-times); }
.fad.#{$fa-css-prefix}-comment-check:after { content: fa-content-secondary($fa-var-comment-check); }
.fad.#{$fa-css-prefix}-comment-dollar:after { content: fa-content-secondary($fa-var-comment-dollar); }
.fad.#{$fa-css-prefix}-comment-dots:after { content: fa-content-secondary($fa-var-comment-dots); }
.fad.#{$fa-css-prefix}-comment-edit:after { content: fa-content-secondary($fa-var-comment-edit); }
.fad.#{$fa-css-prefix}-comment-exclamation:after { content: fa-content-secondary($fa-var-comment-exclamation); }
.fad.#{$fa-css-prefix}-comment-lines:after { content: fa-content-secondary($fa-var-comment-lines); }
.fad.#{$fa-css-prefix}-comment-medical:after { content: fa-content-secondary($fa-var-comment-medical); }
.fad.#{$fa-css-prefix}-comment-minus:after { content: fa-content-secondary($fa-var-comment-minus); }
.fad.#{$fa-css-prefix}-comment-plus:after { content: fa-content-secondary($fa-var-comment-plus); }
.fad.#{$fa-css-prefix}-comment-slash:after { content: fa-content-secondary($fa-var-comment-slash); }
.fad.#{$fa-css-prefix}-comment-smile:after { content: fa-content-secondary($fa-var-comment-smile); }
.fad.#{$fa-css-prefix}-comment-times:after { content: fa-content-secondary($fa-var-comment-times); }
.fad.#{$fa-css-prefix}-comments:after { content: fa-content-secondary($fa-var-comments); }
.fad.#{$fa-css-prefix}-comments-alt:after { content: fa-content-secondary($fa-var-comments-alt); }
.fad.#{$fa-css-prefix}-comments-alt-dollar:after { content: fa-content-secondary($fa-var-comments-alt-dollar); }
.fad.#{$fa-css-prefix}-comments-dollar:after { content: fa-content-secondary($fa-var-comments-dollar); }
.fad.#{$fa-css-prefix}-compact-disc:after { content: fa-content-secondary($fa-var-compact-disc); }
.fad.#{$fa-css-prefix}-compass:after { content: fa-content-secondary($fa-var-compass); }
.fad.#{$fa-css-prefix}-compass-slash:after { content: fa-content-secondary($fa-var-compass-slash); }
.fad.#{$fa-css-prefix}-compress:after { content: fa-content-secondary($fa-var-compress); }
.fad.#{$fa-css-prefix}-compress-alt:after { content: fa-content-secondary($fa-var-compress-alt); }
.fad.#{$fa-css-prefix}-compress-arrows-alt:after { content: fa-content-secondary($fa-var-compress-arrows-alt); }
.fad.#{$fa-css-prefix}-compress-wide:after { content: fa-content-secondary($fa-var-compress-wide); }
.fad.#{$fa-css-prefix}-concierge-bell:after { content: fa-content-secondary($fa-var-concierge-bell); }
.fad.#{$fa-css-prefix}-construction:after { content: fa-content-secondary($fa-var-construction); }
.fad.#{$fa-css-prefix}-container-storage:after { content: fa-content-secondary($fa-var-container-storage); }
.fad.#{$fa-css-prefix}-conveyor-belt:after { content: fa-content-secondary($fa-var-conveyor-belt); }
.fad.#{$fa-css-prefix}-conveyor-belt-alt:after { content: fa-content-secondary($fa-var-conveyor-belt-alt); }
.fad.#{$fa-css-prefix}-cookie:after { content: fa-content-secondary($fa-var-cookie); }
.fad.#{$fa-css-prefix}-cookie-bite:after { content: fa-content-secondary($fa-var-cookie-bite); }
.fad.#{$fa-css-prefix}-copy:after { content: fa-content-secondary($fa-var-copy); }
.fad.#{$fa-css-prefix}-copyright:after { content: fa-content-secondary($fa-var-copyright); }
.fad.#{$fa-css-prefix}-corn:after { content: fa-content-secondary($fa-var-corn); }
.fad.#{$fa-css-prefix}-couch:after { content: fa-content-secondary($fa-var-couch); }
.fad.#{$fa-css-prefix}-cow:after { content: fa-content-secondary($fa-var-cow); }
.fad.#{$fa-css-prefix}-credit-card:after { content: fa-content-secondary($fa-var-credit-card); }
.fad.#{$fa-css-prefix}-credit-card-blank:after { content: fa-content-secondary($fa-var-credit-card-blank); }
.fad.#{$fa-css-prefix}-credit-card-front:after { content: fa-content-secondary($fa-var-credit-card-front); }
.fad.#{$fa-css-prefix}-cricket:after { content: fa-content-secondary($fa-var-cricket); }
.fad.#{$fa-css-prefix}-croissant:after { content: fa-content-secondary($fa-var-croissant); }
.fad.#{$fa-css-prefix}-crop:after { content: fa-content-secondary($fa-var-crop); }
.fad.#{$fa-css-prefix}-crop-alt:after { content: fa-content-secondary($fa-var-crop-alt); }
.fad.#{$fa-css-prefix}-cross:after { content: fa-content($fa-var-cross); visibility: hidden; }
.fad.#{$fa-css-prefix}-crosshairs:after { content: fa-content-secondary($fa-var-crosshairs); }
.fad.#{$fa-css-prefix}-crow:after { content: fa-content-secondary($fa-var-crow); }
.fad.#{$fa-css-prefix}-crown:after { content: fa-content-secondary($fa-var-crown); }
.fad.#{$fa-css-prefix}-crutch:after { content: fa-content-secondary($fa-var-crutch); }
.fad.#{$fa-css-prefix}-crutches:after { content: fa-content-secondary($fa-var-crutches); }
.fad.#{$fa-css-prefix}-cube:after { content: fa-content-secondary($fa-var-cube); }
.fad.#{$fa-css-prefix}-cubes:after { content: fa-content-secondary($fa-var-cubes); }
.fad.#{$fa-css-prefix}-curling:after { content: fa-content-secondary($fa-var-curling); }
.fad.#{$fa-css-prefix}-cut:after { content: fa-content-secondary($fa-var-cut); }
.fad.#{$fa-css-prefix}-dagger:after { content: fa-content-secondary($fa-var-dagger); }
.fad.#{$fa-css-prefix}-database:after { content: fa-content-secondary($fa-var-database); }
.fad.#{$fa-css-prefix}-deaf:after { content: fa-content-secondary($fa-var-deaf); }
.fad.#{$fa-css-prefix}-debug:after { content: fa-content-secondary($fa-var-debug); }
.fad.#{$fa-css-prefix}-deer:after { content: fa-content-secondary($fa-var-deer); }
.fad.#{$fa-css-prefix}-deer-rudolph:after { content: fa-content-secondary($fa-var-deer-rudolph); }
.fad.#{$fa-css-prefix}-democrat:after { content: fa-content-secondary($fa-var-democrat); }
.fad.#{$fa-css-prefix}-desktop:after { content: fa-content-secondary($fa-var-desktop); }
.fad.#{$fa-css-prefix}-desktop-alt:after { content: fa-content-secondary($fa-var-desktop-alt); }
.fad.#{$fa-css-prefix}-dewpoint:after { content: fa-content-secondary($fa-var-dewpoint); }
.fad.#{$fa-css-prefix}-dharmachakra:after { content: fa-content-secondary($fa-var-dharmachakra); }
.fad.#{$fa-css-prefix}-diagnoses:after { content: fa-content-secondary($fa-var-diagnoses); }
.fad.#{$fa-css-prefix}-diamond:after { content: fa-content($fa-var-diamond); visibility: hidden; }
.fad.#{$fa-css-prefix}-dice:after { content: fa-content-secondary($fa-var-dice); }
.fad.#{$fa-css-prefix}-dice-d10:after { content: fa-content-secondary($fa-var-dice-d10); }
.fad.#{$fa-css-prefix}-dice-d12:after { content: fa-content-secondary($fa-var-dice-d12); }
.fad.#{$fa-css-prefix}-dice-d20:after { content: fa-content-secondary($fa-var-dice-d20); }
.fad.#{$fa-css-prefix}-dice-d4:after { content: fa-content-secondary($fa-var-dice-d4); }
.fad.#{$fa-css-prefix}-dice-d6:after { content: fa-content-secondary($fa-var-dice-d6); }
.fad.#{$fa-css-prefix}-dice-d8:after { content: fa-content-secondary($fa-var-dice-d8); }
.fad.#{$fa-css-prefix}-dice-five:after { content: fa-content-secondary($fa-var-dice-five); }
.fad.#{$fa-css-prefix}-dice-four:after { content: fa-content-secondary($fa-var-dice-four); }
.fad.#{$fa-css-prefix}-dice-one:after { content: fa-content-secondary($fa-var-dice-one); }
.fad.#{$fa-css-prefix}-dice-six:after { content: fa-content-secondary($fa-var-dice-six); }
.fad.#{$fa-css-prefix}-dice-three:after { content: fa-content-secondary($fa-var-dice-three); }
.fad.#{$fa-css-prefix}-dice-two:after { content: fa-content-secondary($fa-var-dice-two); }
.fad.#{$fa-css-prefix}-digging:after { content: fa-content-secondary($fa-var-digging); }
.fad.#{$fa-css-prefix}-digital-tachograph:after { content: fa-content-secondary($fa-var-digital-tachograph); }
.fad.#{$fa-css-prefix}-diploma:after { content: fa-content-secondary($fa-var-diploma); }
.fad.#{$fa-css-prefix}-directions:after { content: fa-content-secondary($fa-var-directions); }
.fad.#{$fa-css-prefix}-disease:after { content: fa-content-secondary($fa-var-disease); }
.fad.#{$fa-css-prefix}-divide:after { content: fa-content-secondary($fa-var-divide); }
.fad.#{$fa-css-prefix}-dizzy:after { content: fa-content-secondary($fa-var-dizzy); }
.fad.#{$fa-css-prefix}-dna:after { content: fa-content-secondary($fa-var-dna); }
.fad.#{$fa-css-prefix}-do-not-enter:after { content: fa-content-secondary($fa-var-do-not-enter); }
.fad.#{$fa-css-prefix}-dog:after { content: fa-content-secondary($fa-var-dog); }
.fad.#{$fa-css-prefix}-dog-leashed:after { content: fa-content-secondary($fa-var-dog-leashed); }
.fad.#{$fa-css-prefix}-dollar-sign:after { content: fa-content($fa-var-dollar-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-dolly:after { content: fa-content-secondary($fa-var-dolly); }
.fad.#{$fa-css-prefix}-dolly-empty:after { content: fa-content-secondary($fa-var-dolly-empty); }
.fad.#{$fa-css-prefix}-dolly-flatbed:after { content: fa-content-secondary($fa-var-dolly-flatbed); }
.fad.#{$fa-css-prefix}-dolly-flatbed-alt:after { content: fa-content-secondary($fa-var-dolly-flatbed-alt); }
.fad.#{$fa-css-prefix}-dolly-flatbed-empty:after { content: fa-content($fa-var-dolly-flatbed-empty); visibility: hidden; }
.fad.#{$fa-css-prefix}-donate:after { content: fa-content-secondary($fa-var-donate); }
.fad.#{$fa-css-prefix}-door-closed:after { content: fa-content-secondary($fa-var-door-closed); }
.fad.#{$fa-css-prefix}-door-open:after { content: fa-content-secondary($fa-var-door-open); }
.fad.#{$fa-css-prefix}-dot-circle:after { content: fa-content-secondary($fa-var-dot-circle); }
.fad.#{$fa-css-prefix}-dove:after { content: fa-content-secondary($fa-var-dove); }
.fad.#{$fa-css-prefix}-download:after { content: fa-content-secondary($fa-var-download); }
.fad.#{$fa-css-prefix}-drafting-compass:after { content: fa-content-secondary($fa-var-drafting-compass); }
.fad.#{$fa-css-prefix}-dragon:after { content: fa-content-secondary($fa-var-dragon); }
.fad.#{$fa-css-prefix}-draw-circle:after { content: fa-content-secondary($fa-var-draw-circle); }
.fad.#{$fa-css-prefix}-draw-polygon:after { content: fa-content-secondary($fa-var-draw-polygon); }
.fad.#{$fa-css-prefix}-draw-square:after { content: fa-content-secondary($fa-var-draw-square); }
.fad.#{$fa-css-prefix}-dreidel:after { content: fa-content-secondary($fa-var-dreidel); }
.fad.#{$fa-css-prefix}-drone:after { content: fa-content-secondary($fa-var-drone); }
.fad.#{$fa-css-prefix}-drone-alt:after { content: fa-content-secondary($fa-var-drone-alt); }
.fad.#{$fa-css-prefix}-drum:after { content: fa-content-secondary($fa-var-drum); }
.fad.#{$fa-css-prefix}-drum-steelpan:after { content: fa-content-secondary($fa-var-drum-steelpan); }
.fad.#{$fa-css-prefix}-drumstick:after { content: fa-content-secondary($fa-var-drumstick); }
.fad.#{$fa-css-prefix}-drumstick-bite:after { content: fa-content-secondary($fa-var-drumstick-bite); }
.fad.#{$fa-css-prefix}-dryer:after { content: fa-content-secondary($fa-var-dryer); }
.fad.#{$fa-css-prefix}-dryer-alt:after { content: fa-content-secondary($fa-var-dryer-alt); }
.fad.#{$fa-css-prefix}-duck:after { content: fa-content-secondary($fa-var-duck); }
.fad.#{$fa-css-prefix}-dumbbell:after { content: fa-content-secondary($fa-var-dumbbell); }
.fad.#{$fa-css-prefix}-dumpster:after { content: fa-content-secondary($fa-var-dumpster); }
.fad.#{$fa-css-prefix}-dumpster-fire:after { content: fa-content-secondary($fa-var-dumpster-fire); }
.fad.#{$fa-css-prefix}-dungeon:after { content: fa-content-secondary($fa-var-dungeon); }
.fad.#{$fa-css-prefix}-ear:after { content: fa-content-secondary($fa-var-ear); }
.fad.#{$fa-css-prefix}-ear-muffs:after { content: fa-content-secondary($fa-var-ear-muffs); }
.fad.#{$fa-css-prefix}-eclipse:after { content: fa-content-secondary($fa-var-eclipse); }
.fad.#{$fa-css-prefix}-eclipse-alt:after { content: fa-content-secondary($fa-var-eclipse-alt); }
.fad.#{$fa-css-prefix}-edit:after { content: fa-content-secondary($fa-var-edit); }
.fad.#{$fa-css-prefix}-egg:after { content: fa-content($fa-var-egg); visibility: hidden; }
.fad.#{$fa-css-prefix}-egg-fried:after { content: fa-content-secondary($fa-var-egg-fried); }
.fad.#{$fa-css-prefix}-eject:after { content: fa-content-secondary($fa-var-eject); }
.fad.#{$fa-css-prefix}-elephant:after { content: fa-content-secondary($fa-var-elephant); }
.fad.#{$fa-css-prefix}-ellipsis-h:after { content: fa-content($fa-var-ellipsis-h); visibility: hidden; }
.fad.#{$fa-css-prefix}-ellipsis-h-alt:after { content: fa-content-secondary($fa-var-ellipsis-h-alt); }
.fad.#{$fa-css-prefix}-ellipsis-v:after { content: fa-content($fa-var-ellipsis-v); visibility: hidden; }
.fad.#{$fa-css-prefix}-ellipsis-v-alt:after { content: fa-content-secondary($fa-var-ellipsis-v-alt); }
.fad.#{$fa-css-prefix}-empty-set:after { content: fa-content-secondary($fa-var-empty-set); }
.fad.#{$fa-css-prefix}-engine-warning:after { content: fa-content-secondary($fa-var-engine-warning); }
.fad.#{$fa-css-prefix}-envelope:after { content: fa-content-secondary($fa-var-envelope); }
.fad.#{$fa-css-prefix}-envelope-open:after { content: fa-content-secondary($fa-var-envelope-open); }
.fad.#{$fa-css-prefix}-envelope-open-dollar:after { content: fa-content-secondary($fa-var-envelope-open-dollar); }
.fad.#{$fa-css-prefix}-envelope-open-text:after { content: fa-content-secondary($fa-var-envelope-open-text); }
.fad.#{$fa-css-prefix}-envelope-square:after { content: fa-content-secondary($fa-var-envelope-square); }
.fad.#{$fa-css-prefix}-equals:after { content: fa-content($fa-var-equals); visibility: hidden; }
.fad.#{$fa-css-prefix}-eraser:after { content: fa-content-secondary($fa-var-eraser); }
.fad.#{$fa-css-prefix}-ethernet:after { content: fa-content-secondary($fa-var-ethernet); }
.fad.#{$fa-css-prefix}-euro-sign:after { content: fa-content($fa-var-euro-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-exchange:after { content: fa-content-secondary($fa-var-exchange); }
.fad.#{$fa-css-prefix}-exchange-alt:after { content: fa-content-secondary($fa-var-exchange-alt); }
.fad.#{$fa-css-prefix}-exclamation:after { content: fa-content-secondary($fa-var-exclamation); }
.fad.#{$fa-css-prefix}-exclamation-circle:after { content: fa-content-secondary($fa-var-exclamation-circle); }
.fad.#{$fa-css-prefix}-exclamation-square:after { content: fa-content-secondary($fa-var-exclamation-square); }
.fad.#{$fa-css-prefix}-exclamation-triangle:after { content: fa-content-secondary($fa-var-exclamation-triangle); }
.fad.#{$fa-css-prefix}-expand:after { content: fa-content-secondary($fa-var-expand); }
.fad.#{$fa-css-prefix}-expand-alt:after { content: fa-content-secondary($fa-var-expand-alt); }
.fad.#{$fa-css-prefix}-expand-arrows:after { content: fa-content-secondary($fa-var-expand-arrows); }
.fad.#{$fa-css-prefix}-expand-arrows-alt:after { content: fa-content-secondary($fa-var-expand-arrows-alt); }
.fad.#{$fa-css-prefix}-expand-wide:after { content: fa-content-secondary($fa-var-expand-wide); }
.fad.#{$fa-css-prefix}-external-link:after { content: fa-content-secondary($fa-var-external-link); }
.fad.#{$fa-css-prefix}-external-link-alt:after { content: fa-content-secondary($fa-var-external-link-alt); }
.fad.#{$fa-css-prefix}-external-link-square:after { content: fa-content-secondary($fa-var-external-link-square); }
.fad.#{$fa-css-prefix}-external-link-square-alt:after { content: fa-content-secondary($fa-var-external-link-square-alt); }
.fad.#{$fa-css-prefix}-eye:after { content: fa-content-secondary($fa-var-eye); }
.fad.#{$fa-css-prefix}-eye-dropper:after { content: fa-content-secondary($fa-var-eye-dropper); }
.fad.#{$fa-css-prefix}-eye-evil:after { content: fa-content-secondary($fa-var-eye-evil); }
.fad.#{$fa-css-prefix}-eye-slash:after { content: fa-content-secondary($fa-var-eye-slash); }
.fad.#{$fa-css-prefix}-fan:after { content: fa-content-secondary($fa-var-fan); }
.fad.#{$fa-css-prefix}-farm:after { content: fa-content-secondary($fa-var-farm); }
.fad.#{$fa-css-prefix}-fast-backward:after { content: fa-content-secondary($fa-var-fast-backward); }
.fad.#{$fa-css-prefix}-fast-forward:after { content: fa-content-secondary($fa-var-fast-forward); }
.fad.#{$fa-css-prefix}-fax:after { content: fa-content-secondary($fa-var-fax); }
.fad.#{$fa-css-prefix}-feather:after { content: fa-content-secondary($fa-var-feather); }
.fad.#{$fa-css-prefix}-feather-alt:after { content: fa-content-secondary($fa-var-feather-alt); }
.fad.#{$fa-css-prefix}-female:after { content: fa-content-secondary($fa-var-female); }
.fad.#{$fa-css-prefix}-field-hockey:after { content: fa-content-secondary($fa-var-field-hockey); }
.fad.#{$fa-css-prefix}-fighter-jet:after { content: fa-content-secondary($fa-var-fighter-jet); }
.fad.#{$fa-css-prefix}-file:after { content: fa-content-secondary($fa-var-file); }
.fad.#{$fa-css-prefix}-file-alt:after { content: fa-content-secondary($fa-var-file-alt); }
.fad.#{$fa-css-prefix}-file-archive:after { content: fa-content-secondary($fa-var-file-archive); }
.fad.#{$fa-css-prefix}-file-audio:after { content: fa-content-secondary($fa-var-file-audio); }
.fad.#{$fa-css-prefix}-file-certificate:after { content: fa-content-secondary($fa-var-file-certificate); }
.fad.#{$fa-css-prefix}-file-chart-line:after { content: fa-content-secondary($fa-var-file-chart-line); }
.fad.#{$fa-css-prefix}-file-chart-pie:after { content: fa-content-secondary($fa-var-file-chart-pie); }
.fad.#{$fa-css-prefix}-file-check:after { content: fa-content-secondary($fa-var-file-check); }
.fad.#{$fa-css-prefix}-file-code:after { content: fa-content-secondary($fa-var-file-code); }
.fad.#{$fa-css-prefix}-file-contract:after { content: fa-content-secondary($fa-var-file-contract); }
.fad.#{$fa-css-prefix}-file-csv:after { content: fa-content-secondary($fa-var-file-csv); }
.fad.#{$fa-css-prefix}-file-download:after { content: fa-content-secondary($fa-var-file-download); }
.fad.#{$fa-css-prefix}-file-edit:after { content: fa-content-secondary($fa-var-file-edit); }
.fad.#{$fa-css-prefix}-file-excel:after { content: fa-content-secondary($fa-var-file-excel); }
.fad.#{$fa-css-prefix}-file-exclamation:after { content: fa-content-secondary($fa-var-file-exclamation); }
.fad.#{$fa-css-prefix}-file-export:after { content: fa-content-secondary($fa-var-file-export); }
.fad.#{$fa-css-prefix}-file-image:after { content: fa-content-secondary($fa-var-file-image); }
.fad.#{$fa-css-prefix}-file-import:after { content: fa-content-secondary($fa-var-file-import); }
.fad.#{$fa-css-prefix}-file-invoice:after { content: fa-content-secondary($fa-var-file-invoice); }
.fad.#{$fa-css-prefix}-file-invoice-dollar:after { content: fa-content-secondary($fa-var-file-invoice-dollar); }
.fad.#{$fa-css-prefix}-file-medical:after { content: fa-content-secondary($fa-var-file-medical); }
.fad.#{$fa-css-prefix}-file-medical-alt:after { content: fa-content-secondary($fa-var-file-medical-alt); }
.fad.#{$fa-css-prefix}-file-minus:after { content: fa-content-secondary($fa-var-file-minus); }
.fad.#{$fa-css-prefix}-file-pdf:after { content: fa-content-secondary($fa-var-file-pdf); }
.fad.#{$fa-css-prefix}-file-plus:after { content: fa-content-secondary($fa-var-file-plus); }
.fad.#{$fa-css-prefix}-file-powerpoint:after { content: fa-content-secondary($fa-var-file-powerpoint); }
.fad.#{$fa-css-prefix}-file-prescription:after { content: fa-content-secondary($fa-var-file-prescription); }
.fad.#{$fa-css-prefix}-file-search:after { content: fa-content-secondary($fa-var-file-search); }
.fad.#{$fa-css-prefix}-file-signature:after { content: fa-content-secondary($fa-var-file-signature); }
.fad.#{$fa-css-prefix}-file-spreadsheet:after { content: fa-content-secondary($fa-var-file-spreadsheet); }
.fad.#{$fa-css-prefix}-file-times:after { content: fa-content-secondary($fa-var-file-times); }
.fad.#{$fa-css-prefix}-file-upload:after { content: fa-content-secondary($fa-var-file-upload); }
.fad.#{$fa-css-prefix}-file-user:after { content: fa-content-secondary($fa-var-file-user); }
.fad.#{$fa-css-prefix}-file-video:after { content: fa-content-secondary($fa-var-file-video); }
.fad.#{$fa-css-prefix}-file-word:after { content: fa-content-secondary($fa-var-file-word); }
.fad.#{$fa-css-prefix}-files-medical:after { content: fa-content-secondary($fa-var-files-medical); }
.fad.#{$fa-css-prefix}-fill:after { content: fa-content-secondary($fa-var-fill); }
.fad.#{$fa-css-prefix}-fill-drip:after { content: fa-content-secondary($fa-var-fill-drip); }
.fad.#{$fa-css-prefix}-film:after { content: fa-content-secondary($fa-var-film); }
.fad.#{$fa-css-prefix}-film-alt:after { content: fa-content($fa-var-film-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-filter:after { content: fa-content($fa-var-filter); visibility: hidden; }
.fad.#{$fa-css-prefix}-fingerprint:after { content: fa-content-secondary($fa-var-fingerprint); }
.fad.#{$fa-css-prefix}-fire:after { content: fa-content($fa-var-fire); visibility: hidden; }
.fad.#{$fa-css-prefix}-fire-alt:after { content: fa-content-secondary($fa-var-fire-alt); }
.fad.#{$fa-css-prefix}-fire-extinguisher:after { content: fa-content-secondary($fa-var-fire-extinguisher); }
.fad.#{$fa-css-prefix}-fire-smoke:after { content: fa-content-secondary($fa-var-fire-smoke); }
.fad.#{$fa-css-prefix}-fireplace:after { content: fa-content-secondary($fa-var-fireplace); }
.fad.#{$fa-css-prefix}-first-aid:after { content: fa-content-secondary($fa-var-first-aid); }
.fad.#{$fa-css-prefix}-fish:after { content: fa-content-secondary($fa-var-fish); }
.fad.#{$fa-css-prefix}-fish-cooked:after { content: fa-content-secondary($fa-var-fish-cooked); }
.fad.#{$fa-css-prefix}-fist-raised:after { content: fa-content-secondary($fa-var-fist-raised); }
.fad.#{$fa-css-prefix}-flag:after { content: fa-content-secondary($fa-var-flag); }
.fad.#{$fa-css-prefix}-flag-alt:after { content: fa-content-secondary($fa-var-flag-alt); }
.fad.#{$fa-css-prefix}-flag-checkered:after { content: fa-content-secondary($fa-var-flag-checkered); }
.fad.#{$fa-css-prefix}-flag-usa:after { content: fa-content-secondary($fa-var-flag-usa); }
.fad.#{$fa-css-prefix}-flame:after { content: fa-content-secondary($fa-var-flame); }
.fad.#{$fa-css-prefix}-flask:after { content: fa-content-secondary($fa-var-flask); }
.fad.#{$fa-css-prefix}-flask-poison:after { content: fa-content-secondary($fa-var-flask-poison); }
.fad.#{$fa-css-prefix}-flask-potion:after { content: fa-content-secondary($fa-var-flask-potion); }
.fad.#{$fa-css-prefix}-flower:after { content: fa-content-secondary($fa-var-flower); }
.fad.#{$fa-css-prefix}-flower-daffodil:after { content: fa-content-secondary($fa-var-flower-daffodil); }
.fad.#{$fa-css-prefix}-flower-tulip:after { content: fa-content-secondary($fa-var-flower-tulip); }
.fad.#{$fa-css-prefix}-flushed:after { content: fa-content-secondary($fa-var-flushed); }
.fad.#{$fa-css-prefix}-fog:after { content: fa-content-secondary($fa-var-fog); }
.fad.#{$fa-css-prefix}-folder:after { content: fa-content($fa-var-folder); visibility: hidden; }
.fad.#{$fa-css-prefix}-folder-minus:after { content: fa-content-secondary($fa-var-folder-minus); }
.fad.#{$fa-css-prefix}-folder-open:after { content: fa-content-secondary($fa-var-folder-open); }
.fad.#{$fa-css-prefix}-folder-plus:after { content: fa-content-secondary($fa-var-folder-plus); }
.fad.#{$fa-css-prefix}-folder-times:after { content: fa-content-secondary($fa-var-folder-times); }
.fad.#{$fa-css-prefix}-folder-tree:after { content: fa-content-secondary($fa-var-folder-tree); }
.fad.#{$fa-css-prefix}-folders:after { content: fa-content-secondary($fa-var-folders); }
.fad.#{$fa-css-prefix}-font:after { content: fa-content($fa-var-font); visibility: hidden; }
.fad.#{$fa-css-prefix}-font-awesome-logo-full:after { content: fa-content($fa-var-font-awesome-logo-full); visibility: hidden; }
.fad.#{$fa-css-prefix}-font-case:after { content: fa-content-secondary($fa-var-font-case); }
.fad.#{$fa-css-prefix}-football-ball:after { content: fa-content-secondary($fa-var-football-ball); }
.fad.#{$fa-css-prefix}-football-helmet:after { content: fa-content-secondary($fa-var-football-helmet); }
.fad.#{$fa-css-prefix}-forklift:after { content: fa-content-secondary($fa-var-forklift); }
.fad.#{$fa-css-prefix}-forward:after { content: fa-content-secondary($fa-var-forward); }
.fad.#{$fa-css-prefix}-fragile:after { content: fa-content-secondary($fa-var-fragile); }
.fad.#{$fa-css-prefix}-french-fries:after { content: fa-content-secondary($fa-var-french-fries); }
.fad.#{$fa-css-prefix}-frog:after { content: fa-content-secondary($fa-var-frog); }
.fad.#{$fa-css-prefix}-frosty-head:after { content: fa-content-secondary($fa-var-frosty-head); }
.fad.#{$fa-css-prefix}-frown:after { content: fa-content-secondary($fa-var-frown); }
.fad.#{$fa-css-prefix}-frown-open:after { content: fa-content-secondary($fa-var-frown-open); }
.fad.#{$fa-css-prefix}-function:after { content: fa-content-secondary($fa-var-function); }
.fad.#{$fa-css-prefix}-funnel-dollar:after { content: fa-content-secondary($fa-var-funnel-dollar); }
.fad.#{$fa-css-prefix}-futbol:after { content: fa-content-secondary($fa-var-futbol); }
.fad.#{$fa-css-prefix}-game-board:after { content: fa-content-secondary($fa-var-game-board); }
.fad.#{$fa-css-prefix}-game-board-alt:after { content: fa-content-secondary($fa-var-game-board-alt); }
.fad.#{$fa-css-prefix}-gamepad:after { content: fa-content-secondary($fa-var-gamepad); }
.fad.#{$fa-css-prefix}-gas-pump:after { content: fa-content-secondary($fa-var-gas-pump); }
.fad.#{$fa-css-prefix}-gas-pump-slash:after { content: fa-content-secondary($fa-var-gas-pump-slash); }
.fad.#{$fa-css-prefix}-gavel:after { content: fa-content-secondary($fa-var-gavel); }
.fad.#{$fa-css-prefix}-gem:after { content: fa-content-secondary($fa-var-gem); }
.fad.#{$fa-css-prefix}-genderless:after { content: fa-content($fa-var-genderless); visibility: hidden; }
.fad.#{$fa-css-prefix}-ghost:after { content: fa-content-secondary($fa-var-ghost); }
.fad.#{$fa-css-prefix}-gift:after { content: fa-content-secondary($fa-var-gift); }
.fad.#{$fa-css-prefix}-gift-card:after { content: fa-content-secondary($fa-var-gift-card); }
.fad.#{$fa-css-prefix}-gifts:after { content: fa-content-secondary($fa-var-gifts); }
.fad.#{$fa-css-prefix}-gingerbread-man:after { content: fa-content-secondary($fa-var-gingerbread-man); }
.fad.#{$fa-css-prefix}-glass:after { content: fa-content-secondary($fa-var-glass); }
.fad.#{$fa-css-prefix}-glass-champagne:after { content: fa-content-secondary($fa-var-glass-champagne); }
.fad.#{$fa-css-prefix}-glass-cheers:after { content: fa-content-secondary($fa-var-glass-cheers); }
.fad.#{$fa-css-prefix}-glass-citrus:after { content: fa-content-secondary($fa-var-glass-citrus); }
.fad.#{$fa-css-prefix}-glass-martini:after { content: fa-content($fa-var-glass-martini); visibility: hidden; }
.fad.#{$fa-css-prefix}-glass-martini-alt:after { content: fa-content-secondary($fa-var-glass-martini-alt); }
.fad.#{$fa-css-prefix}-glass-whiskey:after { content: fa-content-secondary($fa-var-glass-whiskey); }
.fad.#{$fa-css-prefix}-glass-whiskey-rocks:after { content: fa-content-secondary($fa-var-glass-whiskey-rocks); }
.fad.#{$fa-css-prefix}-glasses:after { content: fa-content-secondary($fa-var-glasses); }
.fad.#{$fa-css-prefix}-glasses-alt:after { content: fa-content-secondary($fa-var-glasses-alt); }
.fad.#{$fa-css-prefix}-globe:after { content: fa-content-secondary($fa-var-globe); }
.fad.#{$fa-css-prefix}-globe-africa:after { content: fa-content-secondary($fa-var-globe-africa); }
.fad.#{$fa-css-prefix}-globe-americas:after { content: fa-content-secondary($fa-var-globe-americas); }
.fad.#{$fa-css-prefix}-globe-asia:after { content: fa-content-secondary($fa-var-globe-asia); }
.fad.#{$fa-css-prefix}-globe-europe:after { content: fa-content-secondary($fa-var-globe-europe); }
.fad.#{$fa-css-prefix}-globe-snow:after { content: fa-content-secondary($fa-var-globe-snow); }
.fad.#{$fa-css-prefix}-globe-stand:after { content: fa-content-secondary($fa-var-globe-stand); }
.fad.#{$fa-css-prefix}-golf-ball:after { content: fa-content-secondary($fa-var-golf-ball); }
.fad.#{$fa-css-prefix}-golf-club:after { content: fa-content-secondary($fa-var-golf-club); }
.fad.#{$fa-css-prefix}-gopuram:after { content: fa-content-secondary($fa-var-gopuram); }
.fad.#{$fa-css-prefix}-graduation-cap:after { content: fa-content-secondary($fa-var-graduation-cap); }
.fad.#{$fa-css-prefix}-greater-than:after { content: fa-content($fa-var-greater-than); visibility: hidden; }
.fad.#{$fa-css-prefix}-greater-than-equal:after { content: fa-content-secondary($fa-var-greater-than-equal); }
.fad.#{$fa-css-prefix}-grimace:after { content: fa-content-secondary($fa-var-grimace); }
.fad.#{$fa-css-prefix}-grin:after { content: fa-content-secondary($fa-var-grin); }
.fad.#{$fa-css-prefix}-grin-alt:after { content: fa-content-secondary($fa-var-grin-alt); }
.fad.#{$fa-css-prefix}-grin-beam:after { content: fa-content-secondary($fa-var-grin-beam); }
.fad.#{$fa-css-prefix}-grin-beam-sweat:after { content: fa-content-secondary($fa-var-grin-beam-sweat); }
.fad.#{$fa-css-prefix}-grin-hearts:after { content: fa-content-secondary($fa-var-grin-hearts); }
.fad.#{$fa-css-prefix}-grin-squint:after { content: fa-content-secondary($fa-var-grin-squint); }
.fad.#{$fa-css-prefix}-grin-squint-tears:after { content: fa-content-secondary($fa-var-grin-squint-tears); }
.fad.#{$fa-css-prefix}-grin-stars:after { content: fa-content-secondary($fa-var-grin-stars); }
.fad.#{$fa-css-prefix}-grin-tears:after { content: fa-content-secondary($fa-var-grin-tears); }
.fad.#{$fa-css-prefix}-grin-tongue:after { content: fa-content-secondary($fa-var-grin-tongue); }
.fad.#{$fa-css-prefix}-grin-tongue-squint:after { content: fa-content-secondary($fa-var-grin-tongue-squint); }
.fad.#{$fa-css-prefix}-grin-tongue-wink:after { content: fa-content-secondary($fa-var-grin-tongue-wink); }
.fad.#{$fa-css-prefix}-grin-wink:after { content: fa-content-secondary($fa-var-grin-wink); }
.fad.#{$fa-css-prefix}-grip-horizontal:after { content: fa-content($fa-var-grip-horizontal); visibility: hidden; }
.fad.#{$fa-css-prefix}-grip-lines:after { content: fa-content($fa-var-grip-lines); visibility: hidden; }
.fad.#{$fa-css-prefix}-grip-lines-vertical:after { content: fa-content($fa-var-grip-lines-vertical); visibility: hidden; }
.fad.#{$fa-css-prefix}-grip-vertical:after { content: fa-content($fa-var-grip-vertical); visibility: hidden; }
.fad.#{$fa-css-prefix}-guitar:after { content: fa-content-secondary($fa-var-guitar); }
.fad.#{$fa-css-prefix}-h-square:after { content: fa-content-secondary($fa-var-h-square); }
.fad.#{$fa-css-prefix}-h1:after { content: fa-content-secondary($fa-var-h1); }
.fad.#{$fa-css-prefix}-h2:after { content: fa-content-secondary($fa-var-h2); }
.fad.#{$fa-css-prefix}-h3:after { content: fa-content-secondary($fa-var-h3); }
.fad.#{$fa-css-prefix}-h4:after { content: fa-content-secondary($fa-var-h4); }
.fad.#{$fa-css-prefix}-hamburger:after { content: fa-content-secondary($fa-var-hamburger); }
.fad.#{$fa-css-prefix}-hammer:after { content: fa-content-secondary($fa-var-hammer); }
.fad.#{$fa-css-prefix}-hammer-war:after { content: fa-content-secondary($fa-var-hammer-war); }
.fad.#{$fa-css-prefix}-hamsa:after { content: fa-content-secondary($fa-var-hamsa); }
.fad.#{$fa-css-prefix}-hand-heart:after { content: fa-content-secondary($fa-var-hand-heart); }
.fad.#{$fa-css-prefix}-hand-holding:after { content: fa-content($fa-var-hand-holding); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-holding-box:after { content: fa-content-secondary($fa-var-hand-holding-box); }
.fad.#{$fa-css-prefix}-hand-holding-heart:after { content: fa-content-secondary($fa-var-hand-holding-heart); }
.fad.#{$fa-css-prefix}-hand-holding-magic:after { content: fa-content-secondary($fa-var-hand-holding-magic); }
.fad.#{$fa-css-prefix}-hand-holding-seedling:after { content: fa-content-secondary($fa-var-hand-holding-seedling); }
.fad.#{$fa-css-prefix}-hand-holding-usd:after { content: fa-content-secondary($fa-var-hand-holding-usd); }
.fad.#{$fa-css-prefix}-hand-holding-water:after { content: fa-content-secondary($fa-var-hand-holding-water); }
.fad.#{$fa-css-prefix}-hand-lizard:after { content: fa-content($fa-var-hand-lizard); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-middle-finger:after { content: fa-content($fa-var-hand-middle-finger); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-paper:after { content: fa-content($fa-var-hand-paper); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-peace:after { content: fa-content($fa-var-hand-peace); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-point-down:after { content: fa-content-secondary($fa-var-hand-point-down); }
.fad.#{$fa-css-prefix}-hand-point-left:after { content: fa-content-secondary($fa-var-hand-point-left); }
.fad.#{$fa-css-prefix}-hand-point-right:after { content: fa-content-secondary($fa-var-hand-point-right); }
.fad.#{$fa-css-prefix}-hand-point-up:after { content: fa-content-secondary($fa-var-hand-point-up); }
.fad.#{$fa-css-prefix}-hand-pointer:after { content: fa-content-secondary($fa-var-hand-pointer); }
.fad.#{$fa-css-prefix}-hand-receiving:after { content: fa-content-secondary($fa-var-hand-receiving); }
.fad.#{$fa-css-prefix}-hand-rock:after { content: fa-content($fa-var-hand-rock); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-scissors:after { content: fa-content($fa-var-hand-scissors); visibility: hidden; }
.fad.#{$fa-css-prefix}-hand-spock:after { content: fa-content($fa-var-hand-spock); visibility: hidden; }
.fad.#{$fa-css-prefix}-hands:after { content: fa-content($fa-var-hands); visibility: hidden; }
.fad.#{$fa-css-prefix}-hands-heart:after { content: fa-content-secondary($fa-var-hands-heart); }
.fad.#{$fa-css-prefix}-hands-helping:after { content: fa-content-secondary($fa-var-hands-helping); }
.fad.#{$fa-css-prefix}-hands-usd:after { content: fa-content-secondary($fa-var-hands-usd); }
.fad.#{$fa-css-prefix}-handshake:after { content: fa-content-secondary($fa-var-handshake); }
.fad.#{$fa-css-prefix}-handshake-alt:after { content: fa-content-secondary($fa-var-handshake-alt); }
.fad.#{$fa-css-prefix}-hanukiah:after { content: fa-content-secondary($fa-var-hanukiah); }
.fad.#{$fa-css-prefix}-hard-hat:after { content: fa-content-secondary($fa-var-hard-hat); }
.fad.#{$fa-css-prefix}-hashtag:after { content: fa-content($fa-var-hashtag); visibility: hidden; }
.fad.#{$fa-css-prefix}-hat-chef:after { content: fa-content-secondary($fa-var-hat-chef); }
.fad.#{$fa-css-prefix}-hat-santa:after { content: fa-content-secondary($fa-var-hat-santa); }
.fad.#{$fa-css-prefix}-hat-winter:after { content: fa-content-secondary($fa-var-hat-winter); }
.fad.#{$fa-css-prefix}-hat-witch:after { content: fa-content-secondary($fa-var-hat-witch); }
.fad.#{$fa-css-prefix}-hat-wizard:after { content: fa-content-secondary($fa-var-hat-wizard); }
.fad.#{$fa-css-prefix}-haykal:after { content: fa-content-secondary($fa-var-haykal); }
.fad.#{$fa-css-prefix}-hdd:after { content: fa-content-secondary($fa-var-hdd); }
.fad.#{$fa-css-prefix}-head-side:after { content: fa-content-secondary($fa-var-head-side); }
.fad.#{$fa-css-prefix}-head-side-brain:after { content: fa-content-secondary($fa-var-head-side-brain); }
.fad.#{$fa-css-prefix}-head-side-medical:after { content: fa-content-secondary($fa-var-head-side-medical); }
.fad.#{$fa-css-prefix}-head-vr:after { content: fa-content-secondary($fa-var-head-vr); }
.fad.#{$fa-css-prefix}-heading:after { content: fa-content($fa-var-heading); visibility: hidden; }
.fad.#{$fa-css-prefix}-headphones:after { content: fa-content-secondary($fa-var-headphones); }
.fad.#{$fa-css-prefix}-headphones-alt:after { content: fa-content-secondary($fa-var-headphones-alt); }
.fad.#{$fa-css-prefix}-headset:after { content: fa-content-secondary($fa-var-headset); }
.fad.#{$fa-css-prefix}-heart:after { content: fa-content($fa-var-heart); visibility: hidden; }
.fad.#{$fa-css-prefix}-heart-broken:after { content: fa-content-secondary($fa-var-heart-broken); }
.fad.#{$fa-css-prefix}-heart-circle:after { content: fa-content-secondary($fa-var-heart-circle); }
.fad.#{$fa-css-prefix}-heart-rate:after { content: fa-content($fa-var-heart-rate); visibility: hidden; }
.fad.#{$fa-css-prefix}-heart-square:after { content: fa-content-secondary($fa-var-heart-square); }
.fad.#{$fa-css-prefix}-heartbeat:after { content: fa-content-secondary($fa-var-heartbeat); }
.fad.#{$fa-css-prefix}-helicopter:after { content: fa-content-secondary($fa-var-helicopter); }
.fad.#{$fa-css-prefix}-helmet-battle:after { content: fa-content-secondary($fa-var-helmet-battle); }
.fad.#{$fa-css-prefix}-hexagon:after { content: fa-content($fa-var-hexagon); visibility: hidden; }
.fad.#{$fa-css-prefix}-highlighter:after { content: fa-content-secondary($fa-var-highlighter); }
.fad.#{$fa-css-prefix}-hiking:after { content: fa-content-secondary($fa-var-hiking); }
.fad.#{$fa-css-prefix}-hippo:after { content: fa-content($fa-var-hippo); visibility: hidden; }
.fad.#{$fa-css-prefix}-history:after { content: fa-content-secondary($fa-var-history); }
.fad.#{$fa-css-prefix}-hockey-mask:after { content: fa-content-secondary($fa-var-hockey-mask); }
.fad.#{$fa-css-prefix}-hockey-puck:after { content: fa-content-secondary($fa-var-hockey-puck); }
.fad.#{$fa-css-prefix}-hockey-sticks:after { content: fa-content-secondary($fa-var-hockey-sticks); }
.fad.#{$fa-css-prefix}-holly-berry:after { content: fa-content-secondary($fa-var-holly-berry); }
.fad.#{$fa-css-prefix}-home:after { content: fa-content-secondary($fa-var-home); }
.fad.#{$fa-css-prefix}-home-alt:after { content: fa-content-secondary($fa-var-home-alt); }
.fad.#{$fa-css-prefix}-home-heart:after { content: fa-content-secondary($fa-var-home-heart); }
.fad.#{$fa-css-prefix}-home-lg:after { content: fa-content-secondary($fa-var-home-lg); }
.fad.#{$fa-css-prefix}-home-lg-alt:after { content: fa-content-secondary($fa-var-home-lg-alt); }
.fad.#{$fa-css-prefix}-hood-cloak:after { content: fa-content-secondary($fa-var-hood-cloak); }
.fad.#{$fa-css-prefix}-horizontal-rule:after { content: fa-content($fa-var-horizontal-rule); visibility: hidden; }
.fad.#{$fa-css-prefix}-horse:after { content: fa-content-secondary($fa-var-horse); }
.fad.#{$fa-css-prefix}-horse-head:after { content: fa-content($fa-var-horse-head); visibility: hidden; }
.fad.#{$fa-css-prefix}-hospital:after { content: fa-content-secondary($fa-var-hospital); }
.fad.#{$fa-css-prefix}-hospital-alt:after { content: fa-content-secondary($fa-var-hospital-alt); }
.fad.#{$fa-css-prefix}-hospital-symbol:after { content: fa-content-secondary($fa-var-hospital-symbol); }
.fad.#{$fa-css-prefix}-hospital-user:after { content: fa-content-secondary($fa-var-hospital-user); }
.fad.#{$fa-css-prefix}-hospitals:after { content: fa-content-secondary($fa-var-hospitals); }
.fad.#{$fa-css-prefix}-hot-tub:after { content: fa-content-secondary($fa-var-hot-tub); }
.fad.#{$fa-css-prefix}-hotdog:after { content: fa-content-secondary($fa-var-hotdog); }
.fad.#{$fa-css-prefix}-hotel:after { content: fa-content-secondary($fa-var-hotel); }
.fad.#{$fa-css-prefix}-hourglass:after { content: fa-content-secondary($fa-var-hourglass); }
.fad.#{$fa-css-prefix}-hourglass-end:after { content: fa-content-secondary($fa-var-hourglass-end); }
.fad.#{$fa-css-prefix}-hourglass-half:after { content: fa-content-secondary($fa-var-hourglass-half); }
.fad.#{$fa-css-prefix}-hourglass-start:after { content: fa-content-secondary($fa-var-hourglass-start); }
.fad.#{$fa-css-prefix}-house-damage:after { content: fa-content-secondary($fa-var-house-damage); }
.fad.#{$fa-css-prefix}-house-flood:after { content: fa-content-secondary($fa-var-house-flood); }
.fad.#{$fa-css-prefix}-hryvnia:after { content: fa-content-secondary($fa-var-hryvnia); }
.fad.#{$fa-css-prefix}-humidity:after { content: fa-content-secondary($fa-var-humidity); }
.fad.#{$fa-css-prefix}-hurricane:after { content: fa-content-secondary($fa-var-hurricane); }
.fad.#{$fa-css-prefix}-i-cursor:after { content: fa-content($fa-var-i-cursor); visibility: hidden; }
.fad.#{$fa-css-prefix}-ice-cream:after { content: fa-content-secondary($fa-var-ice-cream); }
.fad.#{$fa-css-prefix}-ice-skate:after { content: fa-content-secondary($fa-var-ice-skate); }
.fad.#{$fa-css-prefix}-icicles:after { content: fa-content($fa-var-icicles); visibility: hidden; }
.fad.#{$fa-css-prefix}-icons:after { content: fa-content-secondary($fa-var-icons); }
.fad.#{$fa-css-prefix}-icons-alt:after { content: fa-content-secondary($fa-var-icons-alt); }
.fad.#{$fa-css-prefix}-id-badge:after { content: fa-content-secondary($fa-var-id-badge); }
.fad.#{$fa-css-prefix}-id-card:after { content: fa-content-secondary($fa-var-id-card); }
.fad.#{$fa-css-prefix}-id-card-alt:after { content: fa-content-secondary($fa-var-id-card-alt); }
.fad.#{$fa-css-prefix}-igloo:after { content: fa-content-secondary($fa-var-igloo); }
.fad.#{$fa-css-prefix}-image:after { content: fa-content-secondary($fa-var-image); }
.fad.#{$fa-css-prefix}-images:after { content: fa-content-secondary($fa-var-images); }
.fad.#{$fa-css-prefix}-inbox:after { content: fa-content($fa-var-inbox); visibility: hidden; }
.fad.#{$fa-css-prefix}-inbox-in:after { content: fa-content-secondary($fa-var-inbox-in); }
.fad.#{$fa-css-prefix}-inbox-out:after { content: fa-content-secondary($fa-var-inbox-out); }
.fad.#{$fa-css-prefix}-indent:after { content: fa-content-secondary($fa-var-indent); }
.fad.#{$fa-css-prefix}-industry:after { content: fa-content($fa-var-industry); visibility: hidden; }
.fad.#{$fa-css-prefix}-industry-alt:after { content: fa-content-secondary($fa-var-industry-alt); }
.fad.#{$fa-css-prefix}-infinity:after { content: fa-content($fa-var-infinity); visibility: hidden; }
.fad.#{$fa-css-prefix}-info:after { content: fa-content($fa-var-info); visibility: hidden; }
.fad.#{$fa-css-prefix}-info-circle:after { content: fa-content-secondary($fa-var-info-circle); }
.fad.#{$fa-css-prefix}-info-square:after { content: fa-content-secondary($fa-var-info-square); }
.fad.#{$fa-css-prefix}-inhaler:after { content: fa-content-secondary($fa-var-inhaler); }
.fad.#{$fa-css-prefix}-integral:after { content: fa-content($fa-var-integral); visibility: hidden; }
.fad.#{$fa-css-prefix}-intersection:after { content: fa-content($fa-var-intersection); visibility: hidden; }
.fad.#{$fa-css-prefix}-inventory:after { content: fa-content-secondary($fa-var-inventory); }
.fad.#{$fa-css-prefix}-island-tropical:after { content: fa-content-secondary($fa-var-island-tropical); }
.fad.#{$fa-css-prefix}-italic:after { content: fa-content($fa-var-italic); visibility: hidden; }
.fad.#{$fa-css-prefix}-jack-o-lantern:after { content: fa-content-secondary($fa-var-jack-o-lantern); }
.fad.#{$fa-css-prefix}-jedi:after { content: fa-content-secondary($fa-var-jedi); }
.fad.#{$fa-css-prefix}-joint:after { content: fa-content-secondary($fa-var-joint); }
.fad.#{$fa-css-prefix}-journal-whills:after { content: fa-content-secondary($fa-var-journal-whills); }
.fad.#{$fa-css-prefix}-kaaba:after { content: fa-content-secondary($fa-var-kaaba); }
.fad.#{$fa-css-prefix}-kerning:after { content: fa-content-secondary($fa-var-kerning); }
.fad.#{$fa-css-prefix}-key:after { content: fa-content($fa-var-key); visibility: hidden; }
.fad.#{$fa-css-prefix}-key-skeleton:after { content: fa-content($fa-var-key-skeleton); visibility: hidden; }
.fad.#{$fa-css-prefix}-keyboard:after { content: fa-content-secondary($fa-var-keyboard); }
.fad.#{$fa-css-prefix}-keynote:after { content: fa-content-secondary($fa-var-keynote); }
.fad.#{$fa-css-prefix}-khanda:after { content: fa-content-secondary($fa-var-khanda); }
.fad.#{$fa-css-prefix}-kidneys:after { content: fa-content-secondary($fa-var-kidneys); }
.fad.#{$fa-css-prefix}-kiss:after { content: fa-content-secondary($fa-var-kiss); }
.fad.#{$fa-css-prefix}-kiss-beam:after { content: fa-content-secondary($fa-var-kiss-beam); }
.fad.#{$fa-css-prefix}-kiss-wink-heart:after { content: fa-content-secondary($fa-var-kiss-wink-heart); }
.fad.#{$fa-css-prefix}-kite:after { content: fa-content-secondary($fa-var-kite); }
.fad.#{$fa-css-prefix}-kiwi-bird:after { content: fa-content-secondary($fa-var-kiwi-bird); }
.fad.#{$fa-css-prefix}-knife-kitchen:after { content: fa-content-secondary($fa-var-knife-kitchen); }
.fad.#{$fa-css-prefix}-lambda:after { content: fa-content($fa-var-lambda); visibility: hidden; }
.fad.#{$fa-css-prefix}-lamp:after { content: fa-content-secondary($fa-var-lamp); }
.fad.#{$fa-css-prefix}-landmark:after { content: fa-content-secondary($fa-var-landmark); }
.fad.#{$fa-css-prefix}-landmark-alt:after { content: fa-content-secondary($fa-var-landmark-alt); }
.fad.#{$fa-css-prefix}-language:after { content: fa-content-secondary($fa-var-language); }
.fad.#{$fa-css-prefix}-laptop:after { content: fa-content-secondary($fa-var-laptop); }
.fad.#{$fa-css-prefix}-laptop-code:after { content: fa-content-secondary($fa-var-laptop-code); }
.fad.#{$fa-css-prefix}-laptop-medical:after { content: fa-content-secondary($fa-var-laptop-medical); }
.fad.#{$fa-css-prefix}-laugh:after { content: fa-content-secondary($fa-var-laugh); }
.fad.#{$fa-css-prefix}-laugh-beam:after { content: fa-content-secondary($fa-var-laugh-beam); }
.fad.#{$fa-css-prefix}-laugh-squint:after { content: fa-content-secondary($fa-var-laugh-squint); }
.fad.#{$fa-css-prefix}-laugh-wink:after { content: fa-content-secondary($fa-var-laugh-wink); }
.fad.#{$fa-css-prefix}-layer-group:after { content: fa-content-secondary($fa-var-layer-group); }
.fad.#{$fa-css-prefix}-layer-minus:after { content: fa-content-secondary($fa-var-layer-minus); }
.fad.#{$fa-css-prefix}-layer-plus:after { content: fa-content-secondary($fa-var-layer-plus); }
.fad.#{$fa-css-prefix}-leaf:after { content: fa-content-secondary($fa-var-leaf); }
.fad.#{$fa-css-prefix}-leaf-heart:after { content: fa-content-secondary($fa-var-leaf-heart); }
.fad.#{$fa-css-prefix}-leaf-maple:after { content: fa-content-secondary($fa-var-leaf-maple); }
.fad.#{$fa-css-prefix}-leaf-oak:after { content: fa-content-secondary($fa-var-leaf-oak); }
.fad.#{$fa-css-prefix}-lemon:after { content: fa-content-secondary($fa-var-lemon); }
.fad.#{$fa-css-prefix}-less-than:after { content: fa-content($fa-var-less-than); visibility: hidden; }
.fad.#{$fa-css-prefix}-less-than-equal:after { content: fa-content-secondary($fa-var-less-than-equal); }
.fad.#{$fa-css-prefix}-level-down:after { content: fa-content($fa-var-level-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-level-down-alt:after { content: fa-content($fa-var-level-down-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-level-up:after { content: fa-content($fa-var-level-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-level-up-alt:after { content: fa-content($fa-var-level-up-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-life-ring:after { content: fa-content-secondary($fa-var-life-ring); }
.fad.#{$fa-css-prefix}-lightbulb:after { content: fa-content-secondary($fa-var-lightbulb); }
.fad.#{$fa-css-prefix}-lightbulb-dollar:after { content: fa-content-secondary($fa-var-lightbulb-dollar); }
.fad.#{$fa-css-prefix}-lightbulb-exclamation:after { content: fa-content-secondary($fa-var-lightbulb-exclamation); }
.fad.#{$fa-css-prefix}-lightbulb-on:after { content: fa-content-secondary($fa-var-lightbulb-on); }
.fad.#{$fa-css-prefix}-lightbulb-slash:after { content: fa-content-secondary($fa-var-lightbulb-slash); }
.fad.#{$fa-css-prefix}-lights-holiday:after { content: fa-content-secondary($fa-var-lights-holiday); }
.fad.#{$fa-css-prefix}-line-columns:after { content: fa-content-secondary($fa-var-line-columns); }
.fad.#{$fa-css-prefix}-line-height:after { content: fa-content-secondary($fa-var-line-height); }
.fad.#{$fa-css-prefix}-link:after { content: fa-content-secondary($fa-var-link); }
.fad.#{$fa-css-prefix}-lips:after { content: fa-content-secondary($fa-var-lips); }
.fad.#{$fa-css-prefix}-lira-sign:after { content: fa-content($fa-var-lira-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-list:after { content: fa-content-secondary($fa-var-list); }
.fad.#{$fa-css-prefix}-list-alt:after { content: fa-content-secondary($fa-var-list-alt); }
.fad.#{$fa-css-prefix}-list-ol:after { content: fa-content-secondary($fa-var-list-ol); }
.fad.#{$fa-css-prefix}-list-ul:after { content: fa-content-secondary($fa-var-list-ul); }
.fad.#{$fa-css-prefix}-location:after { content: fa-content-secondary($fa-var-location); }
.fad.#{$fa-css-prefix}-location-arrow:after { content: fa-content($fa-var-location-arrow); visibility: hidden; }
.fad.#{$fa-css-prefix}-location-circle:after { content: fa-content-secondary($fa-var-location-circle); }
.fad.#{$fa-css-prefix}-location-slash:after { content: fa-content-secondary($fa-var-location-slash); }
.fad.#{$fa-css-prefix}-lock:after { content: fa-content-secondary($fa-var-lock); }
.fad.#{$fa-css-prefix}-lock-alt:after { content: fa-content-secondary($fa-var-lock-alt); }
.fad.#{$fa-css-prefix}-lock-open:after { content: fa-content-secondary($fa-var-lock-open); }
.fad.#{$fa-css-prefix}-lock-open-alt:after { content: fa-content-secondary($fa-var-lock-open-alt); }
.fad.#{$fa-css-prefix}-long-arrow-alt-down:after { content: fa-content($fa-var-long-arrow-alt-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-alt-left:after { content: fa-content($fa-var-long-arrow-alt-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-alt-right:after { content: fa-content($fa-var-long-arrow-alt-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-alt-up:after { content: fa-content($fa-var-long-arrow-alt-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-down:after { content: fa-content($fa-var-long-arrow-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-left:after { content: fa-content($fa-var-long-arrow-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-right:after { content: fa-content($fa-var-long-arrow-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-long-arrow-up:after { content: fa-content($fa-var-long-arrow-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-loveseat:after { content: fa-content-secondary($fa-var-loveseat); }
.fad.#{$fa-css-prefix}-low-vision:after { content: fa-content-secondary($fa-var-low-vision); }
.fad.#{$fa-css-prefix}-luchador:after { content: fa-content-secondary($fa-var-luchador); }
.fad.#{$fa-css-prefix}-luggage-cart:after { content: fa-content-secondary($fa-var-luggage-cart); }
.fad.#{$fa-css-prefix}-lungs:after { content: fa-content-secondary($fa-var-lungs); }
.fad.#{$fa-css-prefix}-mace:after { content: fa-content-secondary($fa-var-mace); }
.fad.#{$fa-css-prefix}-magic:after { content: fa-content-secondary($fa-var-magic); }
.fad.#{$fa-css-prefix}-magnet:after { content: fa-content-secondary($fa-var-magnet); }
.fad.#{$fa-css-prefix}-mail-bulk:after { content: fa-content-secondary($fa-var-mail-bulk); }
.fad.#{$fa-css-prefix}-mailbox:after { content: fa-content-secondary($fa-var-mailbox); }
.fad.#{$fa-css-prefix}-male:after { content: fa-content-secondary($fa-var-male); }
.fad.#{$fa-css-prefix}-mandolin:after { content: fa-content-secondary($fa-var-mandolin); }
.fad.#{$fa-css-prefix}-map:after { content: fa-content-secondary($fa-var-map); }
.fad.#{$fa-css-prefix}-map-marked:after { content: fa-content-secondary($fa-var-map-marked); }
.fad.#{$fa-css-prefix}-map-marked-alt:after { content: fa-content-secondary($fa-var-map-marked-alt); }
.fad.#{$fa-css-prefix}-map-marker:after { content: fa-content($fa-var-map-marker); visibility: hidden; }
.fad.#{$fa-css-prefix}-map-marker-alt:after { content: fa-content-secondary($fa-var-map-marker-alt); }
.fad.#{$fa-css-prefix}-map-marker-alt-slash:after { content: fa-content-secondary($fa-var-map-marker-alt-slash); }
.fad.#{$fa-css-prefix}-map-marker-check:after { content: fa-content-secondary($fa-var-map-marker-check); }
.fad.#{$fa-css-prefix}-map-marker-edit:after { content: fa-content-secondary($fa-var-map-marker-edit); }
.fad.#{$fa-css-prefix}-map-marker-exclamation:after { content: fa-content-secondary($fa-var-map-marker-exclamation); }
.fad.#{$fa-css-prefix}-map-marker-minus:after { content: fa-content-secondary($fa-var-map-marker-minus); }
.fad.#{$fa-css-prefix}-map-marker-plus:after { content: fa-content-secondary($fa-var-map-marker-plus); }
.fad.#{$fa-css-prefix}-map-marker-question:after { content: fa-content-secondary($fa-var-map-marker-question); }
.fad.#{$fa-css-prefix}-map-marker-slash:after { content: fa-content-secondary($fa-var-map-marker-slash); }
.fad.#{$fa-css-prefix}-map-marker-smile:after { content: fa-content-secondary($fa-var-map-marker-smile); }
.fad.#{$fa-css-prefix}-map-marker-times:after { content: fa-content-secondary($fa-var-map-marker-times); }
.fad.#{$fa-css-prefix}-map-pin:after { content: fa-content-secondary($fa-var-map-pin); }
.fad.#{$fa-css-prefix}-map-signs:after { content: fa-content-secondary($fa-var-map-signs); }
.fad.#{$fa-css-prefix}-marker:after { content: fa-content-secondary($fa-var-marker); }
.fad.#{$fa-css-prefix}-mars:after { content: fa-content($fa-var-mars); visibility: hidden; }
.fad.#{$fa-css-prefix}-mars-double:after { content: fa-content-secondary($fa-var-mars-double); }
.fad.#{$fa-css-prefix}-mars-stroke:after { content: fa-content($fa-var-mars-stroke); visibility: hidden; }
.fad.#{$fa-css-prefix}-mars-stroke-h:after { content: fa-content($fa-var-mars-stroke-h); visibility: hidden; }
.fad.#{$fa-css-prefix}-mars-stroke-v:after { content: fa-content($fa-var-mars-stroke-v); visibility: hidden; }
.fad.#{$fa-css-prefix}-mask:after { content: fa-content($fa-var-mask); visibility: hidden; }
.fad.#{$fa-css-prefix}-meat:after { content: fa-content-secondary($fa-var-meat); }
.fad.#{$fa-css-prefix}-medal:after { content: fa-content-secondary($fa-var-medal); }
.fad.#{$fa-css-prefix}-medkit:after { content: fa-content-secondary($fa-var-medkit); }
.fad.#{$fa-css-prefix}-megaphone:after { content: fa-content-secondary($fa-var-megaphone); }
.fad.#{$fa-css-prefix}-meh:after { content: fa-content-secondary($fa-var-meh); }
.fad.#{$fa-css-prefix}-meh-blank:after { content: fa-content-secondary($fa-var-meh-blank); }
.fad.#{$fa-css-prefix}-meh-rolling-eyes:after { content: fa-content-secondary($fa-var-meh-rolling-eyes); }
.fad.#{$fa-css-prefix}-memory:after { content: fa-content-secondary($fa-var-memory); }
.fad.#{$fa-css-prefix}-menorah:after { content: fa-content-secondary($fa-var-menorah); }
.fad.#{$fa-css-prefix}-mercury:after { content: fa-content($fa-var-mercury); visibility: hidden; }
.fad.#{$fa-css-prefix}-meteor:after { content: fa-content-secondary($fa-var-meteor); }
.fad.#{$fa-css-prefix}-microchip:after { content: fa-content-secondary($fa-var-microchip); }
.fad.#{$fa-css-prefix}-microphone:after { content: fa-content-secondary($fa-var-microphone); }
.fad.#{$fa-css-prefix}-microphone-alt:after { content: fa-content-secondary($fa-var-microphone-alt); }
.fad.#{$fa-css-prefix}-microphone-alt-slash:after { content: fa-content-secondary($fa-var-microphone-alt-slash); }
.fad.#{$fa-css-prefix}-microphone-slash:after { content: fa-content-secondary($fa-var-microphone-slash); }
.fad.#{$fa-css-prefix}-microscope:after { content: fa-content-secondary($fa-var-microscope); }
.fad.#{$fa-css-prefix}-mind-share:after { content: fa-content-secondary($fa-var-mind-share); }
.fad.#{$fa-css-prefix}-minus:after { content: fa-content($fa-var-minus); visibility: hidden; }
.fad.#{$fa-css-prefix}-minus-circle:after { content: fa-content($fa-var-minus-circle); visibility: hidden; }
.fad.#{$fa-css-prefix}-minus-hexagon:after { content: fa-content-secondary($fa-var-minus-hexagon); }
.fad.#{$fa-css-prefix}-minus-octagon:after { content: fa-content-secondary($fa-var-minus-octagon); }
.fad.#{$fa-css-prefix}-minus-square:after { content: fa-content-secondary($fa-var-minus-square); }
.fad.#{$fa-css-prefix}-mistletoe:after { content: fa-content-secondary($fa-var-mistletoe); }
.fad.#{$fa-css-prefix}-mitten:after { content: fa-content-secondary($fa-var-mitten); }
.fad.#{$fa-css-prefix}-mobile:after { content: fa-content($fa-var-mobile); visibility: hidden; }
.fad.#{$fa-css-prefix}-mobile-alt:after { content: fa-content-secondary($fa-var-mobile-alt); }
.fad.#{$fa-css-prefix}-mobile-android:after { content: fa-content($fa-var-mobile-android); visibility: hidden; }
.fad.#{$fa-css-prefix}-mobile-android-alt:after { content: fa-content-secondary($fa-var-mobile-android-alt); }
.fad.#{$fa-css-prefix}-money-bill:after { content: fa-content-secondary($fa-var-money-bill); }
.fad.#{$fa-css-prefix}-money-bill-alt:after { content: fa-content-secondary($fa-var-money-bill-alt); }
.fad.#{$fa-css-prefix}-money-bill-wave:after { content: fa-content-secondary($fa-var-money-bill-wave); }
.fad.#{$fa-css-prefix}-money-bill-wave-alt:after { content: fa-content($fa-var-money-bill-wave-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-money-check:after { content: fa-content-secondary($fa-var-money-check); }
.fad.#{$fa-css-prefix}-money-check-alt:after { content: fa-content-secondary($fa-var-money-check-alt); }
.fad.#{$fa-css-prefix}-money-check-edit:after { content: fa-content-secondary($fa-var-money-check-edit); }
.fad.#{$fa-css-prefix}-money-check-edit-alt:after { content: fa-content-secondary($fa-var-money-check-edit-alt); }
.fad.#{$fa-css-prefix}-monitor-heart-rate:after { content: fa-content-secondary($fa-var-monitor-heart-rate); }
.fad.#{$fa-css-prefix}-monkey:after { content: fa-content-secondary($fa-var-monkey); }
.fad.#{$fa-css-prefix}-monument:after { content: fa-content-secondary($fa-var-monument); }
.fad.#{$fa-css-prefix}-moon:after { content: fa-content($fa-var-moon); visibility: hidden; }
.fad.#{$fa-css-prefix}-moon-cloud:after { content: fa-content-secondary($fa-var-moon-cloud); }
.fad.#{$fa-css-prefix}-moon-stars:after { content: fa-content-secondary($fa-var-moon-stars); }
.fad.#{$fa-css-prefix}-mortar-pestle:after { content: fa-content-secondary($fa-var-mortar-pestle); }
.fad.#{$fa-css-prefix}-mosque:after { content: fa-content-secondary($fa-var-mosque); }
.fad.#{$fa-css-prefix}-motorcycle:after { content: fa-content-secondary($fa-var-motorcycle); }
.fad.#{$fa-css-prefix}-mountain:after { content: fa-content-secondary($fa-var-mountain); }
.fad.#{$fa-css-prefix}-mountains:after { content: fa-content-secondary($fa-var-mountains); }
.fad.#{$fa-css-prefix}-mouse-pointer:after { content: fa-content($fa-var-mouse-pointer); visibility: hidden; }
.fad.#{$fa-css-prefix}-mug:after { content: fa-content($fa-var-mug); visibility: hidden; }
.fad.#{$fa-css-prefix}-mug-hot:after { content: fa-content-secondary($fa-var-mug-hot); }
.fad.#{$fa-css-prefix}-mug-marshmallows:after { content: fa-content-secondary($fa-var-mug-marshmallows); }
.fad.#{$fa-css-prefix}-mug-tea:after { content: fa-content-secondary($fa-var-mug-tea); }
.fad.#{$fa-css-prefix}-music:after { content: fa-content($fa-var-music); visibility: hidden; }
.fad.#{$fa-css-prefix}-narwhal:after { content: fa-content-secondary($fa-var-narwhal); }
.fad.#{$fa-css-prefix}-network-wired:after { content: fa-content-secondary($fa-var-network-wired); }
.fad.#{$fa-css-prefix}-neuter:after { content: fa-content($fa-var-neuter); visibility: hidden; }
.fad.#{$fa-css-prefix}-newspaper:after { content: fa-content-secondary($fa-var-newspaper); }
.fad.#{$fa-css-prefix}-not-equal:after { content: fa-content($fa-var-not-equal); visibility: hidden; }
.fad.#{$fa-css-prefix}-notes-medical:after { content: fa-content-secondary($fa-var-notes-medical); }
.fad.#{$fa-css-prefix}-object-group:after { content: fa-content-secondary($fa-var-object-group); }
.fad.#{$fa-css-prefix}-object-ungroup:after { content: fa-content-secondary($fa-var-object-ungroup); }
.fad.#{$fa-css-prefix}-octagon:after { content: fa-content($fa-var-octagon); visibility: hidden; }
.fad.#{$fa-css-prefix}-oil-can:after { content: fa-content-secondary($fa-var-oil-can); }
.fad.#{$fa-css-prefix}-oil-temp:after { content: fa-content-secondary($fa-var-oil-temp); }
.fad.#{$fa-css-prefix}-om:after { content: fa-content-secondary($fa-var-om); }
.fad.#{$fa-css-prefix}-omega:after { content: fa-content($fa-var-omega); visibility: hidden; }
.fad.#{$fa-css-prefix}-ornament:after { content: fa-content-secondary($fa-var-ornament); }
.fad.#{$fa-css-prefix}-otter:after { content: fa-content-secondary($fa-var-otter); }
.fad.#{$fa-css-prefix}-outdent:after { content: fa-content-secondary($fa-var-outdent); }
.fad.#{$fa-css-prefix}-overline:after { content: fa-content-secondary($fa-var-overline); }
.fad.#{$fa-css-prefix}-page-break:after { content: fa-content-secondary($fa-var-page-break); }
.fad.#{$fa-css-prefix}-pager:after { content: fa-content-secondary($fa-var-pager); }
.fad.#{$fa-css-prefix}-paint-brush:after { content: fa-content-secondary($fa-var-paint-brush); }
.fad.#{$fa-css-prefix}-paint-brush-alt:after { content: fa-content-secondary($fa-var-paint-brush-alt); }
.fad.#{$fa-css-prefix}-paint-roller:after { content: fa-content-secondary($fa-var-paint-roller); }
.fad.#{$fa-css-prefix}-palette:after { content: fa-content-secondary($fa-var-palette); }
.fad.#{$fa-css-prefix}-pallet:after { content: fa-content-secondary($fa-var-pallet); }
.fad.#{$fa-css-prefix}-pallet-alt:after { content: fa-content-secondary($fa-var-pallet-alt); }
.fad.#{$fa-css-prefix}-paper-plane:after { content: fa-content-secondary($fa-var-paper-plane); }
.fad.#{$fa-css-prefix}-paperclip:after { content: fa-content($fa-var-paperclip); visibility: hidden; }
.fad.#{$fa-css-prefix}-parachute-box:after { content: fa-content-secondary($fa-var-parachute-box); }
.fad.#{$fa-css-prefix}-paragraph:after { content: fa-content($fa-var-paragraph); visibility: hidden; }
.fad.#{$fa-css-prefix}-paragraph-rtl:after { content: fa-content-secondary($fa-var-paragraph-rtl); }
.fad.#{$fa-css-prefix}-parking:after { content: fa-content-secondary($fa-var-parking); }
.fad.#{$fa-css-prefix}-parking-circle:after { content: fa-content-secondary($fa-var-parking-circle); }
.fad.#{$fa-css-prefix}-parking-circle-slash:after { content: fa-content-secondary($fa-var-parking-circle-slash); }
.fad.#{$fa-css-prefix}-parking-slash:after { content: fa-content-secondary($fa-var-parking-slash); }
.fad.#{$fa-css-prefix}-passport:after { content: fa-content-secondary($fa-var-passport); }
.fad.#{$fa-css-prefix}-pastafarianism:after { content: fa-content-secondary($fa-var-pastafarianism); }
.fad.#{$fa-css-prefix}-paste:after { content: fa-content-secondary($fa-var-paste); }
.fad.#{$fa-css-prefix}-pause:after { content: fa-content($fa-var-pause); visibility: hidden; }
.fad.#{$fa-css-prefix}-pause-circle:after { content: fa-content-secondary($fa-var-pause-circle); }
.fad.#{$fa-css-prefix}-paw:after { content: fa-content-secondary($fa-var-paw); }
.fad.#{$fa-css-prefix}-paw-alt:after { content: fa-content-secondary($fa-var-paw-alt); }
.fad.#{$fa-css-prefix}-paw-claws:after { content: fa-content-secondary($fa-var-paw-claws); }
.fad.#{$fa-css-prefix}-peace:after { content: fa-content($fa-var-peace); visibility: hidden; }
.fad.#{$fa-css-prefix}-pegasus:after { content: fa-content-secondary($fa-var-pegasus); }
.fad.#{$fa-css-prefix}-pen:after { content: fa-content-secondary($fa-var-pen); }
.fad.#{$fa-css-prefix}-pen-alt:after { content: fa-content-secondary($fa-var-pen-alt); }
.fad.#{$fa-css-prefix}-pen-fancy:after { content: fa-content-secondary($fa-var-pen-fancy); }
.fad.#{$fa-css-prefix}-pen-nib:after { content: fa-content-secondary($fa-var-pen-nib); }
.fad.#{$fa-css-prefix}-pen-square:after { content: fa-content-secondary($fa-var-pen-square); }
.fad.#{$fa-css-prefix}-pencil:after { content: fa-content-secondary($fa-var-pencil); }
.fad.#{$fa-css-prefix}-pencil-alt:after { content: fa-content-secondary($fa-var-pencil-alt); }
.fad.#{$fa-css-prefix}-pencil-paintbrush:after { content: fa-content-secondary($fa-var-pencil-paintbrush); }
.fad.#{$fa-css-prefix}-pencil-ruler:after { content: fa-content-secondary($fa-var-pencil-ruler); }
.fad.#{$fa-css-prefix}-pennant:after { content: fa-content-secondary($fa-var-pennant); }
.fad.#{$fa-css-prefix}-people-carry:after { content: fa-content-secondary($fa-var-people-carry); }
.fad.#{$fa-css-prefix}-pepper-hot:after { content: fa-content-secondary($fa-var-pepper-hot); }
.fad.#{$fa-css-prefix}-percent:after { content: fa-content-secondary($fa-var-percent); }
.fad.#{$fa-css-prefix}-percentage:after { content: fa-content-secondary($fa-var-percentage); }
.fad.#{$fa-css-prefix}-person-booth:after { content: fa-content-secondary($fa-var-person-booth); }
.fad.#{$fa-css-prefix}-person-carry:after { content: fa-content-secondary($fa-var-person-carry); }
.fad.#{$fa-css-prefix}-person-dolly:after { content: fa-content-secondary($fa-var-person-dolly); }
.fad.#{$fa-css-prefix}-person-dolly-empty:after { content: fa-content-secondary($fa-var-person-dolly-empty); }
.fad.#{$fa-css-prefix}-person-sign:after { content: fa-content-secondary($fa-var-person-sign); }
.fad.#{$fa-css-prefix}-phone:after { content: fa-content($fa-var-phone); visibility: hidden; }
.fad.#{$fa-css-prefix}-phone-laptop:after { content: fa-content-secondary($fa-var-phone-laptop); }
.fad.#{$fa-css-prefix}-phone-office:after { content: fa-content-secondary($fa-var-phone-office); }
.fad.#{$fa-css-prefix}-phone-plus:after { content: fa-content-secondary($fa-var-phone-plus); }
.fad.#{$fa-css-prefix}-phone-slash:after { content: fa-content-secondary($fa-var-phone-slash); }
.fad.#{$fa-css-prefix}-phone-square:after { content: fa-content-secondary($fa-var-phone-square); }
.fad.#{$fa-css-prefix}-phone-volume:after { content: fa-content-secondary($fa-var-phone-volume); }
.fad.#{$fa-css-prefix}-photo-video:after { content: fa-content-secondary($fa-var-photo-video); }
.fad.#{$fa-css-prefix}-pi:after { content: fa-content($fa-var-pi); visibility: hidden; }
.fad.#{$fa-css-prefix}-pie:after { content: fa-content-secondary($fa-var-pie); }
.fad.#{$fa-css-prefix}-pig:after { content: fa-content-secondary($fa-var-pig); }
.fad.#{$fa-css-prefix}-piggy-bank:after { content: fa-content-secondary($fa-var-piggy-bank); }
.fad.#{$fa-css-prefix}-pills:after { content: fa-content-secondary($fa-var-pills); }
.fad.#{$fa-css-prefix}-pizza:after { content: fa-content-secondary($fa-var-pizza); }
.fad.#{$fa-css-prefix}-pizza-slice:after { content: fa-content-secondary($fa-var-pizza-slice); }
.fad.#{$fa-css-prefix}-place-of-worship:after { content: fa-content-secondary($fa-var-place-of-worship); }
.fad.#{$fa-css-prefix}-plane:after { content: fa-content-secondary($fa-var-plane); }
.fad.#{$fa-css-prefix}-plane-alt:after { content: fa-content-secondary($fa-var-plane-alt); }
.fad.#{$fa-css-prefix}-plane-arrival:after { content: fa-content-secondary($fa-var-plane-arrival); }
.fad.#{$fa-css-prefix}-plane-departure:after { content: fa-content-secondary($fa-var-plane-departure); }
.fad.#{$fa-css-prefix}-play:after { content: fa-content($fa-var-play); visibility: hidden; }
.fad.#{$fa-css-prefix}-play-circle:after { content: fa-content-secondary($fa-var-play-circle); }
.fad.#{$fa-css-prefix}-plug:after { content: fa-content-secondary($fa-var-plug); }
.fad.#{$fa-css-prefix}-plus:after { content: fa-content($fa-var-plus); visibility: hidden; }
.fad.#{$fa-css-prefix}-plus-circle:after { content: fa-content-secondary($fa-var-plus-circle); }
.fad.#{$fa-css-prefix}-plus-hexagon:after { content: fa-content-secondary($fa-var-plus-hexagon); }
.fad.#{$fa-css-prefix}-plus-octagon:after { content: fa-content-secondary($fa-var-plus-octagon); }
.fad.#{$fa-css-prefix}-plus-square:after { content: fa-content-secondary($fa-var-plus-square); }
.fad.#{$fa-css-prefix}-podcast:after { content: fa-content-secondary($fa-var-podcast); }
.fad.#{$fa-css-prefix}-podium:after { content: fa-content-secondary($fa-var-podium); }
.fad.#{$fa-css-prefix}-podium-star:after { content: fa-content-secondary($fa-var-podium-star); }
.fad.#{$fa-css-prefix}-poll:after { content: fa-content-secondary($fa-var-poll); }
.fad.#{$fa-css-prefix}-poll-h:after { content: fa-content-secondary($fa-var-poll-h); }
.fad.#{$fa-css-prefix}-poll-people:after { content: fa-content-secondary($fa-var-poll-people); }
.fad.#{$fa-css-prefix}-poo:after { content: fa-content-secondary($fa-var-poo); }
.fad.#{$fa-css-prefix}-poo-storm:after { content: fa-content-secondary($fa-var-poo-storm); }
.fad.#{$fa-css-prefix}-poop:after { content: fa-content($fa-var-poop); visibility: hidden; }
.fad.#{$fa-css-prefix}-popcorn:after { content: fa-content-secondary($fa-var-popcorn); }
.fad.#{$fa-css-prefix}-portrait:after { content: fa-content-secondary($fa-var-portrait); }
.fad.#{$fa-css-prefix}-pound-sign:after { content: fa-content($fa-var-pound-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-power-off:after { content: fa-content-secondary($fa-var-power-off); }
.fad.#{$fa-css-prefix}-pray:after { content: fa-content-secondary($fa-var-pray); }
.fad.#{$fa-css-prefix}-praying-hands:after { content: fa-content-secondary($fa-var-praying-hands); }
.fad.#{$fa-css-prefix}-prescription:after { content: fa-content($fa-var-prescription); visibility: hidden; }
.fad.#{$fa-css-prefix}-prescription-bottle:after { content: fa-content-secondary($fa-var-prescription-bottle); }
.fad.#{$fa-css-prefix}-prescription-bottle-alt:after { content: fa-content-secondary($fa-var-prescription-bottle-alt); }
.fad.#{$fa-css-prefix}-presentation:after { content: fa-content-secondary($fa-var-presentation); }
.fad.#{$fa-css-prefix}-print:after { content: fa-content-secondary($fa-var-print); }
.fad.#{$fa-css-prefix}-print-search:after { content: fa-content-secondary($fa-var-print-search); }
.fad.#{$fa-css-prefix}-print-slash:after { content: fa-content-secondary($fa-var-print-slash); }
.fad.#{$fa-css-prefix}-procedures:after { content: fa-content-secondary($fa-var-procedures); }
.fad.#{$fa-css-prefix}-project-diagram:after { content: fa-content-secondary($fa-var-project-diagram); }
.fad.#{$fa-css-prefix}-pumpkin:after { content: fa-content-secondary($fa-var-pumpkin); }
.fad.#{$fa-css-prefix}-puzzle-piece:after { content: fa-content($fa-var-puzzle-piece); visibility: hidden; }
.fad.#{$fa-css-prefix}-qrcode:after { content: fa-content-secondary($fa-var-qrcode); }
.fad.#{$fa-css-prefix}-question:after { content: fa-content($fa-var-question); visibility: hidden; }
.fad.#{$fa-css-prefix}-question-circle:after { content: fa-content-secondary($fa-var-question-circle); }
.fad.#{$fa-css-prefix}-question-square:after { content: fa-content-secondary($fa-var-question-square); }
.fad.#{$fa-css-prefix}-quidditch:after { content: fa-content-secondary($fa-var-quidditch); }
.fad.#{$fa-css-prefix}-quote-left:after { content: fa-content($fa-var-quote-left); visibility: hidden; }
.fad.#{$fa-css-prefix}-quote-right:after { content: fa-content($fa-var-quote-right); visibility: hidden; }
.fad.#{$fa-css-prefix}-quran:after { content: fa-content-secondary($fa-var-quran); }
.fad.#{$fa-css-prefix}-rabbit:after { content: fa-content-secondary($fa-var-rabbit); }
.fad.#{$fa-css-prefix}-rabbit-fast:after { content: fa-content-secondary($fa-var-rabbit-fast); }
.fad.#{$fa-css-prefix}-racquet:after { content: fa-content-secondary($fa-var-racquet); }
.fad.#{$fa-css-prefix}-radiation:after { content: fa-content-secondary($fa-var-radiation); }
.fad.#{$fa-css-prefix}-radiation-alt:after { content: fa-content-secondary($fa-var-radiation-alt); }
.fad.#{$fa-css-prefix}-rainbow:after { content: fa-content-secondary($fa-var-rainbow); }
.fad.#{$fa-css-prefix}-raindrops:after { content: fa-content-secondary($fa-var-raindrops); }
.fad.#{$fa-css-prefix}-ram:after { content: fa-content-secondary($fa-var-ram); }
.fad.#{$fa-css-prefix}-ramp-loading:after { content: fa-content-secondary($fa-var-ramp-loading); }
.fad.#{$fa-css-prefix}-random:after { content: fa-content-secondary($fa-var-random); }
.fad.#{$fa-css-prefix}-receipt:after { content: fa-content-secondary($fa-var-receipt); }
.fad.#{$fa-css-prefix}-rectangle-landscape:after { content: fa-content($fa-var-rectangle-landscape); visibility: hidden; }
.fad.#{$fa-css-prefix}-rectangle-portrait:after { content: fa-content($fa-var-rectangle-portrait); visibility: hidden; }
.fad.#{$fa-css-prefix}-rectangle-wide:after { content: fa-content($fa-var-rectangle-wide); visibility: hidden; }
.fad.#{$fa-css-prefix}-recycle:after { content: fa-content($fa-var-recycle); visibility: hidden; }
.fad.#{$fa-css-prefix}-redo:after { content: fa-content($fa-var-redo); visibility: hidden; }
.fad.#{$fa-css-prefix}-redo-alt:after { content: fa-content($fa-var-redo-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-registered:after { content: fa-content-secondary($fa-var-registered); }
.fad.#{$fa-css-prefix}-remove-format:after { content: fa-content-secondary($fa-var-remove-format); }
.fad.#{$fa-css-prefix}-repeat:after { content: fa-content-secondary($fa-var-repeat); }
.fad.#{$fa-css-prefix}-repeat-1:after { content: fa-content-secondary($fa-var-repeat-1); }
.fad.#{$fa-css-prefix}-repeat-1-alt:after { content: fa-content-secondary($fa-var-repeat-1-alt); }
.fad.#{$fa-css-prefix}-repeat-alt:after { content: fa-content-secondary($fa-var-repeat-alt); }
.fad.#{$fa-css-prefix}-reply:after { content: fa-content($fa-var-reply); visibility: hidden; }
.fad.#{$fa-css-prefix}-reply-all:after { content: fa-content-secondary($fa-var-reply-all); }
.fad.#{$fa-css-prefix}-republican:after { content: fa-content-secondary($fa-var-republican); }
.fad.#{$fa-css-prefix}-restroom:after { content: fa-content-secondary($fa-var-restroom); }
.fad.#{$fa-css-prefix}-retweet:after { content: fa-content-secondary($fa-var-retweet); }
.fad.#{$fa-css-prefix}-retweet-alt:after { content: fa-content-secondary($fa-var-retweet-alt); }
.fad.#{$fa-css-prefix}-ribbon:after { content: fa-content-secondary($fa-var-ribbon); }
.fad.#{$fa-css-prefix}-ring:after { content: fa-content-secondary($fa-var-ring); }
.fad.#{$fa-css-prefix}-rings-wedding:after { content: fa-content-secondary($fa-var-rings-wedding); }
.fad.#{$fa-css-prefix}-road:after { content: fa-content-secondary($fa-var-road); }
.fad.#{$fa-css-prefix}-robot:after { content: fa-content-secondary($fa-var-robot); }
.fad.#{$fa-css-prefix}-rocket:after { content: fa-content-secondary($fa-var-rocket); }
.fad.#{$fa-css-prefix}-route:after { content: fa-content-secondary($fa-var-route); }
.fad.#{$fa-css-prefix}-route-highway:after { content: fa-content-secondary($fa-var-route-highway); }
.fad.#{$fa-css-prefix}-route-interstate:after { content: fa-content-secondary($fa-var-route-interstate); }
.fad.#{$fa-css-prefix}-rss:after { content: fa-content-secondary($fa-var-rss); }
.fad.#{$fa-css-prefix}-rss-square:after { content: fa-content-secondary($fa-var-rss-square); }
.fad.#{$fa-css-prefix}-ruble-sign:after { content: fa-content($fa-var-ruble-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-ruler:after { content: fa-content-secondary($fa-var-ruler); }
.fad.#{$fa-css-prefix}-ruler-combined:after { content: fa-content-secondary($fa-var-ruler-combined); }
.fad.#{$fa-css-prefix}-ruler-horizontal:after { content: fa-content-secondary($fa-var-ruler-horizontal); }
.fad.#{$fa-css-prefix}-ruler-triangle:after { content: fa-content-secondary($fa-var-ruler-triangle); }
.fad.#{$fa-css-prefix}-ruler-vertical:after { content: fa-content-secondary($fa-var-ruler-vertical); }
.fad.#{$fa-css-prefix}-running:after { content: fa-content-secondary($fa-var-running); }
.fad.#{$fa-css-prefix}-rupee-sign:after { content: fa-content($fa-var-rupee-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-rv:after { content: fa-content-secondary($fa-var-rv); }
.fad.#{$fa-css-prefix}-sack:after { content: fa-content($fa-var-sack); visibility: hidden; }
.fad.#{$fa-css-prefix}-sack-dollar:after { content: fa-content-secondary($fa-var-sack-dollar); }
.fad.#{$fa-css-prefix}-sad-cry:after { content: fa-content-secondary($fa-var-sad-cry); }
.fad.#{$fa-css-prefix}-sad-tear:after { content: fa-content-secondary($fa-var-sad-tear); }
.fad.#{$fa-css-prefix}-salad:after { content: fa-content-secondary($fa-var-salad); }
.fad.#{$fa-css-prefix}-sandwich:after { content: fa-content-secondary($fa-var-sandwich); }
.fad.#{$fa-css-prefix}-satellite:after { content: fa-content-secondary($fa-var-satellite); }
.fad.#{$fa-css-prefix}-satellite-dish:after { content: fa-content-secondary($fa-var-satellite-dish); }
.fad.#{$fa-css-prefix}-sausage:after { content: fa-content-secondary($fa-var-sausage); }
.fad.#{$fa-css-prefix}-save:after { content: fa-content-secondary($fa-var-save); }
.fad.#{$fa-css-prefix}-scalpel:after { content: fa-content-secondary($fa-var-scalpel); }
.fad.#{$fa-css-prefix}-scalpel-path:after { content: fa-content-secondary($fa-var-scalpel-path); }
.fad.#{$fa-css-prefix}-scanner:after { content: fa-content-secondary($fa-var-scanner); }
.fad.#{$fa-css-prefix}-scanner-keyboard:after { content: fa-content-secondary($fa-var-scanner-keyboard); }
.fad.#{$fa-css-prefix}-scanner-touchscreen:after { content: fa-content-secondary($fa-var-scanner-touchscreen); }
.fad.#{$fa-css-prefix}-scarecrow:after { content: fa-content-secondary($fa-var-scarecrow); }
.fad.#{$fa-css-prefix}-scarf:after { content: fa-content-secondary($fa-var-scarf); }
.fad.#{$fa-css-prefix}-school:after { content: fa-content-secondary($fa-var-school); }
.fad.#{$fa-css-prefix}-screwdriver:after { content: fa-content-secondary($fa-var-screwdriver); }
.fad.#{$fa-css-prefix}-scroll:after { content: fa-content-secondary($fa-var-scroll); }
.fad.#{$fa-css-prefix}-scroll-old:after { content: fa-content-secondary($fa-var-scroll-old); }
.fad.#{$fa-css-prefix}-scrubber:after { content: fa-content($fa-var-scrubber); visibility: hidden; }
.fad.#{$fa-css-prefix}-scythe:after { content: fa-content-secondary($fa-var-scythe); }
.fad.#{$fa-css-prefix}-sd-card:after { content: fa-content-secondary($fa-var-sd-card); }
.fad.#{$fa-css-prefix}-search:after { content: fa-content-secondary($fa-var-search); }
.fad.#{$fa-css-prefix}-search-dollar:after { content: fa-content-secondary($fa-var-search-dollar); }
.fad.#{$fa-css-prefix}-search-location:after { content: fa-content-secondary($fa-var-search-location); }
.fad.#{$fa-css-prefix}-search-minus:after { content: fa-content-secondary($fa-var-search-minus); }
.fad.#{$fa-css-prefix}-search-plus:after { content: fa-content-secondary($fa-var-search-plus); }
.fad.#{$fa-css-prefix}-seedling:after { content: fa-content-secondary($fa-var-seedling); }
.fad.#{$fa-css-prefix}-send-back:after { content: fa-content-secondary($fa-var-send-back); }
.fad.#{$fa-css-prefix}-send-backward:after { content: fa-content-secondary($fa-var-send-backward); }
.fad.#{$fa-css-prefix}-server:after { content: fa-content-secondary($fa-var-server); }
.fad.#{$fa-css-prefix}-shapes:after { content: fa-content-secondary($fa-var-shapes); }
.fad.#{$fa-css-prefix}-share:after { content: fa-content($fa-var-share); visibility: hidden; }
.fad.#{$fa-css-prefix}-share-all:after { content: fa-content-secondary($fa-var-share-all); }
.fad.#{$fa-css-prefix}-share-alt:after { content: fa-content-secondary($fa-var-share-alt); }
.fad.#{$fa-css-prefix}-share-alt-square:after { content: fa-content-secondary($fa-var-share-alt-square); }
.fad.#{$fa-css-prefix}-share-square:after { content: fa-content-secondary($fa-var-share-square); }
.fad.#{$fa-css-prefix}-sheep:after { content: fa-content-secondary($fa-var-sheep); }
.fad.#{$fa-css-prefix}-shekel-sign:after { content: fa-content-secondary($fa-var-shekel-sign); }
.fad.#{$fa-css-prefix}-shield:after { content: fa-content($fa-var-shield); visibility: hidden; }
.fad.#{$fa-css-prefix}-shield-alt:after { content: fa-content-secondary($fa-var-shield-alt); }
.fad.#{$fa-css-prefix}-shield-check:after { content: fa-content-secondary($fa-var-shield-check); }
.fad.#{$fa-css-prefix}-shield-cross:after { content: fa-content-secondary($fa-var-shield-cross); }
.fad.#{$fa-css-prefix}-ship:after { content: fa-content-secondary($fa-var-ship); }
.fad.#{$fa-css-prefix}-shipping-fast:after { content: fa-content-secondary($fa-var-shipping-fast); }
.fad.#{$fa-css-prefix}-shipping-timed:after { content: fa-content-secondary($fa-var-shipping-timed); }
.fad.#{$fa-css-prefix}-shish-kebab:after { content: fa-content-secondary($fa-var-shish-kebab); }
.fad.#{$fa-css-prefix}-shoe-prints:after { content: fa-content-secondary($fa-var-shoe-prints); }
.fad.#{$fa-css-prefix}-shopping-bag:after { content: fa-content-secondary($fa-var-shopping-bag); }
.fad.#{$fa-css-prefix}-shopping-basket:after { content: fa-content-secondary($fa-var-shopping-basket); }
.fad.#{$fa-css-prefix}-shopping-cart:after { content: fa-content-secondary($fa-var-shopping-cart); }
.fad.#{$fa-css-prefix}-shovel:after { content: fa-content($fa-var-shovel); visibility: hidden; }
.fad.#{$fa-css-prefix}-shovel-snow:after { content: fa-content-secondary($fa-var-shovel-snow); }
.fad.#{$fa-css-prefix}-shower:after { content: fa-content-secondary($fa-var-shower); }
.fad.#{$fa-css-prefix}-shredder:after { content: fa-content-secondary($fa-var-shredder); }
.fad.#{$fa-css-prefix}-shuttle-van:after { content: fa-content-secondary($fa-var-shuttle-van); }
.fad.#{$fa-css-prefix}-shuttlecock:after { content: fa-content-secondary($fa-var-shuttlecock); }
.fad.#{$fa-css-prefix}-sickle:after { content: fa-content-secondary($fa-var-sickle); }
.fad.#{$fa-css-prefix}-sigma:after { content: fa-content($fa-var-sigma); visibility: hidden; }
.fad.#{$fa-css-prefix}-sign:after { content: fa-content-secondary($fa-var-sign); }
.fad.#{$fa-css-prefix}-sign-in:after { content: fa-content-secondary($fa-var-sign-in); }
.fad.#{$fa-css-prefix}-sign-in-alt:after { content: fa-content-secondary($fa-var-sign-in-alt); }
.fad.#{$fa-css-prefix}-sign-language:after { content: fa-content-secondary($fa-var-sign-language); }
.fad.#{$fa-css-prefix}-sign-out:after { content: fa-content-secondary($fa-var-sign-out); }
.fad.#{$fa-css-prefix}-sign-out-alt:after { content: fa-content-secondary($fa-var-sign-out-alt); }
.fad.#{$fa-css-prefix}-signal:after { content: fa-content-secondary($fa-var-signal); }
.fad.#{$fa-css-prefix}-signal-1:after { content: fa-content($fa-var-signal-1); visibility: hidden; }
.fad.#{$fa-css-prefix}-signal-2:after { content: fa-content($fa-var-signal-2); visibility: hidden; }
.fad.#{$fa-css-prefix}-signal-3:after { content: fa-content-secondary($fa-var-signal-3); }
.fad.#{$fa-css-prefix}-signal-4:after { content: fa-content-secondary($fa-var-signal-4); }
.fad.#{$fa-css-prefix}-signal-alt:after { content: fa-content-secondary($fa-var-signal-alt); }
.fad.#{$fa-css-prefix}-signal-alt-1:after { content: fa-content($fa-var-signal-alt-1); visibility: hidden; }
.fad.#{$fa-css-prefix}-signal-alt-2:after { content: fa-content($fa-var-signal-alt-2); visibility: hidden; }
.fad.#{$fa-css-prefix}-signal-alt-3:after { content: fa-content-secondary($fa-var-signal-alt-3); }
.fad.#{$fa-css-prefix}-signal-alt-slash:after { content: fa-content-secondary($fa-var-signal-alt-slash); }
.fad.#{$fa-css-prefix}-signal-slash:after { content: fa-content-secondary($fa-var-signal-slash); }
.fad.#{$fa-css-prefix}-signature:after { content: fa-content($fa-var-signature); visibility: hidden; }
.fad.#{$fa-css-prefix}-sim-card:after { content: fa-content-secondary($fa-var-sim-card); }
.fad.#{$fa-css-prefix}-sitemap:after { content: fa-content-secondary($fa-var-sitemap); }
.fad.#{$fa-css-prefix}-skating:after { content: fa-content-secondary($fa-var-skating); }
.fad.#{$fa-css-prefix}-skeleton:after { content: fa-content-secondary($fa-var-skeleton); }
.fad.#{$fa-css-prefix}-ski-jump:after { content: fa-content-secondary($fa-var-ski-jump); }
.fad.#{$fa-css-prefix}-ski-lift:after { content: fa-content-secondary($fa-var-ski-lift); }
.fad.#{$fa-css-prefix}-skiing:after { content: fa-content-secondary($fa-var-skiing); }
.fad.#{$fa-css-prefix}-skiing-nordic:after { content: fa-content-secondary($fa-var-skiing-nordic); }
.fad.#{$fa-css-prefix}-skull:after { content: fa-content-secondary($fa-var-skull); }
.fad.#{$fa-css-prefix}-skull-crossbones:after { content: fa-content-secondary($fa-var-skull-crossbones); }
.fad.#{$fa-css-prefix}-slash:after { content: fa-content($fa-var-slash); visibility: hidden; }
.fad.#{$fa-css-prefix}-sledding:after { content: fa-content-secondary($fa-var-sledding); }
.fad.#{$fa-css-prefix}-sleigh:after { content: fa-content-secondary($fa-var-sleigh); }
.fad.#{$fa-css-prefix}-sliders-h:after { content: fa-content-secondary($fa-var-sliders-h); }
.fad.#{$fa-css-prefix}-sliders-h-square:after { content: fa-content-secondary($fa-var-sliders-h-square); }
.fad.#{$fa-css-prefix}-sliders-v:after { content: fa-content-secondary($fa-var-sliders-v); }
.fad.#{$fa-css-prefix}-sliders-v-square:after { content: fa-content-secondary($fa-var-sliders-v-square); }
.fad.#{$fa-css-prefix}-smile:after { content: fa-content-secondary($fa-var-smile); }
.fad.#{$fa-css-prefix}-smile-beam:after { content: fa-content-secondary($fa-var-smile-beam); }
.fad.#{$fa-css-prefix}-smile-plus:after { content: fa-content-secondary($fa-var-smile-plus); }
.fad.#{$fa-css-prefix}-smile-wink:after { content: fa-content-secondary($fa-var-smile-wink); }
.fad.#{$fa-css-prefix}-smog:after { content: fa-content-secondary($fa-var-smog); }
.fad.#{$fa-css-prefix}-smoke:after { content: fa-content-secondary($fa-var-smoke); }
.fad.#{$fa-css-prefix}-smoking:after { content: fa-content-secondary($fa-var-smoking); }
.fad.#{$fa-css-prefix}-smoking-ban:after { content: fa-content-secondary($fa-var-smoking-ban); }
.fad.#{$fa-css-prefix}-sms:after { content: fa-content-secondary($fa-var-sms); }
.fad.#{$fa-css-prefix}-snake:after { content: fa-content($fa-var-snake); visibility: hidden; }
.fad.#{$fa-css-prefix}-snooze:after { content: fa-content-secondary($fa-var-snooze); }
.fad.#{$fa-css-prefix}-snow-blowing:after { content: fa-content-secondary($fa-var-snow-blowing); }
.fad.#{$fa-css-prefix}-snowboarding:after { content: fa-content-secondary($fa-var-snowboarding); }
.fad.#{$fa-css-prefix}-snowflake:after { content: fa-content($fa-var-snowflake); visibility: hidden; }
.fad.#{$fa-css-prefix}-snowflakes:after { content: fa-content-secondary($fa-var-snowflakes); }
.fad.#{$fa-css-prefix}-snowman:after { content: fa-content-secondary($fa-var-snowman); }
.fad.#{$fa-css-prefix}-snowmobile:after { content: fa-content-secondary($fa-var-snowmobile); }
.fad.#{$fa-css-prefix}-snowplow:after { content: fa-content-secondary($fa-var-snowplow); }
.fad.#{$fa-css-prefix}-socks:after { content: fa-content-secondary($fa-var-socks); }
.fad.#{$fa-css-prefix}-solar-panel:after { content: fa-content-secondary($fa-var-solar-panel); }
.fad.#{$fa-css-prefix}-sort:after { content: fa-content-secondary($fa-var-sort); }
.fad.#{$fa-css-prefix}-sort-alpha-down:after { content: fa-content-secondary($fa-var-sort-alpha-down); }
.fad.#{$fa-css-prefix}-sort-alpha-down-alt:after { content: fa-content-secondary($fa-var-sort-alpha-down-alt); }
.fad.#{$fa-css-prefix}-sort-alpha-up:after { content: fa-content-secondary($fa-var-sort-alpha-up); }
.fad.#{$fa-css-prefix}-sort-alpha-up-alt:after { content: fa-content-secondary($fa-var-sort-alpha-up-alt); }
.fad.#{$fa-css-prefix}-sort-alt:after { content: fa-content-secondary($fa-var-sort-alt); }
.fad.#{$fa-css-prefix}-sort-amount-down:after { content: fa-content-secondary($fa-var-sort-amount-down); }
.fad.#{$fa-css-prefix}-sort-amount-down-alt:after { content: fa-content-secondary($fa-var-sort-amount-down-alt); }
.fad.#{$fa-css-prefix}-sort-amount-up:after { content: fa-content-secondary($fa-var-sort-amount-up); }
.fad.#{$fa-css-prefix}-sort-amount-up-alt:after { content: fa-content-secondary($fa-var-sort-amount-up-alt); }
.fad.#{$fa-css-prefix}-sort-down:after { content: fa-content($fa-var-sort-down); visibility: hidden; }
.fad.#{$fa-css-prefix}-sort-numeric-down:after { content: fa-content-secondary($fa-var-sort-numeric-down); }
.fad.#{$fa-css-prefix}-sort-numeric-down-alt:after { content: fa-content-secondary($fa-var-sort-numeric-down-alt); }
.fad.#{$fa-css-prefix}-sort-numeric-up:after { content: fa-content-secondary($fa-var-sort-numeric-up); }
.fad.#{$fa-css-prefix}-sort-numeric-up-alt:after { content: fa-content-secondary($fa-var-sort-numeric-up-alt); }
.fad.#{$fa-css-prefix}-sort-shapes-down:after { content: fa-content-secondary($fa-var-sort-shapes-down); }
.fad.#{$fa-css-prefix}-sort-shapes-down-alt:after { content: fa-content-secondary($fa-var-sort-shapes-down-alt); }
.fad.#{$fa-css-prefix}-sort-shapes-up:after { content: fa-content-secondary($fa-var-sort-shapes-up); }
.fad.#{$fa-css-prefix}-sort-shapes-up-alt:after { content: fa-content-secondary($fa-var-sort-shapes-up-alt); }
.fad.#{$fa-css-prefix}-sort-size-down:after { content: fa-content-secondary($fa-var-sort-size-down); }
.fad.#{$fa-css-prefix}-sort-size-down-alt:after { content: fa-content-secondary($fa-var-sort-size-down-alt); }
.fad.#{$fa-css-prefix}-sort-size-up:after { content: fa-content-secondary($fa-var-sort-size-up); }
.fad.#{$fa-css-prefix}-sort-size-up-alt:after { content: fa-content-secondary($fa-var-sort-size-up-alt); }
.fad.#{$fa-css-prefix}-sort-up:after { content: fa-content($fa-var-sort-up); visibility: hidden; }
.fad.#{$fa-css-prefix}-soup:after { content: fa-content-secondary($fa-var-soup); }
.fad.#{$fa-css-prefix}-spa:after { content: fa-content-secondary($fa-var-spa); }
.fad.#{$fa-css-prefix}-space-shuttle:after { content: fa-content-secondary($fa-var-space-shuttle); }
.fad.#{$fa-css-prefix}-spade:after { content: fa-content($fa-var-spade); visibility: hidden; }
.fad.#{$fa-css-prefix}-sparkles:after { content: fa-content-secondary($fa-var-sparkles); }
.fad.#{$fa-css-prefix}-spell-check:after { content: fa-content-secondary($fa-var-spell-check); }
.fad.#{$fa-css-prefix}-spider:after { content: fa-content-secondary($fa-var-spider); }
.fad.#{$fa-css-prefix}-spider-black-widow:after { content: fa-content-secondary($fa-var-spider-black-widow); }
.fad.#{$fa-css-prefix}-spider-web:after { content: fa-content($fa-var-spider-web); visibility: hidden; }
.fad.#{$fa-css-prefix}-spinner:after { content: fa-content-secondary($fa-var-spinner); }
.fad.#{$fa-css-prefix}-spinner-third:after { content: fa-content($fa-var-spinner-third); visibility: hidden; }
.fad.#{$fa-css-prefix}-splotch:after { content: fa-content($fa-var-splotch); visibility: hidden; }
.fad.#{$fa-css-prefix}-spray-can:after { content: fa-content-secondary($fa-var-spray-can); }
.fad.#{$fa-css-prefix}-square:after { content: fa-content($fa-var-square); visibility: hidden; }
.fad.#{$fa-css-prefix}-square-full:after { content: fa-content($fa-var-square-full); visibility: hidden; }
.fad.#{$fa-css-prefix}-square-root:after { content: fa-content($fa-var-square-root); visibility: hidden; }
.fad.#{$fa-css-prefix}-square-root-alt:after { content: fa-content-secondary($fa-var-square-root-alt); }
.fad.#{$fa-css-prefix}-squirrel:after { content: fa-content-secondary($fa-var-squirrel); }
.fad.#{$fa-css-prefix}-staff:after { content: fa-content($fa-var-staff); visibility: hidden; }
.fad.#{$fa-css-prefix}-stamp:after { content: fa-content-secondary($fa-var-stamp); }
.fad.#{$fa-css-prefix}-star:after { content: fa-content($fa-var-star); visibility: hidden; }
.fad.#{$fa-css-prefix}-star-and-crescent:after { content: fa-content-secondary($fa-var-star-and-crescent); }
.fad.#{$fa-css-prefix}-star-christmas:after { content: fa-content-secondary($fa-var-star-christmas); }
.fad.#{$fa-css-prefix}-star-exclamation:after { content: fa-content-secondary($fa-var-star-exclamation); }
.fad.#{$fa-css-prefix}-star-half:after { content: fa-content($fa-var-star-half); visibility: hidden; }
.fad.#{$fa-css-prefix}-star-half-alt:after { content: fa-content-secondary($fa-var-star-half-alt); }
.fad.#{$fa-css-prefix}-star-of-david:after { content: fa-content($fa-var-star-of-david); visibility: hidden; }
.fad.#{$fa-css-prefix}-star-of-life:after { content: fa-content($fa-var-star-of-life); visibility: hidden; }
.fad.#{$fa-css-prefix}-stars:after { content: fa-content-secondary($fa-var-stars); }
.fad.#{$fa-css-prefix}-steak:after { content: fa-content-secondary($fa-var-steak); }
.fad.#{$fa-css-prefix}-steering-wheel:after { content: fa-content-secondary($fa-var-steering-wheel); }
.fad.#{$fa-css-prefix}-step-backward:after { content: fa-content-secondary($fa-var-step-backward); }
.fad.#{$fa-css-prefix}-step-forward:after { content: fa-content-secondary($fa-var-step-forward); }
.fad.#{$fa-css-prefix}-stethoscope:after { content: fa-content-secondary($fa-var-stethoscope); }
.fad.#{$fa-css-prefix}-sticky-note:after { content: fa-content-secondary($fa-var-sticky-note); }
.fad.#{$fa-css-prefix}-stocking:after { content: fa-content-secondary($fa-var-stocking); }
.fad.#{$fa-css-prefix}-stomach:after { content: fa-content-secondary($fa-var-stomach); }
.fad.#{$fa-css-prefix}-stop:after { content: fa-content($fa-var-stop); visibility: hidden; }
.fad.#{$fa-css-prefix}-stop-circle:after { content: fa-content-secondary($fa-var-stop-circle); }
.fad.#{$fa-css-prefix}-stopwatch:after { content: fa-content-secondary($fa-var-stopwatch); }
.fad.#{$fa-css-prefix}-store:after { content: fa-content-secondary($fa-var-store); }
.fad.#{$fa-css-prefix}-store-alt:after { content: fa-content-secondary($fa-var-store-alt); }
.fad.#{$fa-css-prefix}-stream:after { content: fa-content-secondary($fa-var-stream); }
.fad.#{$fa-css-prefix}-street-view:after { content: fa-content-secondary($fa-var-street-view); }
.fad.#{$fa-css-prefix}-stretcher:after { content: fa-content-secondary($fa-var-stretcher); }
.fad.#{$fa-css-prefix}-strikethrough:after { content: fa-content-secondary($fa-var-strikethrough); }
.fad.#{$fa-css-prefix}-stroopwafel:after { content: fa-content-secondary($fa-var-stroopwafel); }
.fad.#{$fa-css-prefix}-subscript:after { content: fa-content-secondary($fa-var-subscript); }
.fad.#{$fa-css-prefix}-subway:after { content: fa-content-secondary($fa-var-subway); }
.fad.#{$fa-css-prefix}-suitcase:after { content: fa-content-secondary($fa-var-suitcase); }
.fad.#{$fa-css-prefix}-suitcase-rolling:after { content: fa-content-secondary($fa-var-suitcase-rolling); }
.fad.#{$fa-css-prefix}-sun:after { content: fa-content-secondary($fa-var-sun); }
.fad.#{$fa-css-prefix}-sun-cloud:after { content: fa-content-secondary($fa-var-sun-cloud); }
.fad.#{$fa-css-prefix}-sun-dust:after { content: fa-content-secondary($fa-var-sun-dust); }
.fad.#{$fa-css-prefix}-sun-haze:after { content: fa-content-secondary($fa-var-sun-haze); }
.fad.#{$fa-css-prefix}-sunglasses:after { content: fa-content-secondary($fa-var-sunglasses); }
.fad.#{$fa-css-prefix}-sunrise:after { content: fa-content-secondary($fa-var-sunrise); }
.fad.#{$fa-css-prefix}-sunset:after { content: fa-content-secondary($fa-var-sunset); }
.fad.#{$fa-css-prefix}-superscript:after { content: fa-content-secondary($fa-var-superscript); }
.fad.#{$fa-css-prefix}-surprise:after { content: fa-content-secondary($fa-var-surprise); }
.fad.#{$fa-css-prefix}-swatchbook:after { content: fa-content-secondary($fa-var-swatchbook); }
.fad.#{$fa-css-prefix}-swimmer:after { content: fa-content-secondary($fa-var-swimmer); }
.fad.#{$fa-css-prefix}-swimming-pool:after { content: fa-content-secondary($fa-var-swimming-pool); }
.fad.#{$fa-css-prefix}-sword:after { content: fa-content-secondary($fa-var-sword); }
.fad.#{$fa-css-prefix}-swords:after { content: fa-content-secondary($fa-var-swords); }
.fad.#{$fa-css-prefix}-synagogue:after { content: fa-content-secondary($fa-var-synagogue); }
.fad.#{$fa-css-prefix}-sync:after { content: fa-content-secondary($fa-var-sync); }
.fad.#{$fa-css-prefix}-sync-alt:after { content: fa-content-secondary($fa-var-sync-alt); }
.fad.#{$fa-css-prefix}-syringe:after { content: fa-content-secondary($fa-var-syringe); }
.fad.#{$fa-css-prefix}-table:after { content: fa-content-secondary($fa-var-table); }
.fad.#{$fa-css-prefix}-table-tennis:after { content: fa-content-secondary($fa-var-table-tennis); }
.fad.#{$fa-css-prefix}-tablet:after { content: fa-content($fa-var-tablet); visibility: hidden; }
.fad.#{$fa-css-prefix}-tablet-alt:after { content: fa-content-secondary($fa-var-tablet-alt); }
.fad.#{$fa-css-prefix}-tablet-android:after { content: fa-content($fa-var-tablet-android); visibility: hidden; }
.fad.#{$fa-css-prefix}-tablet-android-alt:after { content: fa-content-secondary($fa-var-tablet-android-alt); }
.fad.#{$fa-css-prefix}-tablet-rugged:after { content: fa-content-secondary($fa-var-tablet-rugged); }
.fad.#{$fa-css-prefix}-tablets:after { content: fa-content-secondary($fa-var-tablets); }
.fad.#{$fa-css-prefix}-tachometer:after { content: fa-content-secondary($fa-var-tachometer); }
.fad.#{$fa-css-prefix}-tachometer-alt:after { content: fa-content-secondary($fa-var-tachometer-alt); }
.fad.#{$fa-css-prefix}-tachometer-alt-average:after { content: fa-content-secondary($fa-var-tachometer-alt-average); }
.fad.#{$fa-css-prefix}-tachometer-alt-fast:after { content: fa-content-secondary($fa-var-tachometer-alt-fast); }
.fad.#{$fa-css-prefix}-tachometer-alt-fastest:after { content: fa-content-secondary($fa-var-tachometer-alt-fastest); }
.fad.#{$fa-css-prefix}-tachometer-alt-slow:after { content: fa-content-secondary($fa-var-tachometer-alt-slow); }
.fad.#{$fa-css-prefix}-tachometer-alt-slowest:after { content: fa-content-secondary($fa-var-tachometer-alt-slowest); }
.fad.#{$fa-css-prefix}-tachometer-average:after { content: fa-content-secondary($fa-var-tachometer-average); }
.fad.#{$fa-css-prefix}-tachometer-fast:after { content: fa-content-secondary($fa-var-tachometer-fast); }
.fad.#{$fa-css-prefix}-tachometer-fastest:after { content: fa-content-secondary($fa-var-tachometer-fastest); }
.fad.#{$fa-css-prefix}-tachometer-slow:after { content: fa-content-secondary($fa-var-tachometer-slow); }
.fad.#{$fa-css-prefix}-tachometer-slowest:after { content: fa-content-secondary($fa-var-tachometer-slowest); }
.fad.#{$fa-css-prefix}-taco:after { content: fa-content-secondary($fa-var-taco); }
.fad.#{$fa-css-prefix}-tag:after { content: fa-content($fa-var-tag); visibility: hidden; }
.fad.#{$fa-css-prefix}-tags:after { content: fa-content-secondary($fa-var-tags); }
.fad.#{$fa-css-prefix}-tally:after { content: fa-content-secondary($fa-var-tally); }
.fad.#{$fa-css-prefix}-tanakh:after { content: fa-content-secondary($fa-var-tanakh); }
.fad.#{$fa-css-prefix}-tape:after { content: fa-content-secondary($fa-var-tape); }
.fad.#{$fa-css-prefix}-tasks:after { content: fa-content-secondary($fa-var-tasks); }
.fad.#{$fa-css-prefix}-tasks-alt:after { content: fa-content-secondary($fa-var-tasks-alt); }
.fad.#{$fa-css-prefix}-taxi:after { content: fa-content-secondary($fa-var-taxi); }
.fad.#{$fa-css-prefix}-teeth:after { content: fa-content-secondary($fa-var-teeth); }
.fad.#{$fa-css-prefix}-teeth-open:after { content: fa-content-secondary($fa-var-teeth-open); }
.fad.#{$fa-css-prefix}-temperature-frigid:after { content: fa-content-secondary($fa-var-temperature-frigid); }
.fad.#{$fa-css-prefix}-temperature-high:after { content: fa-content-secondary($fa-var-temperature-high); }
.fad.#{$fa-css-prefix}-temperature-hot:after { content: fa-content-secondary($fa-var-temperature-hot); }
.fad.#{$fa-css-prefix}-temperature-low:after { content: fa-content-secondary($fa-var-temperature-low); }
.fad.#{$fa-css-prefix}-tenge:after { content: fa-content($fa-var-tenge); visibility: hidden; }
.fad.#{$fa-css-prefix}-tennis-ball:after { content: fa-content-secondary($fa-var-tennis-ball); }
.fad.#{$fa-css-prefix}-terminal:after { content: fa-content-secondary($fa-var-terminal); }
.fad.#{$fa-css-prefix}-text:after { content: fa-content($fa-var-text); visibility: hidden; }
.fad.#{$fa-css-prefix}-text-height:after { content: fa-content-secondary($fa-var-text-height); }
.fad.#{$fa-css-prefix}-text-size:after { content: fa-content-secondary($fa-var-text-size); }
.fad.#{$fa-css-prefix}-text-width:after { content: fa-content-secondary($fa-var-text-width); }
.fad.#{$fa-css-prefix}-th:after { content: fa-content-secondary($fa-var-th); }
.fad.#{$fa-css-prefix}-th-large:after { content: fa-content-secondary($fa-var-th-large); }
.fad.#{$fa-css-prefix}-th-list:after { content: fa-content-secondary($fa-var-th-list); }
.fad.#{$fa-css-prefix}-theater-masks:after { content: fa-content-secondary($fa-var-theater-masks); }
.fad.#{$fa-css-prefix}-thermometer:after { content: fa-content-secondary($fa-var-thermometer); }
.fad.#{$fa-css-prefix}-thermometer-empty:after { content: fa-content-secondary($fa-var-thermometer-empty); }
.fad.#{$fa-css-prefix}-thermometer-full:after { content: fa-content-secondary($fa-var-thermometer-full); }
.fad.#{$fa-css-prefix}-thermometer-half:after { content: fa-content-secondary($fa-var-thermometer-half); }
.fad.#{$fa-css-prefix}-thermometer-quarter:after { content: fa-content-secondary($fa-var-thermometer-quarter); }
.fad.#{$fa-css-prefix}-thermometer-three-quarters:after { content: fa-content-secondary($fa-var-thermometer-three-quarters); }
.fad.#{$fa-css-prefix}-theta:after { content: fa-content($fa-var-theta); visibility: hidden; }
.fad.#{$fa-css-prefix}-thumbs-down:after { content: fa-content-secondary($fa-var-thumbs-down); }
.fad.#{$fa-css-prefix}-thumbs-up:after { content: fa-content-secondary($fa-var-thumbs-up); }
.fad.#{$fa-css-prefix}-thumbtack:after { content: fa-content-secondary($fa-var-thumbtack); }
.fad.#{$fa-css-prefix}-thunderstorm:after { content: fa-content-secondary($fa-var-thunderstorm); }
.fad.#{$fa-css-prefix}-thunderstorm-moon:after { content: fa-content-secondary($fa-var-thunderstorm-moon); }
.fad.#{$fa-css-prefix}-thunderstorm-sun:after { content: fa-content-secondary($fa-var-thunderstorm-sun); }
.fad.#{$fa-css-prefix}-ticket:after { content: fa-content($fa-var-ticket); visibility: hidden; }
.fad.#{$fa-css-prefix}-ticket-alt:after { content: fa-content-secondary($fa-var-ticket-alt); }
.fad.#{$fa-css-prefix}-tilde:after { content: fa-content($fa-var-tilde); visibility: hidden; }
.fad.#{$fa-css-prefix}-times:after { content: fa-content($fa-var-times); visibility: hidden; }
.fad.#{$fa-css-prefix}-times-circle:after { content: fa-content-secondary($fa-var-times-circle); }
.fad.#{$fa-css-prefix}-times-hexagon:after { content: fa-content-secondary($fa-var-times-hexagon); }
.fad.#{$fa-css-prefix}-times-octagon:after { content: fa-content-secondary($fa-var-times-octagon); }
.fad.#{$fa-css-prefix}-times-square:after { content: fa-content-secondary($fa-var-times-square); }
.fad.#{$fa-css-prefix}-tint:after { content: fa-content-secondary($fa-var-tint); }
.fad.#{$fa-css-prefix}-tint-slash:after { content: fa-content-secondary($fa-var-tint-slash); }
.fad.#{$fa-css-prefix}-tire:after { content: fa-content-secondary($fa-var-tire); }
.fad.#{$fa-css-prefix}-tire-flat:after { content: fa-content-secondary($fa-var-tire-flat); }
.fad.#{$fa-css-prefix}-tire-pressure-warning:after { content: fa-content-secondary($fa-var-tire-pressure-warning); }
.fad.#{$fa-css-prefix}-tire-rugged:after { content: fa-content-secondary($fa-var-tire-rugged); }
.fad.#{$fa-css-prefix}-tired:after { content: fa-content-secondary($fa-var-tired); }
.fad.#{$fa-css-prefix}-toggle-off:after { content: fa-content-secondary($fa-var-toggle-off); }
.fad.#{$fa-css-prefix}-toggle-on:after { content: fa-content-secondary($fa-var-toggle-on); }
.fad.#{$fa-css-prefix}-toilet:after { content: fa-content-secondary($fa-var-toilet); }
.fad.#{$fa-css-prefix}-toilet-paper:after { content: fa-content-secondary($fa-var-toilet-paper); }
.fad.#{$fa-css-prefix}-toilet-paper-alt:after { content: fa-content-secondary($fa-var-toilet-paper-alt); }
.fad.#{$fa-css-prefix}-tombstone:after { content: fa-content-secondary($fa-var-tombstone); }
.fad.#{$fa-css-prefix}-tombstone-alt:after { content: fa-content-secondary($fa-var-tombstone-alt); }
.fad.#{$fa-css-prefix}-toolbox:after { content: fa-content-secondary($fa-var-toolbox); }
.fad.#{$fa-css-prefix}-tools:after { content: fa-content-secondary($fa-var-tools); }
.fad.#{$fa-css-prefix}-tooth:after { content: fa-content($fa-var-tooth); visibility: hidden; }
.fad.#{$fa-css-prefix}-toothbrush:after { content: fa-content-secondary($fa-var-toothbrush); }
.fad.#{$fa-css-prefix}-torah:after { content: fa-content-secondary($fa-var-torah); }
.fad.#{$fa-css-prefix}-torii-gate:after { content: fa-content-secondary($fa-var-torii-gate); }
.fad.#{$fa-css-prefix}-tornado:after { content: fa-content-secondary($fa-var-tornado); }
.fad.#{$fa-css-prefix}-tractor:after { content: fa-content-secondary($fa-var-tractor); }
.fad.#{$fa-css-prefix}-trademark:after { content: fa-content($fa-var-trademark); visibility: hidden; }
.fad.#{$fa-css-prefix}-traffic-cone:after { content: fa-content-secondary($fa-var-traffic-cone); }
.fad.#{$fa-css-prefix}-traffic-light:after { content: fa-content-secondary($fa-var-traffic-light); }
.fad.#{$fa-css-prefix}-traffic-light-go:after { content: fa-content-secondary($fa-var-traffic-light-go); }
.fad.#{$fa-css-prefix}-traffic-light-slow:after { content: fa-content-secondary($fa-var-traffic-light-slow); }
.fad.#{$fa-css-prefix}-traffic-light-stop:after { content: fa-content-secondary($fa-var-traffic-light-stop); }
.fad.#{$fa-css-prefix}-train:after { content: fa-content-secondary($fa-var-train); }
.fad.#{$fa-css-prefix}-tram:after { content: fa-content-secondary($fa-var-tram); }
.fad.#{$fa-css-prefix}-transgender:after { content: fa-content($fa-var-transgender); visibility: hidden; }
.fad.#{$fa-css-prefix}-transgender-alt:after { content: fa-content($fa-var-transgender-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-trash:after { content: fa-content-secondary($fa-var-trash); }
.fad.#{$fa-css-prefix}-trash-alt:after { content: fa-content-secondary($fa-var-trash-alt); }
.fad.#{$fa-css-prefix}-trash-restore:after { content: fa-content-secondary($fa-var-trash-restore); }
.fad.#{$fa-css-prefix}-trash-restore-alt:after { content: fa-content-secondary($fa-var-trash-restore-alt); }
.fad.#{$fa-css-prefix}-trash-undo:after { content: fa-content-secondary($fa-var-trash-undo); }
.fad.#{$fa-css-prefix}-trash-undo-alt:after { content: fa-content-secondary($fa-var-trash-undo-alt); }
.fad.#{$fa-css-prefix}-treasure-chest:after { content: fa-content-secondary($fa-var-treasure-chest); }
.fad.#{$fa-css-prefix}-tree:after { content: fa-content-secondary($fa-var-tree); }
.fad.#{$fa-css-prefix}-tree-alt:after { content: fa-content-secondary($fa-var-tree-alt); }
.fad.#{$fa-css-prefix}-tree-christmas:after { content: fa-content-secondary($fa-var-tree-christmas); }
.fad.#{$fa-css-prefix}-tree-decorated:after { content: fa-content-secondary($fa-var-tree-decorated); }
.fad.#{$fa-css-prefix}-tree-large:after { content: fa-content($fa-var-tree-large); visibility: hidden; }
.fad.#{$fa-css-prefix}-tree-palm:after { content: fa-content-secondary($fa-var-tree-palm); }
.fad.#{$fa-css-prefix}-trees:after { content: fa-content-secondary($fa-var-trees); }
.fad.#{$fa-css-prefix}-triangle:after { content: fa-content($fa-var-triangle); visibility: hidden; }
.fad.#{$fa-css-prefix}-trophy:after { content: fa-content($fa-var-trophy); visibility: hidden; }
.fad.#{$fa-css-prefix}-trophy-alt:after { content: fa-content-secondary($fa-var-trophy-alt); }
.fad.#{$fa-css-prefix}-truck:after { content: fa-content-secondary($fa-var-truck); }
.fad.#{$fa-css-prefix}-truck-container:after { content: fa-content-secondary($fa-var-truck-container); }
.fad.#{$fa-css-prefix}-truck-couch:after { content: fa-content-secondary($fa-var-truck-couch); }
.fad.#{$fa-css-prefix}-truck-loading:after { content: fa-content-secondary($fa-var-truck-loading); }
.fad.#{$fa-css-prefix}-truck-monster:after { content: fa-content-secondary($fa-var-truck-monster); }
.fad.#{$fa-css-prefix}-truck-moving:after { content: fa-content-secondary($fa-var-truck-moving); }
.fad.#{$fa-css-prefix}-truck-pickup:after { content: fa-content-secondary($fa-var-truck-pickup); }
.fad.#{$fa-css-prefix}-truck-plow:after { content: fa-content-secondary($fa-var-truck-plow); }
.fad.#{$fa-css-prefix}-truck-ramp:after { content: fa-content-secondary($fa-var-truck-ramp); }
.fad.#{$fa-css-prefix}-tshirt:after { content: fa-content($fa-var-tshirt); visibility: hidden; }
.fad.#{$fa-css-prefix}-tty:after { content: fa-content-secondary($fa-var-tty); }
.fad.#{$fa-css-prefix}-turkey:after { content: fa-content-secondary($fa-var-turkey); }
.fad.#{$fa-css-prefix}-turtle:after { content: fa-content-secondary($fa-var-turtle); }
.fad.#{$fa-css-prefix}-tv:after { content: fa-content-secondary($fa-var-tv); }
.fad.#{$fa-css-prefix}-tv-retro:after { content: fa-content-secondary($fa-var-tv-retro); }
.fad.#{$fa-css-prefix}-umbrella:after { content: fa-content-secondary($fa-var-umbrella); }
.fad.#{$fa-css-prefix}-umbrella-beach:after { content: fa-content-secondary($fa-var-umbrella-beach); }
.fad.#{$fa-css-prefix}-underline:after { content: fa-content-secondary($fa-var-underline); }
.fad.#{$fa-css-prefix}-undo:after { content: fa-content($fa-var-undo); visibility: hidden; }
.fad.#{$fa-css-prefix}-undo-alt:after { content: fa-content($fa-var-undo-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-unicorn:after { content: fa-content-secondary($fa-var-unicorn); }
.fad.#{$fa-css-prefix}-union:after { content: fa-content($fa-var-union); visibility: hidden; }
.fad.#{$fa-css-prefix}-universal-access:after { content: fa-content-secondary($fa-var-universal-access); }
.fad.#{$fa-css-prefix}-university:after { content: fa-content-secondary($fa-var-university); }
.fad.#{$fa-css-prefix}-unlink:after { content: fa-content-secondary($fa-var-unlink); }
.fad.#{$fa-css-prefix}-unlock:after { content: fa-content-secondary($fa-var-unlock); }
.fad.#{$fa-css-prefix}-unlock-alt:after { content: fa-content-secondary($fa-var-unlock-alt); }
.fad.#{$fa-css-prefix}-upload:after { content: fa-content-secondary($fa-var-upload); }
.fad.#{$fa-css-prefix}-usd-circle:after { content: fa-content-secondary($fa-var-usd-circle); }
.fad.#{$fa-css-prefix}-usd-square:after { content: fa-content-secondary($fa-var-usd-square); }
.fad.#{$fa-css-prefix}-user:after { content: fa-content($fa-var-user); visibility: hidden; }
.fad.#{$fa-css-prefix}-user-alt:after { content: fa-content($fa-var-user-alt); visibility: hidden; }
.fad.#{$fa-css-prefix}-user-alt-slash:after { content: fa-content-secondary($fa-var-user-alt-slash); }
.fad.#{$fa-css-prefix}-user-astronaut:after { content: fa-content-secondary($fa-var-user-astronaut); }
.fad.#{$fa-css-prefix}-user-chart:after { content: fa-content-secondary($fa-var-user-chart); }
.fad.#{$fa-css-prefix}-user-check:after { content: fa-content-secondary($fa-var-user-check); }
.fad.#{$fa-css-prefix}-user-circle:after { content: fa-content-secondary($fa-var-user-circle); }
.fad.#{$fa-css-prefix}-user-clock:after { content: fa-content-secondary($fa-var-user-clock); }
.fad.#{$fa-css-prefix}-user-cog:after { content: fa-content-secondary($fa-var-user-cog); }
.fad.#{$fa-css-prefix}-user-crown:after { content: fa-content-secondary($fa-var-user-crown); }
.fad.#{$fa-css-prefix}-user-edit:after { content: fa-content-secondary($fa-var-user-edit); }
.fad.#{$fa-css-prefix}-user-friends:after { content: fa-content-secondary($fa-var-user-friends); }
.fad.#{$fa-css-prefix}-user-graduate:after { content: fa-content-secondary($fa-var-user-graduate); }
.fad.#{$fa-css-prefix}-user-hard-hat:after { content: fa-content-secondary($fa-var-user-hard-hat); }
.fad.#{$fa-css-prefix}-user-headset:after { content: fa-content-secondary($fa-var-user-headset); }
.fad.#{$fa-css-prefix}-user-injured:after { content: fa-content-secondary($fa-var-user-injured); }
.fad.#{$fa-css-prefix}-user-lock:after { content: fa-content-secondary($fa-var-user-lock); }
.fad.#{$fa-css-prefix}-user-md:after { content: fa-content-secondary($fa-var-user-md); }
.fad.#{$fa-css-prefix}-user-md-chat:after { content: fa-content-secondary($fa-var-user-md-chat); }
.fad.#{$fa-css-prefix}-user-minus:after { content: fa-content-secondary($fa-var-user-minus); }
.fad.#{$fa-css-prefix}-user-ninja:after { content: fa-content-secondary($fa-var-user-ninja); }
.fad.#{$fa-css-prefix}-user-nurse:after { content: fa-content-secondary($fa-var-user-nurse); }
.fad.#{$fa-css-prefix}-user-plus:after { content: fa-content-secondary($fa-var-user-plus); }
.fad.#{$fa-css-prefix}-user-secret:after { content: fa-content-secondary($fa-var-user-secret); }
.fad.#{$fa-css-prefix}-user-shield:after { content: fa-content-secondary($fa-var-user-shield); }
.fad.#{$fa-css-prefix}-user-slash:after { content: fa-content-secondary($fa-var-user-slash); }
.fad.#{$fa-css-prefix}-user-tag:after { content: fa-content-secondary($fa-var-user-tag); }
.fad.#{$fa-css-prefix}-user-tie:after { content: fa-content-secondary($fa-var-user-tie); }
.fad.#{$fa-css-prefix}-user-times:after { content: fa-content-secondary($fa-var-user-times); }
.fad.#{$fa-css-prefix}-users:after { content: fa-content-secondary($fa-var-users); }
.fad.#{$fa-css-prefix}-users-class:after { content: fa-content-secondary($fa-var-users-class); }
.fad.#{$fa-css-prefix}-users-cog:after { content: fa-content-secondary($fa-var-users-cog); }
.fad.#{$fa-css-prefix}-users-crown:after { content: fa-content-secondary($fa-var-users-crown); }
.fad.#{$fa-css-prefix}-users-medical:after { content: fa-content-secondary($fa-var-users-medical); }
.fad.#{$fa-css-prefix}-utensil-fork:after { content: fa-content($fa-var-utensil-fork); visibility: hidden; }
.fad.#{$fa-css-prefix}-utensil-knife:after { content: fa-content($fa-var-utensil-knife); visibility: hidden; }
.fad.#{$fa-css-prefix}-utensil-spoon:after { content: fa-content($fa-var-utensil-spoon); visibility: hidden; }
.fad.#{$fa-css-prefix}-utensils:after { content: fa-content-secondary($fa-var-utensils); }
.fad.#{$fa-css-prefix}-utensils-alt:after { content: fa-content-secondary($fa-var-utensils-alt); }
.fad.#{$fa-css-prefix}-value-absolute:after { content: fa-content-secondary($fa-var-value-absolute); }
.fad.#{$fa-css-prefix}-vector-square:after { content: fa-content-secondary($fa-var-vector-square); }
.fad.#{$fa-css-prefix}-venus:after { content: fa-content($fa-var-venus); visibility: hidden; }
.fad.#{$fa-css-prefix}-venus-double:after { content: fa-content-secondary($fa-var-venus-double); }
.fad.#{$fa-css-prefix}-venus-mars:after { content: fa-content-secondary($fa-var-venus-mars); }
.fad.#{$fa-css-prefix}-vial:after { content: fa-content-secondary($fa-var-vial); }
.fad.#{$fa-css-prefix}-vials:after { content: fa-content-secondary($fa-var-vials); }
.fad.#{$fa-css-prefix}-video:after { content: fa-content-secondary($fa-var-video); }
.fad.#{$fa-css-prefix}-video-plus:after { content: fa-content-secondary($fa-var-video-plus); }
.fad.#{$fa-css-prefix}-video-slash:after { content: fa-content-secondary($fa-var-video-slash); }
.fad.#{$fa-css-prefix}-vihara:after { content: fa-content-secondary($fa-var-vihara); }
.fad.#{$fa-css-prefix}-voicemail:after { content: fa-content-secondary($fa-var-voicemail); }
.fad.#{$fa-css-prefix}-volcano:after { content: fa-content-secondary($fa-var-volcano); }
.fad.#{$fa-css-prefix}-volleyball-ball:after { content: fa-content($fa-var-volleyball-ball); visibility: hidden; }
.fad.#{$fa-css-prefix}-volume:after { content: fa-content-secondary($fa-var-volume); }
.fad.#{$fa-css-prefix}-volume-down:after { content: fa-content-secondary($fa-var-volume-down); }
.fad.#{$fa-css-prefix}-volume-mute:after { content: fa-content-secondary($fa-var-volume-mute); }
.fad.#{$fa-css-prefix}-volume-off:after { content: fa-content($fa-var-volume-off); visibility: hidden; }
.fad.#{$fa-css-prefix}-volume-slash:after { content: fa-content-secondary($fa-var-volume-slash); }
.fad.#{$fa-css-prefix}-volume-up:after { content: fa-content-secondary($fa-var-volume-up); }
.fad.#{$fa-css-prefix}-vote-nay:after { content: fa-content-secondary($fa-var-vote-nay); }
.fad.#{$fa-css-prefix}-vote-yea:after { content: fa-content-secondary($fa-var-vote-yea); }
.fad.#{$fa-css-prefix}-vr-cardboard:after { content: fa-content($fa-var-vr-cardboard); visibility: hidden; }
.fad.#{$fa-css-prefix}-walker:after { content: fa-content-secondary($fa-var-walker); }
.fad.#{$fa-css-prefix}-walking:after { content: fa-content-secondary($fa-var-walking); }
.fad.#{$fa-css-prefix}-wallet:after { content: fa-content-secondary($fa-var-wallet); }
.fad.#{$fa-css-prefix}-wand:after { content: fa-content($fa-var-wand); visibility: hidden; }
.fad.#{$fa-css-prefix}-wand-magic:after { content: fa-content-secondary($fa-var-wand-magic); }
.fad.#{$fa-css-prefix}-warehouse:after { content: fa-content-secondary($fa-var-warehouse); }
.fad.#{$fa-css-prefix}-warehouse-alt:after { content: fa-content-secondary($fa-var-warehouse-alt); }
.fad.#{$fa-css-prefix}-washer:after { content: fa-content-secondary($fa-var-washer); }
.fad.#{$fa-css-prefix}-watch:after { content: fa-content-secondary($fa-var-watch); }
.fad.#{$fa-css-prefix}-watch-fitness:after { content: fa-content-secondary($fa-var-watch-fitness); }
.fad.#{$fa-css-prefix}-water:after { content: fa-content-secondary($fa-var-water); }
.fad.#{$fa-css-prefix}-water-lower:after { content: fa-content-secondary($fa-var-water-lower); }
.fad.#{$fa-css-prefix}-water-rise:after { content: fa-content-secondary($fa-var-water-rise); }
.fad.#{$fa-css-prefix}-wave-sine:after { content: fa-content($fa-var-wave-sine); visibility: hidden; }
.fad.#{$fa-css-prefix}-wave-square:after { content: fa-content($fa-var-wave-square); visibility: hidden; }
.fad.#{$fa-css-prefix}-wave-triangle:after { content: fa-content($fa-var-wave-triangle); visibility: hidden; }
.fad.#{$fa-css-prefix}-webcam:after { content: fa-content-secondary($fa-var-webcam); }
.fad.#{$fa-css-prefix}-webcam-slash:after { content: fa-content-secondary($fa-var-webcam-slash); }
.fad.#{$fa-css-prefix}-weight:after { content: fa-content-secondary($fa-var-weight); }
.fad.#{$fa-css-prefix}-weight-hanging:after { content: fa-content-secondary($fa-var-weight-hanging); }
.fad.#{$fa-css-prefix}-whale:after { content: fa-content($fa-var-whale); visibility: hidden; }
.fad.#{$fa-css-prefix}-wheat:after { content: fa-content-secondary($fa-var-wheat); }
.fad.#{$fa-css-prefix}-wheelchair:after { content: fa-content-secondary($fa-var-wheelchair); }
.fad.#{$fa-css-prefix}-whistle:after { content: fa-content-secondary($fa-var-whistle); }
.fad.#{$fa-css-prefix}-wifi:after { content: fa-content-secondary($fa-var-wifi); }
.fad.#{$fa-css-prefix}-wifi-1:after { content: fa-content($fa-var-wifi-1); visibility: hidden; }
.fad.#{$fa-css-prefix}-wifi-2:after { content: fa-content-secondary($fa-var-wifi-2); }
.fad.#{$fa-css-prefix}-wifi-slash:after { content: fa-content-secondary($fa-var-wifi-slash); }
.fad.#{$fa-css-prefix}-wind:after { content: fa-content-secondary($fa-var-wind); }
.fad.#{$fa-css-prefix}-wind-turbine:after { content: fa-content-secondary($fa-var-wind-turbine); }
.fad.#{$fa-css-prefix}-wind-warning:after { content: fa-content-secondary($fa-var-wind-warning); }
.fad.#{$fa-css-prefix}-window:after { content: fa-content-secondary($fa-var-window); }
.fad.#{$fa-css-prefix}-window-alt:after { content: fa-content-secondary($fa-var-window-alt); }
.fad.#{$fa-css-prefix}-window-close:after { content: fa-content-secondary($fa-var-window-close); }
.fad.#{$fa-css-prefix}-window-maximize:after { content: fa-content-secondary($fa-var-window-maximize); }
.fad.#{$fa-css-prefix}-window-minimize:after { content: fa-content($fa-var-window-minimize); visibility: hidden; }
.fad.#{$fa-css-prefix}-window-restore:after { content: fa-content-secondary($fa-var-window-restore); }
.fad.#{$fa-css-prefix}-windsock:after { content: fa-content-secondary($fa-var-windsock); }
.fad.#{$fa-css-prefix}-wine-bottle:after { content: fa-content-secondary($fa-var-wine-bottle); }
.fad.#{$fa-css-prefix}-wine-glass:after { content: fa-content($fa-var-wine-glass); visibility: hidden; }
.fad.#{$fa-css-prefix}-wine-glass-alt:after { content: fa-content-secondary($fa-var-wine-glass-alt); }
.fad.#{$fa-css-prefix}-won-sign:after { content: fa-content($fa-var-won-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-wreath:after { content: fa-content-secondary($fa-var-wreath); }
.fad.#{$fa-css-prefix}-wrench:after { content: fa-content($fa-var-wrench); visibility: hidden; }
.fad.#{$fa-css-prefix}-x-ray:after { content: fa-content-secondary($fa-var-x-ray); }
.fad.#{$fa-css-prefix}-yen-sign:after { content: fa-content($fa-var-yen-sign); visibility: hidden; }
.fad.#{$fa-css-prefix}-yin-yang:after { content: fa-content-secondary($fa-var-yin-yang); }
