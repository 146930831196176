
/*----------------------------------------------------------------------------*/
/*#ICONS STYLE*/
/*----------------------------------------------------------------------------*/

.icon {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  flex-shrink: 0;
  width:  35px;
  height: 35px;
  margin-right: 30px;

  &--lg {
    width:  40px;
    height: 40px;
    margin-right: 15px;
  }
  &--xl {
    width:  75px;
    height: 75px;
    margin-right: 0;
  }
  &--rounded { border-radius: 50px; }
  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &--blue       { background-color: mat-color($gmb-app-primary, 50); }
  &--green      { background-color: mat-color($gmb-app-accent, 50); }
  &--red        { background-color: mat-color($gmb-app-warn, 50); }
  &--orange     { background-color: #fdefda; }
  &--purple     { background-color: #f9f0ff; }
  &--limeyellow { background-color: #fbfbd4; }
  &--limegreen  { background-color: #e9feb1; }
  &--gray       {
    background-color: $lightgray;
    i { color: #737373; }
  }

  i { font-size: 16px; }
}

.copy-clipboard {
  color: #87919f;
  cursor: pointer;
  padding-left: 10px;
}

