/*----------------------------------------------------------------------------*/
/*#SIDEBAR STYLE*/
/*----------------------------------------------------------------------------*/

.sidebar {
  width: 275px;
  background-color: mat-color(mat-palette($gmb-darkblue, 400));

  &__title {
    position: relative;
    display: block;
    color: #fff;
    padding: 20px 30px;
    font-size: 14px;
    background-color: transparent;
    transition: all .3s ease-in-out;
    min-height: 100px;

    h2 {
      font-size: 20px;
      line-height: 1em;
    }

  }

  &__item {
    position: relative;
    display: block;
    color: #fff;
    padding: 20px 30px;
    font-size: 16px;
    background-color: transparent;
    transition: all .3s ease-in-out;
    @media (max-height: 778px) {
      padding-top:    11px;
      padding-bottom: 11px;
    }

    i {
      display: inline-block;
      width: 40px;
    }

    &.active,
    &:hover {
      background-color: mat-color(mat-palette($gmb-darkblue, 500));
    }

    &.active::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: mat-color($gmb-app-primary, 500);
    }
  }

  &__group {
    &:not(:last-child) {
      padding: 10px 0;
      border-bottom: 2px solid mat-color(mat-palette($gmb-darkblue, 500));
    }

    &:first-child {
      padding-top: 30px;
    }
  }
}


.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}
.mat-drawer.mat-sidenav { border-right: none; }

