/*----------------------------------------------------------------------------*/
/*#PANEL STYLE*/
/*----------------------------------------------------------------------------*/

.panel {
  position: relative;
  margin-bottom: 20px;
  &--below { z-index: 1; }
  &--above {
    z-index: 2;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.2);
    margin-left: -5px;

    .panel__header {
      margin-top: -20px;
      padding-top:    40px;
      padding-bottom: 20px;
    }
  }

  &--border {
    border: 1px solid $lightgray;
    .panel__header { border-bottom: 1px solid $lightgray; }
  }

  &__header {
    padding: 20px 40px;
    &.bg--green.panel__header--border { border-bottom: 1px solid mat-color($gmb-app-accent, default); }
  }
  &__content { padding: 40px; }
}
