
/*----------------------------------------------------------------------------*/
/*#LINES STYLE*/
/*----------------------------------------------------------------------------*/

//line through and behind text
.line-through {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $lightgray;
    position: absolute;
    left:   0;
    right:  0;
    top:   50%;
    transform: translateY(-50%);
  }

  span {
    position: relative;
    padding: 0 20px;
    background-color: #fff;
  }
}

//line under text
.line--under {
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 3px;
    left:  -3px;
    right: -3px;
    height: 6px;
    z-index: -1;
  }

  &.line--primary::before { background-color: mat-color($gmb-app-accent, default); }
}
