/*----------------------------------------------------------------------------*/
/*#GRADE STYLE*/
/*----------------------------------------------------------------------------*/

.nav-grade {
  a {
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    padding: 15px;
    margin: 0;
    transition: all .3s ease-in-out;
    &:hover { color: mat-color($gmb-app-accent, 500); }
  }
}

.header-grade {
  padding-left: 30px!important;
  padding-right: 30px!important;

  .logo img {
    width: inherit;
    max-height: 50px;
  }
}

//buttons
.btn--grade,
[class*="mat-"].btn--grade {
  min-width: 100px;
  height: 42px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 12px;
  border-radius: 4px;

  &.btn--white {
    border: 2px solid #000;
    background-color: #fff;
  }
}

.grade-popup {
  position: fixed;
  right:  25px;
  bottom: -250px;
  width: 370px;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  overflow: hidden;
  animation: .7s linear showPopup 2s forwards;

  &.close {
    animation: .7s linear closePopup forwards;
  }

  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ddd;
    transition: all .3s ease-in-out;
    &:hover { color: mat-color($gmb-app-accent, 500); }
  }

  &__content {
    border-radius: 10px;
    padding: 40px 30px 20px;
  }

  &.grade-circles {
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }

    &::before {
      background-color: mat-color(mat-palette($gmb-orange, 50));
      top:  -60px;
      left: -30px;
    }

    &::after {
      background-color: mat-color(mat-palette($gmb-green, 50));
      bottom: -130px;
      right:  -130px;
      width:  200px;
      height: 200px;
    }
  }

  .grade-border {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 15px solid #F7D8D7;
      background: #fff;
      position: absolute;
      right: -30px;
      top:   -30px;
    }
  }
}

@keyframes showPopup {
  0% {
    bottom: -250px;
  }
  100% {
    bottom: 50px;
  }
}
@keyframes closePopup {
  0% {
    bottom: 50px;
  }
  100% {
    bottom: -250px;
  }
}
